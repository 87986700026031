
import { useRef, useState, useEffect } from 'react'

// import { Navigation, Pagination, Scrollbar } from 'swiper'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

import { getBaseURL } from './utils/constants'
const baseURL = getBaseURL()

export default function Home() {

    return (<>
        <header className="header navbar navbar-expand-lg navbar-dark position-absolute navbar-sticky">
            <div className="container px-3">
                <a href="index.html" className="navbar-brand pe-3">
                    <img src="/assets/img/logo.png" width="47" alt="Utility Factory" />
                    <span style={{ color: 'white', fontFamily: "'Quattrocento', serif" }}>Utility Factory</span>
                </a>
            </div>
        </header>

        <section class="dark-mode bg-dark bg-size-cover bg-repeat-0 bg-position-center position-relative overflow-hidden py-5 mb-4" style={{ backgroundImage: `url(assets/img/landing/saas-3/hero/hero-bg.jpg)` }}>
            <div class="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-4 pb-xl-5">
                <div class="row pt-3 pb-2 py-md-4">
                    <div class="col-xl-5 col-md-6 pt-lg-5 text-center text-md-start mb-4 mb-md-0">
                        <h1 class="display-3 pb-2 pb-sm-3">Make Collabs Easier with <span style={{ color: 'white', fontFamily: "'Quattrocento', serif" }}>Utility Factory</span></h1>
                        <p class="fs-lg d-md-none d-xl-block pb-2 pb-md-0 mb-4 mb-md-5">A collab management tool to make collabs easy and efficient</p>
                        <div class="d-flex justify-content-center justify-content-md-start pb-2 pt-lg-2 pt-xl-0">
                            <a href={`${baseURL}/auth/twitter`} class="btn btn-lg btn-primary shadow-primary me-3 me-sm-4">Start trial now</a>
                            <a href={`${baseURL}/auth/twitter`} class="btn btn-lg btn-outline-secondary">Connect with Twitter</a>
                        </div>
                    </div>
                    <div class="col-xl-7 col-md-6">
                        <div style={{ maxWidth: '675px' }}>
                            <MouseParallaxContainer style={{ display: 'block', position: 'relative' }} globalFactorX={0.1} globalFactorY={0.1}>
                                <MouseParallaxChild factorX={0.3} factorY={0.5} style={{ zIndex: 1, left: 0, top: 0, display: 'block', position: 'relative' }}>
                                    <img style={{ display: 'block', width: '100%' }} src="/assets/img/landing/hero_layer1.png" alt="" />
                                </MouseParallaxChild>
                            </MouseParallaxContainer>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        { /* Features */}
        <section className="container pt-1 pt-xl-3 pb-5">
            <h2 className="h1 text-center pb-3 pb-md-0 mb-md-5">What You Get</h2>
            <div className="swiper swiper-nav-onhover mt-n3 mx-n2" data-swiper-options='{
          "slidesPerView": 1,
          "spaceBetween": 8,
          "pagination": {
            "el": ".swiper-pagination",
            "clickable": true
          },
          "breakpoints": {
            "600": {
              "slidesPerView": 2
            },
            "1000": {
              "slidesPerView": 3
            }
          }
        }'>
                <div className="swiper-wrapper">

                    <div className="swiper-slide h-auto py-3">
                        <div className="card card-hover h-100 mx-2">
                            <div className="card-body">
                                <div className="d-table position-relative p-3 mb-4">
                                    <i className="bx bx-time" style={{ fontSize: 32 }}></i>
                                    <span className="bg-primary position-absolute top-0 start-0 w-100 h-100 rounded-circle opacity-8"></span>
                                </div>
                                <h3 className="h5 pb-1 mb-2">Easy giveaway creation</h3>
                                <p className="mb-0">Should not take more than 2 minutes to create a giveaway, even with complex conditions.</p>
                            </div>
                        </div>
                    </div>

                    <div className="swiper-slide h-auto py-3">
                        <div className="card card-hover h-100 mx-2">
                            <div className="card-body">
                                <div className="d-table position-relative p-3 mb-4">
                                    <i className="bx bx-cog" style={{ fontSize: 32 }}></i>
                                    <span className="bg-primary position-absolute top-0 start-0 w-100 h-100 rounded-circle opacity-8"></span>
                                </div>
                                <h3 className="h5 pb-1 mb-2">Secured collab process</h3>
                                <p className="mb-0">Automated and secured way of making deals with web3 projects and collab managers.</p>
                            </div>
                        </div>
                    </div>

                    <div className="swiper-slide h-auto py-3">
                        <div className="card card-hover h-100 mx-2">
                            <div className="card-body">
                                <div className="d-table position-relative p-3 mb-4">
                                    <i className="bx bx-group" style={{ fontSize: 32 }}></i>
                                    <span className="bg-primary position-absolute top-0 start-0 w-100 h-100 rounded-circle opacity-8"></span>
                                </div>
                                <h3 className="h5 pb-1 mb-2">Collab Management</h3>
                                <p className="mb-0">Manage project listings with integrated team management.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="swiper-pagination position-relative pt-2 pt-sm-3 mt-4"></div>
            </div>
        </section>

        { /* Arguments */}
        <section class="dark-mode bg-dark bg-size-cover bg-repeat-0 bg-position-center position-relative overflow-hidden py-5 mb-4" style={{ backgroundImage: `url(assets/img/landing/saas-3/hero/hero-bg.jpg)` }}>
            <div class="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-4 pb-xl-5">
                <div className="row">
                    <div className="col-lg-4 text-center text-lg-start pb-3 pb-lg-0 mb-4 mb-lg-0">
                        <h2 className="h1 mb-lg-4">Our Vision</h2>
                        <p className="pb-4 mb-0 mb-lg-3">
                            Innovation comes with a vision. Next meta will make Web3 more democratic and accessible. Previous tools will become obsolete and traditional companies will impose their methods and make web3 more professional.
                        </p>
                        <a href={`${baseURL}/auth/twitter`} className="btn btn-primary shadow-primary btn-lg">Discover our solution</a>
                    </div>
                    <div className="col-xl-7 col-lg-8 offset-xl-1">
                        <div className="row row-cols-1 row-cols-md-2">
                            <div className="col">
                                <div className="card card-hover bg-secondary border-0 mb-4">
                                    <div className="card-body d-flex align-items-start">
                                        <div className="flex-shrink-0 bg-light rounded-3 p-3">
                                            <i className="bx bx-brain" style={{ fontSize: 32 }}></i>
                                        </div>
                                        <div className="ps-4">
                                            <h3 className="h5 pb-2 mb-1">Marketing strategy</h3>
                                            <p className="pb-2 mb-1">
                                                Easy conditions, weak community, hard conditions, less participants but strong community.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-hover bg-secondary border-0 mb-4">
                                    <div className="card-body d-flex align-items-start">
                                        <div className="flex-shrink-0 bg-light rounded-3 p-3">
                                            <i className="bx bx-filter-alt" style={{ fontSize: 32 }}></i>
                                        </div>
                                        <div className="ps-4">
                                            <h3 className="h5 pb-2 mb-1">Conversion funnel</h3>
                                            <p className="pb-2 mb-1">
                                                You can make Giveaways bring participants on <b>your</b> service.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-hover bg-secondary border-0 mb-4">
                                    <div className="card-body d-flex align-items-start">
                                        <div className="flex-shrink-0 bg-light rounded-3 p-3">
                                            <i className="bx bxs-traffic" style={{ fontSize: 32 }}></i>
                                        </div>
                                        <div className="ps-4">
                                            <h3 className="h5 pb-2 mb-1">Trustless</h3>
                                            <p className="pb-2 mb-1">
                                                Make collab deals with no risk. Set a "minimum participants" condition. Token property is checked before the deal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card card-hover bg-secondary border-0 mb-4">
                                    <div className="card-body d-flex align-items-start">
                                        <div className="flex-shrink-0 bg-light rounded-3 p-3">
                                            <i className="bx bx-group" style={{ fontSize: 32 }}></i>
                                        </div>
                                        <div className="ps-4">
                                            <h3 className="h5 pb-2 mb-1">Team Management</h3>
                                            <p className="pb-2 mb-1">
                                                Founders, CM, collab managers ? Create your team and work smoothly on UtilityFactory 😉
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-hover bg-secondary border-0 mb-4">
                                    <div className="card-body d-flex align-items-start">
                                        <div className="flex-shrink-0 bg-light rounded-3 p-3">
                                            <i className="bx bx-cog" style={{ fontSize: 32 }}></i>
                                        </div>
                                        <div className="ps-4">
                                            <h3 className="h5 pb-2 mb-1">Automation</h3>
                                            <p className="pb-2 mb-1">
                                                NFTs are automatically transfered at the end of the raffles.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-hover bg-secondary border-0 mb-4">
                                    <div className="card-body d-flex align-items-start">
                                        <div className="flex-shrink-0 bg-light rounded-3 p-3">
                                            <i className="bx bx-lock" style={{ fontSize: 32 }}></i>
                                        </div>
                                        <div className="ps-4">
                                            <h3 className="h5 pb-2 mb-1">Security</h3>
                                            <p className="pb-2 mb-1">
                                                Participants do not need an account, connect with Twitter or wallet to participate to raffles.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        { /* Pricings */}
        <section className="container pt-5">
            <div className="row justify-content-center text-center pt-2 pt-md-4 pt-lg-5 pb-4 pb-lg-5 mb-1">
                <div className="col-xl-6 col-lg-7 col-md-9 col-sm-11 pt-xl-3">
                    <h2 className="h1 mb-lg-4">Transparent Pricing for You</h2>
                    <p className="fs-lg text-muted mb-0">Save hours for your giveaways</p>
                </div>
            </div>

            <div className="table-responsive border rounded shadow-sm">
                <table className="table table-striped align-middle">
                    <thead>
                        <tr className="text-center">
                            <th className="p-4 border-0 text-start text-nowrap fs-lg">Items</th>
                            <th className="w-20 p-4 border-0">
                                <div className="h4 mt-2 mb-1">Free</div>
                                <div className="fw-normal text-muted">For simple Giveaways & Features</div>
                            </th>
                            <th className="w-20 p-4 border-0">
                                <div className="h4 mt-2 mb-1">Pro plan</div>
                                <div className="fw-normal text-muted">For pro features</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-center">
                            <td className="py-3 px-4 fw-medium border-0 text-start text-nowrap">Organize giveaways for my community</td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-check text-primary fs-3"></i></td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-check text-primary fs-3"></i></td>
                        </tr>
                        <tr className="text-center">
                            <td className="py-3 px-4 fw-medium border-0 text-start text-nowrap">Weekly leaderboard</td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-check text-primary fs-3"></i></td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-check text-primary fs-3"></i></td>
                        </tr>
                        <tr className="text-center">
                            <td className="py-3 px-4 fw-medium border-0 text-start text-nowrap">All time leaderboard</td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-x text-danger fs-3"></i></td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-check text-primary fs-3"></i></td>
                        </tr>
                        <tr className="text-center">
                            <td className="py-3 px-4 fw-medium border-0 text-start text-nowrap">Web3 collab management</td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-x text-danger fs-3"></i></td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-check text-primary fs-3"></i></td>
                        </tr>
                        <tr className="text-center">
                            <td className="py-3 px-4 fw-medium border-0 text-start text-nowrap">Integral token prizes management</td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-x text-danger fs-3"></i></td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-check text-primary fs-3"></i><p className="fs-sm text-muted mb-0">Only Gas Fees*</p></td>
                        </tr>
                        <tr className="text-center">
                            <td className="py-3 px-4 fw-medium border-0 text-start text-nowrap">Web3 prizes marketplace</td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-x text-danger fs-3"></i></td>
                            <td className="w-20 py-3 border-0"><i className="bx bx-check text-primary fs-3"></i></td>
                        </tr>
                        <tr className="text-center">
                            <td className="py-3 px-4 fw-medium border-0 text-start text-nowrap">Price</td>
                            <td className="w-20 py-3 border-0">free</td>
                            <td className="w-20 py-3 border-0">
                                <s>From 29$/mo</s>
                                <p style={{ color: 'red' }}>free for now</p>
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td className="py-3 px-4 border-0 text-start text-nowrap"></td>
                            <td className="w-20 py-3 border-0">
                                <div className="py-3">
                                    <a className="btn btn-outline-primary" href={`${baseURL}/auth/twitter`}>Connect for free</a>
                                    <p className="text-muted small">No CB required</p>
                                </div>
                            </td>
                            <td className="w-20 py-3 border-0">
                                <div className="py-3">
                                    <a className="btn btn-outline-primary" href={`${baseURL}/auth/twitter?goto=subscribe`}>Connect and subscribe</a>
                                    <p className="text-muted small">No CB required</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p className="fs-sm text-muted">* You have to pre-pay the token transfer gas-fees, we will operate the transfer to the winner for you</p>
        </section>

        { /* Use cases */}
        <section className="container pt-5 pb-2 mt-3 mt-sm-4 mt-xl-5">
            <h2 className="h1 text-center pb-3 pb-lg-4">One Tool — Endless Use Cases</h2>

            <ul className="nav nav-tabs flex-nowrap justify-content-lg-center overflow-auto pb-2 mb-3 mb-lg-4" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link text-nowrap active" id="twitter-engagement-tab" data-bs-toggle="tab" data-bs-target="#twitter-engagement" type="button" role="tab" aria-controls="twitter-engagement" aria-selected="true">
                        <i className="bx bx-star fs-lg opacity-60 me-2"></i>
                        Twitter Engagement
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link text-nowrap" id="twitter-space-engagement-tab" data-bs-toggle="tab" data-bs-target="#twitter-space-engagement" type="button" role="tab" aria-controls="twitter-space-engagement" aria-selected="false">
                        <i className="bx bx-briefcase-alt-2 fs-lg opacity-60 me-2"></i>
                        Twitter Space Engagement
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link text-nowrap" id="discord-engagement-tab" data-bs-toggle="tab" data-bs-target="#discord-engagement" type="button" role="tab" aria-controls="discord-engagement" aria-selected="false">
                        <i className="bx bx-rocket fs-lg opacity-60 me-2"></i>
                        Discord Engagement
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link text-nowrap" id="mint-party-tab" data-bs-toggle="tab" data-bs-target="#mint-party" type="button" role="tab" aria-controls="mint-party" aria-selected="false">
                        <i className="bx bx-bar-chart-alt-2 fs-lg opacity-60 me-2"></i>
                        Mint party
                    </button>
                </li>
            </ul>

            <div className="tab-content bg-secondary rounded-3 py-4">

                <div className="tab-pane fade show active" id="twitter-engagement" role="tabpanel" aria-labelledby="twitter-engagement-tab">
                    <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
                        <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                            <h3 className="mb-lg-4">Twitter engagement</h3>
                            <p>
                                The famous "follow", "like", "retweet", "tag friends". The giveaway basics are easy to set up ! This Giveaway was not done with UtilityFactory, but it could have been 😎
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-7 mt-md-4">
                            <img src="assets/img/landing/twitter-engagement.png" className="d-block my-lg-2 mx-auto me-md-0" width="395" alt="Image" />
                        </div>
                    </div>
                </div>

                <div className="tab-pane fade" id="twitter-space-engagement" role="tabpanel" aria-labelledby="twitter-space-engagement-tab">
                    <div className="row align-items-center pt-3 pb-2 pb-sm-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
                        <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                            <h3 className="mb-lg-4">Twitter Space Engagement</h3>
                            <p>
                                You make Twitter Spaces sometimes ? Make your audience engage with your tweets sharing the space's link.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-7 mt-md-4">
                            <img src="assets/img/landing/twitter-spaces.png" className="d-block my-lg-2 mx-auto me-md-0" width="502" alt="Image" />
                        </div>
                    </div>
                </div>

                <div className="tab-pane fade" id="discord-engagement" role="tabpanel" aria-labelledby="discord-engagement-tab">
                    <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 pe-sm-4 pe-md-0 ps-sm-4 ps-lg-0">
                        <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                            <h3 className="mb-lg-4">Discord engagement</h3>
                            <p>
                                Have a discord community ? Use our bot to promote your giveaways, collect participations. You have multiple options to make high profile giveaways.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-7 mt-n3 mt-md-1">
                            <img src="assets/img/landing/discord-engagement.png" className="d-block mb-lg-2 mx-auto me-md-0" width="425" alt="Image" />
                        </div>
                    </div>
                </div>

                <div className="tab-pane fade" id="mint-party" role="tabpanel" aria-labelledby="mint-party-tab">
                    <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 pe-sm-4 pe-md-0 ps-sm-4 ps-lg-0">
                        <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                            <h3 className="mb-lg-4">Mint Party</h3>
                            <p>
                                Want to organize a mint party ? Add a wallet condition to win your giveaway : The user must have minted a specific collection after a specific date and still hold it. You can then offer a giveaway for those who minted your collection during the first day 🔥
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-7 mt-n3 mt-md-1">
                            <img src="assets/img/landing/mint-party.jpg" className="d-block mb-lg-2 mx-auto me-md-0" width="545" alt="Image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        { /* Testimonials */}
        <section className="container py-5 my-2 my-md-4 my-lg-5">
            <div className="row pt-2 py-xl-3">
                <div className="col-lg-3 col-md-4">
                    <h2 className="h1 text-center text-md-start mx-auto mx-md-0 pt-md-2" style={{ maxWidth: '300px' }}>
                        What
                        <br className="d-none d-md-inline" />
                        People Say
                        <br className="d-none d-md-inline" />
                        About the Factory:
                    </h2>
                    <div className="d-flex justify-content-center justify-content-md-start pb-4 mb-2 pt-2 pt-md-4 mt-md-5">
                        <button type="button" id="prev-testimonial" className="btn btn-prev btn-icon btn-sm me-2">
                            <i className="bx bx-chevron-left"></i>
                        </button>
                        <button type="button" id="next-testimonial" className="btn btn-next btn-icon btn-sm ms-2">
                            <i className="bx bx-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <div className="col-lg-9 col-md-8">
                    <div className="swiper mx-n2" data-swiper-options='{
        "slidesPerView": 1,
        "spaceBetween": 8,
        "loop": true,
        "navigation": {
          "prevEl": "#prev-testimonial",
          "nextEl": "#next-testimonial"
        },
        "breakpoints": {
          "500": {
            "slidesPerView": 2
          },
          "1000": {
            "slidesPerView": 2
          },
          "1200": {
            "slidesPerView": 3
          }
        }
      }'>
                        <div className="swiper-wrapper">

                            <div className="swiper-slide h-auto pt-4">
                                <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                    <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                        <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                            <i className="bx bxs-quote-left"></i>
                                        </span>
                                        <blockquote className="card-body pb-3 mb-0">
                                            <p className="mb-0">Amazing tool! We just used it for our Quiz Game with @TheLostDonkeys 🚀</p>
                                        </blockquote>
                                    </div>
                                    <figcaption className="d-flex align-items-center ps-4 pt-4">
                                        <img src="https://pbs.twimg.com/profile_images/1522045335937290240/WEteDLQ5_400x400.png" width="48" className="rounded-circle" alt="Logo of NFTing" />
                                        <div className="ps-3">
                                            <h6 className="fs-sm fw-semibold mb-0">@NFTing_Official</h6>
                                            <span className="fs-xs text-muted">Decentralised Multichain Aggregator</span>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>

                            <div className="swiper-slide h-auto pt-4">
                                <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                    <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                        <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                            <i className="bx bxs-quote-left"></i>
                                        </span>
                                        <blockquote className="card-body pb-3 mb-0">
                                            <p className="mb-0">
                                                During this #Giveaway, we used the tool for the first time and it was super easy to use! The tool takes care of everything you need to do when managing collabs!
                                                Stay tuned for 2023, I think they big plans coming !
                                            </p>
                                        </blockquote>
                                    </div>
                                    <figcaption className="d-flex align-items-center ps-4 pt-4">
                                        <img src="https://pbs.twimg.com/profile_images/1636295652693901312/d4gOjIhT_400x400.png" width="48" className="rounded-circle" alt="Logo of TontonNFT" />
                                        <div className="ps-3">
                                            <h6 className="fs-sm fw-semibold mb-0">@Tonton_Nft</h6>
                                            <span className="fs-xs text-muted">🥇 Alpha Group N*1 in France 🇫🇷</span>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>


                            <div className="swiper-slide h-auto pt-4">
                                <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                    <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                        <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                            <i className="bx bxs-quote-left"></i>
                                        </span>
                                        <blockquote className="card-body pb-3 mb-0">
                                            <p className="mb-0">
                                                Je remercie tout particulièrement
                                                @UtilityFactory_
                                                de m'avoir apporté une solution automatisée, rapide à configurer et "fair" pour ce Giveaway 🙏
                                            </p>
                                        </blockquote>
                                    </div>
                                    <figcaption className="d-flex align-items-center ps-4 pt-4">
                                        <img src="https://pbs.twimg.com/profile_images/1533705404928098310/gET_sTz4_400x400.jpg" width="48" className="rounded-circle" alt="Logo of TontonNFT" />
                                        <div className="ps-3">
                                            <h6 className="fs-sm fw-semibold mb-0">@BubulleCrypto</h6>
                                            <span className="fs-xs text-muted">#Crypto #WEB3 #Metavers</span>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </section>

        { /* FAQ */}
        <section className="container mb-5 pb-2 pb-md-4 pb-lg-5">
            <div className="card border-0 bg-secondary p-md-5 px-sm-2 pt-4 pb-3">
                <div className="card-body mx-auto" style={{ maxWidth: '860px' }}>
                    <h2 className="h1 mb-4 text-center">Any questions?</h2>

                    <div className="accordion" id="accordion-services">
                        <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                            <h3 className="accordion-header" id="heading-0">
                                <button className="accordion-button fs-xl shadow-none rounded-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-0" aria-expanded="true" aria-controls="collapse-1">
                                    What informations do you take from my account when I log in with Twitter ?
                                </button>
                            </h3>
                            <div className="accordion-collapse collapse show" id="collapse-0" aria-labelledby="heading-0" data-bs-parent="#accordion-services">
                                <div className="accordion-body pt-0">
                                    <div className="d-flex flex-md-row flex-column align-items-md-center">
                                        <div className="ps-md-3">
                                            <p className="mb-0">
                                                We use Twitter for Authentication purposes. We will store informations like : username, description, number of followers, image URL. No private information.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                            <h3 className="accordion-header" id="heading-1">
                                <button className="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                    How much does it cost?
                                </button>
                            </h3>
                            <div className="accordion-collapse collapse" id="collapse-1" aria-labelledby="heading-1" data-bs-parent="#accordion-services">
                                <div className="accordion-body pt-0">
                                    <div className="d-flex flex-md-row flex-column align-items-md-center">
                                        <div className="ps-md-3">
                                            <p className="mb-0">
                                                UtilityFactory is a freemium product. You can organize raffles for your community for free.
                                            </p>
                                            <p className="mb-0">
                                                Collab Managers can access Collab Management features after subscribing to our pro solutions from 29$/month.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                            <h3 className="accordion-header" id="heading-2">
                                <button className="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                    What about security? Will you hack my Twitter or Metamask?
                                </button>
                            </h3>
                            <div className="accordion-collapse collapse" id="collapse-2" aria-labelledby="heading-2" data-bs-parent="#accordion-services">
                                <div className="accordion-body pt-0">
                                    <div className="d-flex flex-md-row flex-column align-items-md-center">
                                        <div className="ps-md-3">
                                            <p className="mb-0">
                                                We use the official Twitter API which is secured and lets us grab your Twitter ID, your profile picture and minimal informations about your account.
                                                These informations are used to set up your UtilityFactory account and check your identity by using directly your project's twitter account.

                                                We use Metamask connection only for users offering WL spots or NFTs giveaways in order to check that your really own the wallet address you declared. We ask you to sign with metamask only for profile updates.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                            <h3 className="accordion-header" id="heading-3">
                                <button className="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                    Are token giveaways really free?
                                </button>
                            </h3>
                            <div className="accordion-collapse collapse" id="collapse-3" aria-labelledby="heading-3" data-bs-parent="#accordion-services">
                                <div className="accordion-body pt-0">
                                    <div className="d-flex flex-md-row flex-column align-items-md-center">
                                        <div className="ps-md-3">
                                            <p className="mb-0">
                                                Token giveaways are free for pro plan, you'll just need to pre-pay the transfer fees. Utility Factory will take care of everything,
                                                that's the reason why we ask for token approval and pre-paid transfer fees. At the end of the raffle, once your token has been won,
                                                Utility Factory will transfer the NFT to the winner.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                            <h3 className="accordion-header" id="heading-4">
                                <button className="accordion-button fs-xl shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                    What about the Genesis access pass ? What Genesis means for UtilityFactory ?
                                </button>
                            </h3>
                            <div className="accordion-collapse collapse" id="collapse-4" aria-labelledby="heading-4" data-bs-parent="#accordion-services">
                                <div className="accordion-body pt-0">
                                    <div className="d-flex flex-md-row flex-column align-items-md-center">
                                        <div className="ps-md-3">
                                            <p className="mb-0">
                                                Genesis is the first Access Passed delivered by Utility Factory. This means you really help up growing in the first days of our service.
                                            </p>
                                            <p className="mb-0">
                                                It means a lot for us. That's why in the next months, we will improve the service and reserve some features for the holders of the Genesis Access Pass.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        </section>

        { /* CTA */}
        <section className="bg-secondary py-5">
            <div className="container text-center py-1 py-md-4 py-lg-5">
                <h2 className="h1 mb-4">Ready to Get Started?</h2>
                <p className="lead pb-3 mb-3">Create your first giveaways for free in 5 minutes</p>
                <a className="btn btn-primary shadow-primary btn-lg mb-1" href={`${baseURL}/auth/twitter`}>Connect with twitter</a>
            </div>
        </section>

        <script src="assets/vendor/parallax-js/dist/parallax.min.js"></script>
    </>)
}