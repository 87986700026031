
import './RoadmapPage.css';

import { useState, useEffect } from "react";

function RoadmapPage(props) {


    return (<>
        <h1>Roadmap</h1>
        <p className="text-muted fs-sm">This is just the beginning, you are still early !</p>

        <ul className="timeline">
            <li>
                <div className="timeline-badge success"><i className="bx bx-check"></i></div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        <h4 className="timeline-title">Just a good idea</h4>
                        <p><small className="text-muted"><i className="bx bx-time"></i> July, 2022</small></p>
                    </div>
                    <div className="timeline-body">
                        <p>After having experienced many giveaways, we thought that a tool would be really interesting to manage raffles, token transfer and all details.</p>
                    </div>
                </div>
            </li>

            <li className="timeline-inverted">
                <div className="timeline-badge success"><i className="bx bx-check"></i></div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        <h4 className="timeline-title">First MVP</h4>
                        <p><small className="text-muted"><i className="bx bx-time"></i> August, 2022</small></p>
                    </div>
                    <div className="timeline-body">
                        <p>The first MVP is out ! You can add an Ethereum ERC721 token on UtilityFactory, create a raffle. We collect participants and randomly select a winner. We check the first condition : Twitter Follow. And it works ✌️</p>
                    </div>
                </div>
            </li>

            <li>
                <div className="timeline-badge success"><i className="bx bx-credit-card"></i></div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        <h4 className="timeline-title">Business model</h4>
                        <p><small className="text-muted"><i className="bx bx-time"></i> November, 2022</small></p>
                    </div>
                    <div className="timeline-body">
                        <p>After many giveaways, we are ready to create our first business model element. The UtilityFactory genesis token will allow you to access premium features. It also grants to its holder multiple advantages for the futures: less costs, better rewards.</p>
                    </div>
                </div>
            </li>

            <li className="timeline-inverted">
                <div className="timeline-badge success"><i className="bx bxl-discord"></i></div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        <h4 className="timeline-title">A discord bot</h4>
                        <p><small className="text-muted"><i className="bx bx-time"></i> February, 2023</small></p>
                    </div>
                    <div className="timeline-body">
                        <p>Our offer is developped : Multiple participation requirements (Twitter, Discord, Wallet), website or discord participation platforms.</p>
                    </div>
                </div>
            </li>

            <li>
                <div className="timeline-badge warning"><i className="bx bx-cog"></i></div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        <h4 className="timeline-title">A collab dashboard</h4>
                        <p><small className="text-muted"><i className="bx bx-time"></i> March, 2023</small></p>
                    </div>
                    <div className="timeline-body">
                        <p>Multiple improvements are made to create an extended collab management HQ for your project. An Experience model is created to improve your visibility and confidence in collab partners.</p>
                    </div>
                </div>
            </li>

            <li className="timeline-inverted">
                <div className="timeline-badge"><i className="bx bx-time"></i></div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        <h4 className="timeline-title">Collab Management ...</h4>
                        <p><small className="text-muted"><i className="bx bx-time"></i> Soon</small></p>
                    </div>
                    <div className="timeline-body">
                        <p>What would be the next step ? How to make Collab Management Easier again ?
                        This work is in progress 🚀</p>
                    </div>
                </div>
            </li>

            <li>
                <div className="timeline-badge"><i className="bx bx-time"></i></div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        <h4 className="timeline-title">Did you say, DeFi ?</h4>
                        <p><small className="text-muted"><i className="bx bx-time"></i> After</small></p>
                    </div>
                    <div className="timeline-body">
                        <p>Yes, you like DeFi. Let's make money now.
                        You've earn some experience since March 2023, good news. You will receive an airdrop of our ERC20 token.
                        We are not working on our token only, but we will improve Utility for your tokens too.</p>
                    </div>
                </div>
            </li>
        </ul>

    </>);
}

export default RoadmapPage;