
// import './TokensPanel.css';

import { useState, useEffect } from "react";
import { saveAs } from "file-saver";

import AddCollabRaffleModal from './AddCollabRaffleModal'
import StartCollabRaffleModal from './StartCollabRaffleModal'

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'

const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


const toStrDate = (d) => {
    const date = new Date(parseInt(d))

    let day = date.getDate()
    if (day < 10) day = '0' + day

    let month = (date.getMonth() + 1)
    if (month < 10) month = '0' + month

    let hours = (date.getHours())
    if (hours < 10) hours = '0' + hours

    let minutes = (date.getMinutes())
    if (minutes < 10) minutes = '0' + minutes

    // console.log("Return :", date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + hours + ':' + minutes)
    return date.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes
}

// const now = new Date()
// const minDate = toStrDate(now.getTime() + (1 * 3600 * 1000))
// const maxDate = toStrDate(now.getTime() + (60 * 24 * 3600 * 1000))

function CollabDiffuserRafflePage(props) {

    const [showStartRaffle, setShowStartRaffle] = useState(false)
    const [arrParticipationConditions, setParticipationConditions] = useState(undefined)
    const [showEditRaffle, setShowEditRaffle] = useState(false)
    const [arrRaffles, setArrRaffles] = useState(null)
    const [project, setProject] = useState(null)
    const [copyPasted, setCopyPasted] = useState(false)
    const [showAccepted, setShowAccepted] = useState(false)

    const [user, setUser] = useState(null)

    useEffect(() => {
        async function getUser() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}`
            })
            let user = requestObj.data

            setUser(user)
        }
        console.log("Effect : user = ", user)
        if (!user && props.currentUser.twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, props.currentUser.twitter])

    const fetchParticipationConditions = async () => {
        try {
            const response = await axios.get(`${baseURL}/users/participationconditions`)

            console.log("Response participation conditions :", response)
            setParticipationConditions(response.data)
        } catch (err) {
            //
        }
    }

    const revokeCollab = async (raffle) => {
        // Delete ...
        if (!window.confirm('Do you want to delete this collab ?')) {
            return
        }

        try {
            const response = await axios.patch(`${baseURL}/collabs/${raffle.id}/revoke`)

            console.log("Response collab owned:", response)
            fetchRaffles()

        } catch (err) {
            //
            console.log("Err delete collab owned :", err)
        }

    }

    const acceptCollab = async (raffle) => {
        try {
            const response = await axios.patch(`${baseURL}/collabs/${raffle.id}/accept`)

            console.log("Response collab owned:", response)
            fetchRaffles()
            setShowAccepted(true)
            setTimeout(() => { setShowAccepted(false) }, 20000)

        } catch (err) {
            //
            console.log("Err delete collab owned :", err)
        }

    }

    const fetchRaffles = async () => {

        try {
            const response = await axios.get(`${baseURL}/collabs/owned`)

            console.log("Response collab owned:", response)
            setArrRaffles(response.data)

        } catch (err) {
            //
            console.log("Err get collab owned :", err)
        }
    }

    useEffect(() => {
        if (typeof arrParticipationConditions === 'undefined')
            fetchParticipationConditions()

    }, [arrParticipationConditions])

    useEffect(() => {

        if (arrRaffles === null) {
            fetchRaffles()
        }

        if (copyPasted) {
            setTimeout(() => {
                setCopyPasted(false)
            }, 5000)
        }
    })

    const handleDownloadWinners = async (raffle) => {
        // 
        try {
            const csv = await axios.get(`${baseURL}/collabs/${raffle.id}/winners`)

            console.log("Response collab owned:", csv)
            const blob = new Blob([csv.data], { type: "text/csv;charset=utf-8" });
            saveAs(blob, 'Raffle_winners.csv');

        } catch (err) {
            //
            console.log("Err get collab owned' winners :", err)
        }
    }


    const raffleConditionsToStr = (strConditions) => {
        // console.log("setRaffleConditions :", arrParticipationConditions)
        if (!arrParticipationConditions) {
            return []
        }
        if (!strConditions) return []
        const conditions = strConditions.split(',')
        let arr = []
        for (let condition of conditions) {
            let arrCondition = condition.split('/')
            const found = arrParticipationConditions.find(c => c.id === arrCondition[0])
            if (found) {
                arr.push(found)
            }
        }
        return arr
    }

    return (<>
        <h1>Received collabs</h1>
        <h6 className="text-muted">Check received collab propositions</h6>

        {showAccepted ? (<div className="alert alert-success">
            <b>The collab has been accepted 🚀</b> You will find this into the "Giveaways" page, ready to be launched 🔥
        </div>) : null}

        {showEditRaffle ? (<AddCollabRaffleModal currentUser={props.currentUser} diffuser={true} project={project} projects={user.projects} raffle={showEditRaffle} updateRaffles={fetchRaffles} setShowAddRaffle={setShowEditRaffle} />) : null}

        {showStartRaffle ? (<StartCollabRaffleModal currentUser={props.currentUser} project={project} raffle={showStartRaffle} fetchRaffles={fetchRaffles} setShowPanel={setShowStartRaffle} />) : null}

        <ul className="nav nav-tabs-alt" role="tablist">
            {user && user.projects.map((project, idx) => (
                <li className="nav-item">
                    <a href={`#project${project.id}`} className={`nav-link ${(idx === 0) ? 'active' : ''}`} data-bs-toggle="tab" role="tab">
                        <i className="bx bx-profile opacity-70 me-2"></i>
                        {project.name}
                    </a>
                </li>
            ))}
        </ul>

        <div className="tab-content">
            {user && user.projects.map((project, idx) => (<>
                <div className={`tab-pane fade show ${idx === 0 ? 'active' : ''}`} id={`project${project.id}`} role="tabpanel">

                    {arrRaffles?.filter(r => r.diffuserProjectId === project.id).length === 0 ? (<div className="alert alert-info">
                        <b>No collab offered here 😢</b> You should ask for collabs on Twitter or other social medias so projects know you accept collabs.
                    </div>) : null}

                    <div className="row">
                        {arrRaffles && arrRaffles.filter(r => r.diffuserProjectId === project.id).map(raffle => (
                            <div className="card overflow-hidden col-md-6">
                                <div className="row g-0">
                                    <div className="col-sm-4 bg-repeat-0 bg-size-cover" style={{
                                        backgroundImage: `url(${raffle.image.replace('ipfs://', 'https://ipfs.io/ipfs/')}`, minHeight: '8rem'
                                    }}></div>
                                    <div className="col-sm-8">
                                        <div className="card-body">
                                            <h5 className="card-title">{raffle.name}</h5>
                                            <span className="badge bg-primary">From: <a className="text-white" href={`https://twitter.com/${raffle.project.ownerTwitter}`} target="_blank">{raffle.project.name}</a></span>
                                            <p className="card-text fs-sm">Min participants : {raffle.minParticipants}</p>
                                            <p className="card-text fs-sm">Conditions : {raffle.ownerMandatoryConditions ? raffleConditionsToStr(raffle.ownerMandatoryConditions).map(c => c.name).join(', ') : 'None'}</p>
                                            <p className="card-text fs-sm">
                                                <span className="p-1 m-1"><button className="btn btn-sm btn-success" onClick={() => { acceptCollab(raffle) }}>Accept</button></span>
                                                <span className="p-1 m-1"><button className="btn btn-sm btn-danger" onClick={() => { revokeCollab(raffle) }}>Refuse</button></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>))}
        </div>

    </>);
}

export default CollabDiffuserRafflePage;
