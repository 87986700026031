import axiosObj from 'axios'
import { getBaseURL } from './constants'
const baseURL = getBaseURL()

const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

const toHex = (stringToConvert) => {
    return stringToConvert.split("").map((c) => c.charCodeAt(0).toString(16).padStart(2, "0")).join("");
}

export const getSignedNonce = async (url, twitter, wallet) => {

    const urlNonce = `${baseURL}/users/${twitter}/nonce`

    const response = await axios({
        url: urlNonce,
        method: 'GET',
    })

    console.log("Response nonce :", response.data)

    const ret = await window.ethereum.request({
        method: "personal_sign",
        params: [
            "0x" + toHex(response.data.nonce),
            wallet,
        ]
    });

    return ret;
}
