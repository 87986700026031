
import { useRef, useState, useEffect } from 'react'
import { ethers } from 'ethers';
import axiosObj from 'axios';
import { getBaseURL } from '../utils/constants'
import { Editor } from '@tinymce/tinymce-react'

import ParticipationConditionsElement from './sub/ParticipationConditionsElement'

import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
// import { Shortcuts } from 'tinymce';

// import 'tinymce/tinymce.min.js'

const baseURL = getBaseURL()
const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

function isValidHttpUrl(str) {
    let url;
    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

const toStrDate = (d) => {
    const date = new Date(parseInt(d))

    let month = (date.getMonth() + 1)
    if (month < 10) month = '0' + month

    let hours = (date.getHours())
    if (hours < 10) hours = '0' + hours

    let minutes = (date.getMinutes())
    if (minutes < 10) minutes = '0' + minutes

    // console.log("Return :", date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + hours + ':' + minutes)
    return date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + hours + ':' + minutes
}
const now = Date.now()

export default function AddCollabRaffleModal(props) {

    let setShowPanel = props.setShowPanel

    const editorRef = useRef(null);
    // const log = () => {
    //     if (editorRef.current) {
    //         console.log(editorRef.current.getContent());
    //     }
    // };


    const defaultRaffle = {
        blockchain: props.projects.blockchain,
        name: '',
        description: '<p>What can we win ? Why this collab ?</p>',
        image: props.currentUser.image,
        bannerImage: '',
        website: '',
        twitter: props.currentUser.twitter,
        discord: '',
        ownerMandatoryConditions: `TwitterFollow/${props.currentUser.twitter}`,
        ownerOptionalConditions: '',
        minParticipants: 1,
        maxMints: 1,
        nbSeats: 1,
        projectId: props.project.id,
        prizes: []
    }
    const [raffleToAdd, setRaffleToAdd] = useState(props.raffle ? {
        ...props.raffle,
        nbSeats: props.raffle.prizeTokens.length > 0 ? 1 : props.raffle.prizeWLs.length > 0 ? props.raffle.prizeWLs[0].nbSeats : 1,
        maxMints: props.raffle.prizeTokens.length > 0 ? 0 : props.raffle.prizeWLs.length > 0 ? props.raffle.prizeWLs[0].maxMints : 1,
        prizes: [
            ...props.raffle.prizeTokens.map(t => { return { type: 'token', id: t.id } }),
            ...props.raffle.prizeWLs.map(t => { return { type: 'wl', nbSeats: t.nbSeats, maxMints: t.maxMints } }),
        ]
    } : defaultRaffle)
    const [prizeCategory, setPrizeCategory] = useState(props.raffle?.prizeTokens ? props.raffle.prizeTokens.length > 0 ? 'token' : 'wl' : 'wl')
    const [minEndDate, setMinEndDate] = useState(new Date())
    const [maxEndDate, setMaxEndDate] = useState(new Date(now + Math.floor((6.9 * 24 * 3600 * 1000))))

    const updateEndDates = () => {
        console.log("Start date :", raffleToAdd.raffleStartDate)
        if (raffleToAdd.raffleStartDate) {
            console.log("Update end dates :")
            setMinEndDate(raffleToAdd.raffleStartDate + (30 * 60 * 1000))
            setMaxEndDate(raffleToAdd.raffleStartDate + (7 * 24 * 3600 * 1000) - (2 * 3600 * 1000))
        }
    }

    let initConditions = []

    if (props.raffle) {

        const strInitConditions = props.raffle.ownerMandatoryConditions + ',' + props.raffle.ownerOptionalConditions
        for (let condition of strInitConditions.split(',')) {

            const splitCondition = condition.split('/')
            initConditions.push({
                id: splitCondition[0],
                values: splitCondition.splice(1)
            })
        }
    } else {
        initConditions = [{ id: 'TwitterFollow', values: [props.currentUser.twitter] }]
    }

    const [selectedCondition, setSelectedCondition] = useState(initConditions)
    const [arrPrizes, setArrPrizes] = useState(null)

    useEffect(() => {
        async function getPrizes() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/prizes?collabId=${props.raffle ? props.raffle.id : ''}`
            })
            let arr = requestObj.data.prizes

            console.log("Arr prizes :", arr)
            setArrPrizes(arr)
        }
        if (!arrPrizes) {
            console.log("Arr prizes null ... on get")
            getPrizes()
        }
    }, [arrPrizes])

    let {
        blockchain,
        name,
        description,
        image,
        bannerImage,
        website,
        projectId,
        minParticipants,
        raffleStartDate,
        raffleEndDate,
        nbSeats,
        maxMints,
    } = raffleToAdd

    console.log("Raffle to add :", raffleToAdd)

    const setValue = (k, v) => {
        // console.log("Set value", k, v)
        setRaffleToAdd({
            ...raffleToAdd,
            [k]: v
        })

        setTimeout(updateEndDates, 350)
    }

    const onClick = async () => {

        if (!name) {
            alert(`You must provide a name ;)`)
            return
        }

        if (!props.owner && !description) {
            alert('You must set a description for your giveaway. Add emojis, a short description of what the winner can win.')
            return
        }

        if (!props.owner && !raffleToAdd.image) {
            alert(`You must provide an image URL`)
            return
        }

        if (!props.owner && !isValidHttpUrl(raffleToAdd.image) || (raffleToAdd.bannerImage && !isValidHttpUrl(raffleToAdd.bannerImage))) {
            alert(`You must provide valid image URLs`)
            return
        }

        if (!props.owner && raffleStartDate && !raffleEndDate) {
            alert('You set a start date, but no end date. Please fill the end date or remove the start date to save this raffle.')
            return
        }

        // Prizes :
        if (prizeCategory === 'wl') {
            raffleToAdd.prizes = [{ type: 'wl', nbSeats: nbSeats, maxMints: maxMints }]
        } else {
            raffleToAdd.nbSeats = 1
            delete raffleToAdd.maxMints
        }

        try {

            if (props.raffle) {
                // Edit :

                console.log("Edit :", selectedCondition)

                await axios({
                    method: 'PATCH',
                    url: `${baseURL}/collabs/${props.raffle.id}`,
                    data: {
                        ...raffleToAdd,
                        selectedCondition: selectedCondition.map(c => !c.values || c.values.length === 0 ? c.id : c.id + "/" + c.values.map(v => encodeURIComponent(v)).join('/'))
                    }
                })

            } else {
                // Add :

                await axios({
                    method: 'POST',
                    url: `${baseURL}/collabs/`,
                    data: {
                        ...raffleToAdd,
                        selectedCondition: selectedCondition.map(c => !c.values || c.values.length === 0 ? c.id : c.id + "/" + c.values.map(v => encodeURIComponent(v)).join('/'))
                    }
                })
            }
            props.setShowAddRaffle(false)
            props.updateRaffles()

        } catch (err) {
            console.log("Error add collab :", err)
            alert('An error occured ... sorry for that :(')
        }
    }

    console.log("Project :", props.project)

    return (<div className="modal show" role="dialog" style={{ display: 'block' }} id="profile">
        <div className="modal-dialog" role="document" style={{ maxWidth: '900px' }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add/Edit a Collab</h4>
                    <button type="button" onClick={() => { props.setShowAddRaffle(false); }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="mb-4">
                            <label className="form-label">Project name</label>
                            <select className="form-control">
                                <option disabled={true} selected={true} value={props.project.id}>{props.project.name}</option>
                            </select>
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Raffle name<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" disabled={props.diffuser} value={name} onChange={(e) => { setValue('name', e.target.value) }} placeholder={`${props.currentUser.name} x partner name`} />
                        </div>

                        <ParticipationConditionsElement currentUser={props.currentUser} project={props.projects.find(p => p.id === projectId)} selectedCondition={selectedCondition} setSelectedCondition={setSelectedCondition} />

                        <div className="mb-4">
                            <label className="form-label">Minimum participants</label>
                            <input type="number" className="form-control form-control-lg" min={1} max={10000} value={minParticipants} onChange={(e) => { setValue('minParticipants', e.target.value) }} />
                            <small className="text-muted">If the raffle has less participants than this number, the raffle will be cancelled and the prize(s) will be returned to you. Value between 1 and 10 000.</small><br />
                        </div>

                        <div className="mb-3">
                            <label className="form-label" >Prizes to win</label><br />
                            <small className="text-muted">Selected what the winner(s) will receive : WL or a token ?</small><br />
                            {[{ id: 'wl', label: 'WhiteList spots', description: 'Configure a WL spot offer : Number of spots to win and number of mints offered for each WL spot.' }, { id: 'token', label: 'Token', description: 'Select a token from the "Offers" you previously added. We will transfer the token to the winner automatically.' }].map(category => (<div className="ms-4">
                                <h6 className="mt-3 px-1">{category.icon ? (<span className={category.icon} style={{ color: (category.iconColor) ? category.iconColor : 'blue' }}></span>) : null} {category.category}</h6>
                                <div className="list-group">
                                    <div className="list-group-item">
                                        <div className="form-check">
                                            <div class="form-check form-switch">
                                                <input type="checkbox" onClick={() => { setPrizeCategory(category.id) }} className="form-check-input" id="customSwitch1" checked={prizeCategory === category.id} />
                                                <label
                                                    style={{ cursor: 'help', textDecoration: 'underline', textDecorationStyle: 'dotted' }}
                                                    className="form-check-label"
                                                    data-bs-container="body"
                                                    data-bs-toggle="popover"
                                                    data-bs-placement="top"
                                                    data-bs-trigger="hover"
                                                    data-bs-title={category.label}
                                                    data-bs-content={category.description}
                                                    for="flexCheckDefault">
                                                    {category.label}
                                                </label>
                                            </div>

                                            {category.id === prizeCategory && prizeCategory === 'wl' ? (<div>
                                                <div className="mb-4">
                                                    <label className="form-label">Number of spots to win</label>
                                                    <input type="number" min="1" max="10" className="form-control" value={nbSeats} onChange={(e) => { setValue('nbSeats', e.target.value) }} />
                                                    <p className="form-text"></p>
                                                </div>

                                                <div className="mb-4">
                                                    <label className="form-label">Maximum mints per WL</label>
                                                    <input type="number" className="form-control" value={maxMints} onChange={(e) => { setValue('maxMints', e.target.value) }} />
                                                </div>

                                            </div>) : null}

                                            {category.id === prizeCategory && prizeCategory === 'token' ? (<div className="list-group">
                                                {arrPrizes && arrPrizes.filter(p => p.projectId === props.project.id).filter(p => p.type === category.id).map(p => (<li className="list-group-item">
                                                    <input type="radio" name="prize" checked={raffleToAdd?.prizes ? raffleToAdd.prizes.find(prize => p.id === prize.id) : false} value={p.id} onClick={(e) => { console.log("Get ", e.target.value); setValue('prizes', [{ type: 'token', id: e.target.value }]) }} className="mx-2" />
                                                    <img src={p.image.replace('ipfs://', 'https://ipfs.io/ipfs/')} width={80} height={80} /> <b>{p.name}</b> (#{parseInt('' + p.tokenId, 16)})
                                                </li>))}
                                            </div>) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="list-group">
                                    {arrPrizes && arrPrizes.filter(p => p.projectId === props.project.id).filter(p => p.type === category.id).map(p => (<li className="list-group-item">
                                        <input type="radio" name="prize" value={p.type + ':' + p.id} onClick={(e) => { console.log("Get ", e.target.value); setValue('prizes', [e.target.value]) }} className="mx-2" />
                                        {p.type === 'wl' ?
                                            (<>{p.nbSeats} x {p.type}</>) :
                                            (<>{p.name} - {p.type}</>)
                                        }
                                    </li>))}
                                </div> */}
                            </div>))}
                        </div>

                    </form>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-light" type="button" onClick={() => { props.setShowAddRaffle(false) }} data-bs-dismiss="modal">Close</button>
                    <button className="btn btn-primary" type="button" onClick={() => { onClick() }}>
                        {props.raffle ? "Edit collab" : "Add collab"}
                    </button>
                </div>
            </div>
        </div>
    </div>)
}