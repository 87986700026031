
import { useState, useEffect } from 'react'

import axiosObj from 'axios'
import { getBaseURL } from '../../utils/constants'

import { useAccount, useNetwork, useSwitchNetwork, useConnect, useContractRead, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'

const baseURL = getBaseURL()
const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

const OPERATOR_ADDR = '0x8826d691030236A6C68012a960a78AAc2aebDF06'
const COLLABFACTORY_CONTRACT_ADDR = {
    'goerli': '0x7B4d3B00BFfC30f9FC51B94a5C5169Cd9E772276', // goerli
    'homestead': '0xd8CF802350948b71e0031729b233705051d1AF2d', // mainnet
    'arbitrum': '0xd8CF802350948b71e0031729b233705051d1AF2d', // arb mainnet
    'arbitrumRinkeby': '', // arb testnet
    'polygon': '0xc7820c54D90cA43BafFF57897bee1D292c5cE164', // polygon mainnet
    'mumbai': '0x004256CFBA6B0d984cab089d24Dd4CaF77F32316', // mumbai : polygon testnet
}
const COLLABFACTORY_CONTRACT_ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_owner",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint8",
                "name": "tokenType",
                "type": "uint8"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "_contract",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "_prepaid",
                "type": "uint256"
            }
        ],
        "name": "AddToken",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "a",
                "type": "address"
            }
        ],
        "name": "addAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "adminAddr",
                "type": "address"
            },
            {
                "internalType": "uint8",
                "name": "tokenType",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "contractAddr",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "addToken",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "collectionAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "checkFreeMint",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "collectionAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "checkWL",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "a",
                "type": "address"
            }
        ],
        "name": "removeAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawMoney",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
]

const ABIs = require('../../utils/abis.json')

export default function AddPrizeTokenModal(props) {

    let { user, project } = props
    let { twitter } = props.currentUser

    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()

    const [waitingMessage, setWaitingMessage] = useState('Making transactions on blockchain...')

    const [lstNFTs, setLstNFTs] = useState(null)
    const [waitingForProcess, setWaitingForProcess] = useState(false)

    // Approve token :
    const [tokenInfos, setTokenInfos] = useState({ contractAddress: false, tokenId: false, tokenType: false, tokenTypeId: false, tokenAmount: 0, value: 0 })
    const [addSignature, setAddSignature] = useState({ v: '', r: '', s: '', techTax: 0, estimatedFees: 0 })

    // Wagmi :
    const { address, connector, isConnected } = useAccount()
    const { connect, connectors, error: errorConnect, isLoading: isLoadingConnect, pendingConnector } = useConnect()

    const { config: configApprove, error: prepareErrorApprove, isError: isPrepareErrorApprove } = usePrepareContractWrite({
        address: tokenInfos.contractAddress,
        abi: ABIs[tokenInfos.tokenType],
        functionName: (tokenInfos.tokenType === 'ERC1155') ? 'approveForAll' : 'approve', // depends on tokenType
        args: (tokenInfos.tokenType === 'ERC721') ? [OPERATOR_ADDR, tokenInfos.tokenId] : (tokenInfos.tokenType === 'ERC1155') ? [] : [], // tokenId, operator, depends on tokenType
        enabled: tokenInfos?.tokenType && !addSignature.v,
    })
    const { write: writeApprove, error: errorWriteApprove, isError: isErrorApprove, data: dataApprove } = useContractWrite(configApprove)
    const { isLoading: isLoadingApprove, isSuccess: isSuccessApprove } = useWaitForTransaction({
        hash: dataApprove?.hash,
    })

    console.log("Chain :", chain)

    const { config: configAdd, error: prepareErrorAdd, isError: isPrepareErrorAdd } = usePrepareContractWrite({
        address: COLLABFACTORY_CONTRACT_ADDR[chain ? chain.network : 'ethereum'],
        abi: COLLABFACTORY_CONTRACT_ABI,
        functionName: 'addToken',
        args: [
            OPERATOR_ADDR,
            tokenInfos ? (tokenInfos.tokenType === 'ERC20') ? 1 : (tokenInfos.tokenType === 'ERC721') ? 2 : 3 : 0,
            tokenInfos ? tokenInfos.contractAddress : '',
            tokenInfos ? (tokenInfos.tokenType === 'ERC20') ? tokenInfos.tokenId : (tokenInfos.tokenType === 'ERC721') ? tokenInfos.tokenId : tokenInfos.tokenTypeId : '',
            tokenInfos ? tokenInfos.tokenAmount : 0,
            addSignature ? addSignature.v : '',
            addSignature ? addSignature.r : '',
            addSignature ? addSignature.s : '',
        ],
        overrides: {
            from: address,
            value: `${addSignature ? addSignature.estimatedFees : ''}`,
        },
        enabled: tokenInfos && addSignature.r,
    })


    const { write: writeAdd, error: errorWriteAdd, isError: isErrorAdd, data: dataAdd } = useContractWrite(configAdd)
    const { isLoading: isLoadingAdd, isSuccess: isSuccessAdd } = useWaitForTransaction({
        hash: dataAdd?.hash,
    })

    console.log("Current state : configApprove:", configApprove, "prepareErrorApprove:", prepareErrorApprove, "is error approve :", isErrorApprove, "data approve:", dataApprove, "isLoadingApprove:", isLoadingApprove, "success:", isSuccessApprove)
    console.log("Current state of add write : enabed :", tokenInfos && addSignature.r, writeAdd, "prepare: ", configAdd)

    useEffect(() => {

        console.log("Errors :", isErrorAdd, isErrorApprove)
        if ((isErrorAdd || isErrorApprove)) {


            // if (isPrepareErrorApprove) {
            //     console.error("Error prepare approve :", prepareErrorApprove, typeof prepareErrorApprove)
            // }
            if (isErrorApprove) {
                console.error("Error approve :", errorWriteApprove, typeof errorWriteApprove)
            }
            // if (isPrepareErrorAdd) {
            //     console.error("Error prepare add :", prepareErrorAdd, typeof prepareErrorAdd)
            // }
            if (isErrorAdd) {
                console.error("Error prepare :", errorWriteAdd, typeof errorWriteAdd)
            }

            window.alert("An error occured. Please check your wallet balance, your wallet must be connected and unlocked.")
            props.setShowPanel(false)
            setTimeout(() => { props.fetchOwnedTokens() }, 5000)
        }

    }, [isPrepareErrorAdd, isErrorAdd, isPrepareErrorApprove, isErrorApprove])

    const contractReadIsApproved = useContractRead({
        address: tokenInfos.contractAddress,
        abi: ABIs[tokenInfos.tokenType],
        functionName: (tokenInfos.tokenType === 'ERC1155') ? 'isApprovedForAll' : (tokenInfos.tokenType === 'ERC721') ? 'getApproved' : 'allowance', // isApprovedForAll(account, operator) // getApproved(tokenId) // allowance(address owner, address spender)
        args: (tokenInfos.tokenType === 'ERC721') ? [tokenInfos.tokenId] : (tokenInfos.tokenType === 'ERC1155') ? [] : [], // tokenId, operator, depends on tokenType
        enabled: tokenInfos.tokenType,
    })

    const contractReadIsApprovedAfter = useContractRead({
        address: tokenInfos.contractAddress,
        abi: ABIs[tokenInfos.tokenType],
        functionName: (tokenInfos.tokenType === 'ERC1155') ? 'isApprovedForAll' : (tokenInfos.tokenType === 'ERC721') ? 'getApproved' : 'allowance', // isApprovedForAll(account, operator) // getApproved(tokenId) // allowance(address owner, address spender)
        args: (tokenInfos.tokenType === 'ERC721') ? [tokenInfos.tokenId] : (tokenInfos.tokenType === 'ERC1155') ? [] : [], // tokenId, operator, depends on tokenType
        enabled: isSuccessApprove,
    })

    console.log("Contract is approved :", contractReadIsApproved)

    const goAskForAddSignature = async () => {
        //
        if (!tokenInfos) {
            return
        }

        console.log("Token infos :", tokenInfos)

        try {

            setWaitingMessage('Waiting for adding parameters')
            const addResponse = await axios({
                method: 'GET',
                url: `${baseURL}/prizes/sign?wallet=${address}&contractAddress=${tokenInfos.contractAddress}&tokenType=${tokenInfos.tokenType}&tokenId=${tokenInfos.tokenId}&tokenTypeId=${tokenInfos.tokenTypeId}&tokenAmount=${tokenInfos.tokenAmount}&projectId=${props.project.id}`
            })

            // When get signature + price :
            console.log("Update addSignature :", addResponse.data)
            // const { v, r, s, estimatedFees, techTax, gasFeePrepay } = addResponse.data

            if (addResponse.data.error) {

                alert('An error occured. Please open a ticket on our Disord server so that our tech team can fix this 😢')

            } else {

                setAddSignature(addResponse.data)
            }

        } catch (err) {
            console.error("Error get signature :", err)
        }
    }


    useEffect(() => {

        if (!tokenInfos) return;

        console.log("Token infos changed :", tokenInfos, user)

        console.log("Project :", project)
        let chainIdProject = (project.blockchain === 'ethereum') ? 1 : (project.blockchain === 'ethereum') ? 137 : 42161
        if (baseURL.indexOf('localhost') > -1) {
            if (chainIdProject === 1) {
                chainIdProject = 5
            } else if (chainIdProject === 137) {
                chainIdProject = 80001
            } else if (chainIdProject === 42161) {
                chainIdProject = 421611
            }
        }

        if (chain.id !== chainIdProject) {
            switchNetwork?.(chainIdProject)
        }

        // Get is approved :
        console.log("Already approved ?", contractReadIsApproved.data, OPERATOR_ADDR)
        if (contractReadIsApproved.data !== OPERATOR_ADDR) {
            // Must approve :
            console.log("Launch writeApprove :", writeApprove)
            setWaitingMessage('Waiting for approval...')
            writeApprove?.()
        } else {
            console.log("Ok ... c'est DEJA OK, tu veux quoi de plus ????")
            goAskForAddSignature()
        }

    }, [chain, contractReadIsApproved.data])


    // If approved :
    useEffect(() => {

        console.log("isSuccessApprove :", isSuccessApprove, contractReadIsApprovedAfter.data)

        // Ok, go add it
        if (isSuccessApprove && contractReadIsApprovedAfter.data === OPERATOR_ADDR) {
            console.log("Ok ... on a l'approve, donc on demande la signature")
            goAskForAddSignature()
        }

    }, [isSuccessApprove])

    const handleAddToken = () => {

        console.log("Add signature :", addSignature, dataAdd)
        if (addSignature.r && writeAdd && !dataAdd) {
            console.log("Write add :", writeAdd)
            setWaitingMessage('Waiting for fees pre-paiement...')
            writeAdd?.()
        }

    }

    useEffect(() => {

        console.log("Tx add changed :", dataAdd)

        if (!dataAdd || isLoadingAdd) {
            return;
        }

        console.log("Data add is loading ? :", isLoadingAdd)

        const postAddToken = async () => {

            try {

                const addBCResponse = await axios({
                    method: 'POST',
                    url: `${baseURL}/prizes/tokens`,
                    data: {
                        blockchain: project.blockchain,
                        contract: tokenInfos.contractAddress,
                        tokenType: tokenInfos.tokenType,
                        tokenAmount: tokenInfos.tokenAmount,
                        tokenId: tokenInfos.tokenId,
                        tokenTypeId: tokenInfos.tokenTypeId,
                        txApprove: dataApprove?.hash,
                        txAdd: dataAdd.hash,
                        techTax: addSignature?.techTax,
                        gasFeePrepay: addSignature.gasFeePrepay,
                        projectId: props.project.id,
                        image: tokenInfos.image,
                        name: tokenInfos.name,
                    }
                })

                if (addBCResponse) {

                    props.updateList()
                    setWaitingForProcess(false)
                    props.setShowPanel(false)

                } else {
                    // Error ?
                    console.log("Error post add (if) :", addBCResponse)
                }

            } catch (err) {
                // Error ?
                console.log("Error post add :", err)
            }
        }

        postAddToken()

    }, [dataAdd, isLoadingAdd])


    useEffect(() => {
        const fetchLstNfts = async () => {

            try {
                const response = await axios.get(`${baseURL}/users/${twitter}/getNfts/${props.project.id}?wallet=${address}`)

                // console.log("Response offered :", response)
                // Filter if already in arr of owned tokens :
                const project = props.project
                let arrFiltered = response.data.filter(token => {
                    return !props.arrTokensOwned?.find(tokenAdded => tokenAdded.blockchain === project.blockchain && tokenAdded.tokenId === token.tokenId && tokenAdded.contract === token.contractAddress)
                })
                setLstNFTs(arrFiltered)

            } catch (err) {
                //
                console.error("Err fetch nfts :", err)
            }
        }

        if (!isConnected) return
        if (user && !lstNFTs)
            fetchLstNfts()
    }, [isConnected, user, lstNFTs])


    if (!address) {

        return (<div className="modal" role="dialog" style={{ display: 'block' }} id="profile">
            <div className="modal-dialog" role="document">
                <div className="modal-content modal-lg">
                    <div className="modal-header">
                        <h4 className="modal-title">Connect first</h4>
                        <button type="button" onClick={() => { props.setShowPanel(false); }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="btn-group">
                            {
                                connectors.map((connector) => (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        disabled={!connector.ready}
                                        key={connector.id}
                                        onClick={() => connect({ connector })}
                                    >
                                        {connector.name}
                                        {!connector.ready && ' (unsupported)'}
                                        {isLoadingConnect &&
                                            connector.id === pendingConnector?.id &&
                                            ' (connecting)'}
                                    </button>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    if (tokenInfos && tokenInfos.contractAddress) {

        return (<div className="modal" role="dialog" style={{ display: 'block' }} id="profile">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Adding token</h4>
                        <button type="button" onClick={() => { props.setShowPanel(false); }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-8 p-2">
                                <div className="card card-hover">
                                    <img src={tokenInfos.image} className="card-img-top" alt={tokenInfos.name} style={{ objectFit: "cover", height: "250px" }} />
                                    <div className="card-body justify-content-center align-items-center">
                                        <h5 className="card-title">{tokenInfos.name}</h5>
                                        {addSignature && !dataAdd?.hash && writeAdd ? (<>
                                            <ul className="list-group my-3">
                                                <li className="list-group-item"><i className="bx bx-check" style={{ color: 'green' }} />You approved UtilityFactory to take care of transfer as soon as the raffle is done</li>
                                                <li className="list-group-item"><i className="bx bx-check" style={{ color: 'green' }} />Transfer fees - We will execute the transfer, we estimate future transfer fee : {(addSignature.gasFeePrepay / 1e18).toFixed(6)}  </li>
                                                {/* <li className="list-group-item"><i className="bx bx-check" style={{ color: 'green' }} />{props.currentUser.passType === '' ? 'You have no pass, we take a 50$ fee to add tokens' : 'You have a pass, thanks ;) Don\'t have to pay 50$ fee'}</li> */}
                                            </ul>
                                            <button type="button" className="btn btn-primary" onClick={() => { handleAddToken() }}>
                                                <i className="bx bx-rocket"></i>
                                                Pre-pay transfer fees &amp; add token
                                            </button>
                                        </>) : (
                                            <button type="button" className="btn btn-primary pe-none">
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                {waitingMessage}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >)
    }

    return (<div className="modal" role="dialog" style={{ display: 'block' }} id="profile">
        <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Choose a token to offer</h4>
                    <button type="button" disabled={waitingForProcess} onClick={() => { props.setShowPanel(false); }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div>
                    <div className="alert alert-info" role="alert">
                        You will have to approve our wallet to transfer the token(s) to the winner and pre-pay the gas fees for this transfer.
                        The amount paid is not redeemable.
                        For ERC1155 tokens, you have to approveForAll for technical reasons. We advise you to put the token on a dedicated wallet.
                    </div>
                </div>
                <div className="modal-body">
                    {!user?.projects ? (<div>No project here ... Please configure a project in your account</div>) : isLoadingApprove && isLoadingAdd && !tokenInfos ? (<div>
                        Loading
                    </div>) : (
                        <div className="row">
                            {lstNFTs ? (lstNFTs.length > 0 ? lstNFTs.filter(t => t.name && t.tokenId).map(nft => (
                                <div className="col-3 p-2">
                                    <div className="card card-hover">
                                        <img src={nft.image} className="card-img-top" alt={nft.name} style={{ objectFit: "cover", height: "250px" }} />
                                        <div className="card-body">
                                            <h5 className="card-title">{nft.name}</h5>
                                            <p className="card-text fs-sm">{nft.description?.substr(0, 55)}...</p>
                                            <button className="btn btn-sm btn-primary" onClick={() => { console.log("Set token info :", nft); setTokenInfos(nft) }}>Select</button>
                                        </div>
                                    </div>
                                </div>
                            )) : (
                                <div className="text-center">
                                    You have no token on this wallet ...
                                </div>)) : (
                                <div className="text-center">
                                    <div className="spinner-border spinner-border" role="status" style={{ width: '3.5rem', height: '3.5rem' }} >
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>)
}