import React from 'react'
import { useLayoutEffect } from 'react'

import ReactDOM from 'react-dom/client'
import './index.css'

import Home from './Home'
import App from './App'
import DiffuserPage from './DiffuserPage'
import RaffleChecker from './components/RaffleChecker'

import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom"


const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Wrapper>
      <Routes>
        <Route path='/collab/:diffuserId' element={<DiffuserPage />} />
        <Route path="/rafflecheck/:type/:raffleId/:participationId" element={<RaffleChecker />} />
        <Route exact path='/' element={<Navigate to="/home" />} />
        <Route exact path='/signout' element={<Navigate to="/auth/logout" />} />
        <Route path="*" element={<App />} />
      </Routes>
    </Wrapper>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
