
// import './TokensPanel.css';

import { useState, useEffect } from "react";

import Profile from "./Profile"
import MyPassPage from "./MyPassPage"

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
import SubscribePlanPage from "./SubscribePlanPage"

const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


function MyAccountPage(props) {

    return (<>
        <ul className="nav nav-tabs-alt" role="tablist">
            <li className="nav-item">
                <a href="#home1" className="nav-link active" data-bs-toggle="tab" role="tab">
                    <i className="bx bx-user opacity-70 me-2"></i>
                    My Profile
                </a>
            </li>
            <li className="nav-item">
                <a href="#plan1" className="nav-link" data-bs-toggle="tab" role="tab">
                    <i className="bx bx bx-briefcase opacity-70 me-2"></i>
                    Pro Plan
                </a>
            </li>
            <li className="nav-item">
                <a href="#profile1" className="nav-link" data-bs-toggle="tab" role="tab">
                    <i className="bx bx bx-key opacity-70 me-2"></i>
                    My Pass
                </a>
            </li>
        </ul>

        <div className="tab-content">
            <div className="tab-pane fade show active" id="home1" role="tabpanel">
                <Profile {...props} />
            </div>
            <div className="tab-pane fade" id="plan1" role="tabpanel">
                <SubscribePlanPage {...props} />
            </div>
            <div className="tab-pane fade" id="profile1" role="tabpanel">
                <MyPassPage {...props} />
            </div>
        </div>

    </>);
}

export default MyAccountPage;
