
// import './TokensPanel.css';

import { useState, useEffect } from "react";
import { saveAs } from "file-saver";

import {
    RouterProvider,
    Link,
    Routes,
    NavLink,
    Route
} from "react-router-dom";

import AddLightRaffleModal from './AddLightRaffleModal'
import StartLightRaffleModal from './StartLightRaffleModal'

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'

const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


const now = Date.now()
function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    return str;
}


const toStrDate = (d) => {
    const date = new Date(parseInt(d))

    let day = date.getDate()
    if (day < 10) day = '0' + day

    let month = (date.getMonth() + 1)
    if (month < 10) month = '0' + month

    let hours = (date.getHours())
    if (hours < 10) hours = '0' + hours

    let minutes = (date.getMinutes())
    if (minutes < 10) minutes = '0' + minutes

    // console.log("Return :", date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + hours + ':' + minutes)
    return date.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes
}

// const now = new Date()
// const minDate = toStrDate(now.getTime() + (1 * 3600 * 1000))
// const maxDate = toStrDate(now.getTime() + (60 * 24 * 3600 * 1000))

function LightRafflesPage(props) {

    const [showAddRaffle, setShowAddRaffle] = useState(false)
    const [showStartRaffle, setShowStartRaffle] = useState(false)
    const [showEditRaffle, setShowEditRaffle] = useState(false)
    const [arrRaffles, setArrRaffles] = useState(null)
    const [copyPasted, setCopyPasted] = useState(false)
    const [filter, setFilter] = useState('all')
    const [filterRaffles, setFilterRaffles] = useState('')
    const [onlyCollabs, setOnlyCollabs] = useState(false)

    const [user, setUser] = useState(null)

    useEffect(() => {
        async function getUser() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}`
            })
            let user = requestObj.data

            setUser(user)
        }
        console.log("Effect : user = ", user)
        if (!user && props.currentUser.twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, props.currentUser.twitter])

    const deleteLightRaffle = async (raffle) => {
        // Delete ...

        if (raffle.type === 'collab') {

            if (!window.confirm('Do you want to revoke this collab ?')) {
                return
            }

            try {
                const response = await axios.patch(`${baseURL}/collabs/${raffle.id}/revoke`)

                console.log("Response reovke collab raffle :", response)
                fetchRaffles()

            } catch (err) {
                //
                console.log("Err revoke collab raffles :", err)
            }


        } else {

            if (!window.confirm('Do you want to delete this raffle ?')) {
                return
            }

            try {
                const response = await axios.delete(`${baseURL}/lightraffles/${raffle.id}`)

                console.log("Response light raffle :", response)
                fetchRaffles()

            } catch (err) {
                //
                console.log("Err delete light raffles :", err)
            }
        }


    }

    const fetchRaffles = async () => {

        try {
            const response = await axios.get(`${baseURL}/lightraffles/`)
            // console.log("Response light raffle :", response)
            let arrRaffles = response.data.map(r => { return { ...r, type: 'light' } })

            // console.log("Arr raffles :", arrRaffles.length)

            const responseCollab = await axios.get(`${baseURL}/collabs/accepted`)
            // console.log("Response collab raffle :", responseCollab)
            arrRaffles.push(...responseCollab.data.map(r => { return { ...r, type: 'collab' } }))

            // console.log("Arr raffles post collab :", arrRaffles.length)

            arrRaffles.sort((a, b) => {
                
                if (a.raffleEndDate) {

                    if (a.raffleEndDate < now) {
                        // 
                        return (b.raffleEndDate - a.raffleEndDate) ? 1 : -1
                    }

                    return a.raffleEndDate - b.raffleEndDate
                    
                } else if (a.raffleStartDate) {
                    return a.raffleStartDate - b.raffleStartDate
                }

                return a.updatedAt - b.updatedAt
            })
            setArrRaffles(arrRaffles)

        } catch (err) {
            //
            console.log("Err get light raffles :", err)
        }
    }

    useEffect(() => {

        if (arrRaffles === null) {
            fetchRaffles()
        }

        if (copyPasted) {
            setTimeout(() => {
                setCopyPasted(false)
            }, 5000)
        }
    })

    const handleDownloadWinners = async (raffle) => {
        // 
        try {
            let csv

            if (raffle.type === 'collab') {

                csv = await axios.get(`${baseURL}/collabs/${raffle.id}/winners`)
                console.log("Response collab raffle :", csv)

            } else {

                csv = await axios.get(`${baseURL}/lightraffles/${raffle.id}/winners`)
                console.log("Response light raffle :", csv)
            }

            const blob = new Blob([csv.data], { type: "text/csv;charset=utf-8" });
            saveAs(blob, 'Raffle_winners.csv');

        } catch (err) {
            //
            console.log("Err get light raffles' winners :", err)
        }
    }

    const handleDuplicate = async (raffle) => {
        // 
        try {

            if (raffle.type === 'collab') {

                alert('Cannot duplicate a collab 😅')
                return

            }

            const response = await axios.patch(`${baseURL}/lightraffles/${raffle.id}/duplicate`)
            console.log("Response duplicate light raffle :", response)

            if (response) {
                alert('Raffle duplicated 🔥')
                fetchRaffles()
            }

        } catch (err) {
            //
            console.log("Err duplicate light raffle:", err)
        }
    }

    const funcFilter = (r) => {

        if (filter === 'all') return true;
        if (filter === 'pending') return !r.started && !r.raffleStartDate;
        if (filter === 'programmed') return !r.started && r.raffleStartDate;
        if (filter === 'started') return r.started && r.raffleEndDate && Date.now() < r.raffleEndDate;
        if (filter === 'ended') return r.raffleEndDate && Date.now() > r.raffleEndDate;
    }

    const funcFilterRaffles = (r) => {

        if (!filterRaffles) return true

        if (r.name.toLowerCase().indexOf(filterRaffles.toLowerCase()) > -1) return true
        if (r.project?.name.toLowerCase().indexOf(filterRaffles.toLowerCase()) > -1) return true

        return false
    }

    return (<>
        <h1>Quick raffles</h1>
        <h6 className="text-muted">Create a raffle within a minute</h6>

        {showAddRaffle ? (<AddLightRaffleModal currentUser={props.currentUser} projects={user.projects} updateRaffles={fetchRaffles} setShowAddRaffle={setShowAddRaffle} />) : null}
        {showEditRaffle ? (<AddLightRaffleModal currentUser={props.currentUser} projects={user.projects} raffle={showEditRaffle} updateRaffles={fetchRaffles} setShowAddRaffle={setShowEditRaffle} />) : null}
        {showStartRaffle ? (<StartLightRaffleModal currentUser={props.currentUser} raffle={showStartRaffle} fetchRaffles={fetchRaffles} setShowPanel={setShowStartRaffle} />) : null}

        <ul className="nav nav-tabs-alt" role="tablist">
            {user && user.projects.map((project, idx) => (
                <li className="nav-item">
                    <a href={`#project${project.id}`} className={`nav-link ${idx === 0 ? 'active' : ''}`} data-bs-toggle="tab" role="tab">
                        <i className="bx bx-profile opacity-70 me-2"></i>
                        {project.name}
                    </a>
                </li>
            ))}
        </ul>

        <div className="tab-content">

            {user && user.projects.map((project, idx) => (

                <div className={`tab-pane ${idx === 0 ? 'show active' : 'fade'}`} id={`project${project.id}`} role="tabpanel">

                    <div className="d-flex align-items-end align-self-center">
                        <div className=" p-2">
                            <button className="btn btn-success btn-sm" onClick={() => { setShowAddRaffle(true) }}>
                                <span className="bx bx-tada bx-plus" />
                                Add
                            </button>
                        </div>
                        <div className="btn-group border-start p-2" role="group" aria-label="Outline button group">
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'all'} onClick={() => { setFilter('all') }}>All</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'pending'} onClick={() => { setFilter('pending') }}>Pending</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'programmed'} onClick={() => { setFilter('programmed') }}>Programmed</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'started'} onClick={() => { setFilter('started') }}>Started</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'ended'} onClick={() => { setFilter('ended') }}>Ended</button>
                        </div>
                        <div className="p-2">
                            <div className="d-flex align-items-center border rounded-3">
                                <input type="text" value={filterRaffles} style={{ paddingTop: 0, paddingBottom: 0 }} className="form-control fs-xs border-0 bg-transparent shadow-none" placeholder="Search" onChange={(e) => { setFilterRaffles(e.target.value) }} />
                                {filterRaffles !== '' ? (<div className="p-1" onClick={() => { setFilterRaffles('') }}>
                                    <div className="bx bx-x" style={{ color: 'red' }}></div>
                                </div>) : (<div className="p-1">
                                    <div className="bx bx-search"></div>
                                </div>)}
                            </div>
                        </div>
                        <div className="p-2 pt-3">
                            <div class="form-check form-switch">
                                <input type="checkbox" checked={onlyCollabs} onChange={() => { setOnlyCollabs(!onlyCollabs) }} className="form-check-input" id="customSwitch1" />
                                <label class="form-check-label" for="customSwitch1">only collabs</label>
                            </div>
                        </div>
                    </div>

                    {!arrRaffles ? (<p className="m-5 p-1 text-muted">No giveaway here. Create one now it's free !</p>) : null}

                    {arrRaffles && arrRaffles.filter(r => !onlyCollabs || (onlyCollabs && r.type === 'collab')).filter(funcFilterRaffles).filter(r => (r.type === 'light' && r.projectId === project.id) || (r.type === 'collab' && r.diffuserProjectId === project.id)).filter(funcFilter).map(raffle => (<>
                        <article className="card border-0 bg-transparent m-2 p-1">
                            <div className="row g-0">
                                <div className="col-sm-4 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3" style={{ backgroundImage: `url(${raffle.image})`, minHeight: '15rem' }}>
                                    <a href="#" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more"></a>
                                </div>
                                <div className="col-sm-8">
                                    <div className="card-body px-0 pt-sm-0 ps-sm-4 pb-0 pb-sm-4">

                                        {!raffle.started && !raffle.raffleStartDate ? (<span className="m-1 badge bg-warning shadow-warning fs-sm mb-3">pending</span>) : null}
                                        {!raffle.started && raffle.raffleStartDate ? (<span className="m-1 badge bg-warning shadow-warning fs-sm mb-3">programmed</span>) : null}
                                        {raffle.started && raffle.raffleEndDate && Date.now() < raffle.raffleEndDate ? (<span className="m-1 badge bg-success shadow-success fs-sm mb-3">started</span>) : null}
                                        {raffle.started && raffle.raffleEndDate && Date.now() > raffle.raffleEndDate ? (<span className="m-1 badge bg-danger shadow-danger fs-sm mb-3">ended</span>) : null}

                                        {raffle.type === 'collab' && raffle.project ? (<span className="m-1 badge bg-primary shadow-primary fs-sm mb-3">collab @{raffle.project.ownerTwitter}</span>) : null}

                                        <h3 className="h4">
                                            {raffle.name}
                                        </h3>
                                        {/* <p className="mb-4">DESC</p> */}
                                        {raffle.winnersStr ? (<p className="card-text">
                                            Winner(s) (total list downloadable):
                                            <ul>
                                                {raffle.winnersStr === '' ? <li>No winner</li> : raffle.winnersStr.split(',').slice(0, 3).map(w => <li><a href={`https://twitter.com/${w}`} rel="noreferrer"><span className="bx bxl-twitter" /> {w}</a></li>)}
                                            </ul>
                                            {raffle.winnersStr ? (<button className="btn btn-success btn-sm" disabled={!raffle.winnersStr} onClick={() => { handleDownloadWinners(raffle) }}><i className="bx bx-cloud-download"></i> Download winners</button>) : null}
                                        </p>) : null}

                                        <div className="mb-4">
                                            <label for="text-input" className="form-label">
                                                Share this link to collect participations once started
                                            </label>
                                            <input tupe="url" disabled={true} style={{ cursor: 'text' }} className="form-control" value={`https://ufxyz.com/c/${raffle.type === 'collab' ? 'c' : 'l'}${raffle.id}-${slugify(raffle.name)}`} />
                                        </div>
                                        {raffle.raffleStartDate ? (<div className="mb-4">
                                            <i className="bx bx-timer fs-lg me-1"></i>
                                            <span className="fs-sm">Start: {(new Date(parseInt(raffle.raffleStartDate))).toLocaleDateString()} {(new Date(parseInt(raffle.raffleStartDate))).toLocaleTimeString()}</span>
                                        </div>) : null}
                                        {raffle.raffleEndDate ? (<div className="mb-4">
                                            <i className="bx bx-time fs-lg me-1"></i>
                                            <span className="fs-sm">End: {(new Date(parseInt(raffle.raffleEndDate))).toLocaleDateString()} {(new Date(parseInt(raffle.raffleEndDate))).toLocaleTimeString()}</span>
                                        </div>) : null}
                                        <div className="d-flex align-items-center text-muted">
                                            <div className="fs-sm border-end pe-3 me-3">
                                                <i className="bx bx-bookmark-plus fs-lg me-1"></i>
                                                {(new Date(raffle.createdAt)).toLocaleDateString()}
                                            </div>
                                            <div className="d-flex align-items-center me-3">
                                                {raffle.started ?
                                                    (<>
                                                        <Link to={`/raffle/${raffle.type === 'collab' ? 'c' : 'l'}/${raffle.id}`} params={{ raffleId: `l${raffle.id}` }} className="btn btn-outline-primary btn-sm">
                                                            View more
                                                        </Link>
                                                    </>
                                                    ) : (<>
                                                        <button className="m-1 btn btn-outline-success" onClick={() => { setShowStartRaffle(raffle) }} alt="Start">
                                                            <i className="bx bx-play"></i>
                                                        </button>
                                                        <button className="m-1 btn btn-outline-primary" onClick={() => { setShowEditRaffle(raffle) }}>
                                                            <i className="bx bx-pencil"></i>
                                                        </button>
                                                        <button className="m-1 btn btn-outline-danger" onClick={() => { deleteLightRaffle(raffle) }}>
                                                            <span className="bx bx-trash" />
                                                        </button>
                                                    </>)}

                                                {raffle.started && raffle.raffleEndDate > now ?
                                                    (<>
                                                        <button className="m-1 btn btn-outline-primary" onClick={() => { setShowEditRaffle(raffle) }}>
                                                            <i className="bx bx-pencil"></i>
                                                        </button>
                                                    </>) : null}

                                                {raffle.type !== 'collab' ? (
                                                    <button className="m-1 btn btn-outline-info" onClick={() => { handleDuplicate(raffle) }}>
                                                        <i className="bx bx-copy"></i>
                                                    </button>) : null}

                                            </div>
                                            <div className="d-flex align-items-center me-3">
                                                <i className="bx bx-gift fs-lg me-1"></i>
                                                <span className="fs-sm">{raffle.nbSeats}</span>
                                            </div>
                                            {/* <div className="d-flex align-items-center">
                                                <i className="bx bx-share-alt fs-lg me-1"></i>
                                                <span className="fs-sm">9</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </>
                    ))}

                </div>))}
        </div>
    </>);
}

export default LightRafflesPage;
