
// import './ConnectPanel.css';

import { useState, useEffect } from "react";
import { useParams, useSearchParams } from 'react-router-dom';
// import './DiffuserPage.css'

import axiosObj from 'axios'

import { getBaseURL } from './utils/constants'
const baseURL = getBaseURL()

const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});


function GiveawaysPanel(props) {

    let { diffuserId, participationConditionsChecked } = useParams();
    let [raffleInfos, setRaffleInfos] = useState(false)
    const [connectedUser, setConnectedUser] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {

        if (!connectedUser) {
            (async () => {
                const response = await axios.get(`${baseURL}/auth/isLogged`)
                if (response.data.logged) {
                    setConnectedUser(response.data.logged)
                } else {
                    // Not logged ...
                }
            })()
        }
    })


    useEffect(() => {
        async function getData() {
            // Get user :
            // Get token info :
            // Get Collection info :
            try {
                if (!raffleInfos) {

                    const response = await axios.get(`${baseURL}/users/${diffuserId}/raffles`)
                    console.log("Response :", response)
                    setRaffleInfos(response.data)
                }
            } catch (err) {
                //
            }
        }

        getData()
    }, [diffuserId, raffleInfos])

    useEffect(() => {

        if (raffleInfos && raffleInfos.raffles.length > 0) {

            const script1 = document.createElement('script');
            script1.src = `${baseURL}/static/module.js`;
            document.body.appendChild(script1);

            for (let i = 0; i < raffleInfos.raffles.length; i++) {

                const script2 = document.createElement('script');
                script2.innerHTML = `
                const launch${i} = () => {             
                    if (typeof utilityFactory !== 'undefined')
                        utilityFactory('utility_element_${i}', '${diffuserId}', ${i});
                    else
                        window.setTimeout(
                            launch${i}, 200
                        );
                }
                window.setTimeout(
                    launch${i}, 200
                );`
                script2.defer = true
                document.body.appendChild(script2);
            }
        }
    }, [diffuserId, raffleInfos]);

    console.log("Raffle info :", raffleInfos)

    const raffleObject = (raffleInfos) ? raffleInfos : { raffles: [] }


    const nowTime = (new Date()).getTime()

    return (
        <div>
            <header className="py-5 bg-image-full" style={{ backgroundImage: "url('https://utilityfactory.xyz/assets/img/diffuser_header_picture.jpeg')" }}>
                <div className="text-center my-5">
                    <img className="img-fluid rounded-circle mb-4" src={raffleInfos?.userInfos?.image.replace('_400x400', '_normal')} alt={'Twitter profile picture of ' + raffleInfos?.userInfos?.name} />
                    <h1 className="text-white fs-3 fw-bolder">{raffleInfos?.userInfos?.name}</h1>
                    <p className="text-white-50 mb-0">
                        <a href={"https://twitter.com/" + diffuserId} rel="noreferrer" target="_blank"><i style={{ color: '#1DA1F2', fontSize: '20px' }} className="fab fa-twitter"></i></a>
                        <span style={{ verticalAlign: 'middle', fontSize: '16px', margin: '5px' }}>{raffleInfos?.userInfos?.followers} followers</span>
                        {raffleInfos?.userInfos?.website ? (<span style={{ verticalAlign: 'middle', fontSize: '16px', margin: '5px' }}>- Web: <a href={raffleInfos?.userInfos?.website} rel="noreferrer" target="_blank">{raffleInfos?.userInfos?.website}</a></span>) : null}
                    </p>
                </div>
            </header>

            <section className="py-5">
                <div className="container px-4 px-lg-5 my-5">

                    {(connectedUser && connectedUser.twitter === diffuserId) ? (
                        <section id="diffuser-panel" className="py-4 py-xl-5">
                            <div className="container">
                                <div className="text-white bg-dark border rounded border-0 p-4 p-md-5">
                                    <h2 className="fw-bold text-white mb-3">This is your dedicated page</h2>
                                    <p className="mb-4">
                                        You should share this URL to your community so that they participate to your raffles.
                                    </p>
                                </div>
                            </div>
                        </section>) : null}

                    {raffleObject.raffles.length === 0 ? (
                        <section id="diffuser-panel" className="py-4 py-xl-5">
                            <div className="container">
                                <div className="text-white bg-dark border rounded border-0 p-4 p-md-5">
                                    <h2 className="fw-bold text-white mb-3">No raffle for now</h2>
                                    <p className="mb-4">
                                        Come back anytime soon to participate to our collabs ;)
                                    </p>
                                </div>
                            </div>
                        </section>
                    ) : null}

                    {searchParams.get('participationConditionsChecked') === 'true' ? (
                        <section id="diffuser-panel-paritipation-checked" className="py-4 py-xl-5">
                            <div className="container">
                                <div className="text-white bg-success border rounded border-0 p-4 p-md-5">
                                    <h2 className="fw-bold text-white mb-3">Participation conditions checked ;)</h2>
                                    <p className="mb-4">
                                        Congrats, some of the conditions are checked, good luck
                                    </p>
                                </div>
                            </div>
                        </section>
                    ) : null}

                    {searchParams.get('participationConditionsChecked') === 'false' ? (
                        <section id="diffuser-panel-paritipation-not-checked" className="py-4 py-xl-5">
                            <div className="container">
                                <div className="text-white bg-danger border rounded border-0 p-4 p-md-5">
                                    <h2 className="fw-bold text-white mb-3">Participation conditions not checked :'(</h2>
                                    <p className="mb-4">
                                        Some of the conditions to participate are not checked yet.
                                    </p>
                                </div>
                            </div>
                        </section>
                    ) : null}

                    {raffleObject.raffles.map((raffle, idx) => {

                        const raffleEndTime = new Date(parseInt(raffle.raffleEndDate)).getTime()

                        return (
                            <>
                                <div className="row gx-4 gx-lg-5 align-items-center">
                                    <div className="col-md-6">
                                        <div id={"utility_element_" + idx}></div>
                                    </div>
                                    {raffle.type === "giveaway" ? (
                                        <div className="col-md-6">
                                            {typeof raffle?.raffleOpen !== 'undefined' ? (<div>
                                                <h3 className="display-5 fw-bolder">
                                                    {raffle?.name}
                                                    {raffle?.raffleOpen && raffle?.raffleOpen ?
                                                        (<span style={{ fontSize: '28px' }}>(#{raffle?.tokenId})</span>)
                                                        : (<span style={{ fontSize: '28px', color: 'red' }}>CLOSED</span>)}
                                                </h3>
                                                {raffle?.raffleOpen && raffle?.raffleOpen ? (<p>{raffle?.nbParticipations} participants for now</p>) : null}
                                                <p className="lead">
                                                    Take a chance to win a token of {raffle?.collectionData?.name}.
                                                </p>
                                                <p className="lead">
                                                    {raffle?.collectionData?.description}
                                                </p>
                                            </div>) : (<p>No raffle for now</p>)}
                                        </div>) : (
                                        <div className="col-md-6">
                                            {raffleEndTime ? (<div>
                                                <h3 className="display-5 fw-bolder">
                                                    {raffle?.projectName}
                                                    {raffleEndTime < nowTime ? (<span style={{ fontSize: '28px', color: 'red' }}>CLOSED</span>) : null}
                                                </h3>
                                                <p>{raffle?.nbParticipations} participants for now</p>
                                                <p className="lead">
                                                    Take a chance to win {raffle.nbSeats} WL spots for {raffle?.projectName}.
                                                </p>
                                                <p className="lead">
                                                    { raffle?.description }
                                                </p>
                                            </div>) : (<p>No raffle for now</p>)}
                                        </div>
                                    )}
                                </div>
                                <hr />
                            </>
                        )
                    })}
                </div>
            </section>
        </div>)
}

export default GiveawaysPanel;
