
import { useState, useEffect } from 'react'
import axiosObj from 'axios'
import { getBaseURL } from '../../utils/constants'

const baseURL = getBaseURL()
const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

function extractTwitterTweetURL(str) {
    // Example : "https://twitter.com/Tonton_Nft/status/1626914358310699010?s=fkjgh"
    const extract = str.match(/twitter.com\/.+\/status\/([0-9]+)[^0-9]?.*/i)
    if (extract && extract.length > 1) {
        return extract[1]
    }
    return str
}
function cleanTwitterAccount(str) {
    let cleanedTwitter = str.replace('@', '')
    if (str.match(/twitter\.com/) !== null) {
        const arrMatch = [...cleanedTwitter.matchAll(/twitter.com\/([A-Za-z0-9_]+)\/?/gi)]
        console.dir(arrMatch)
        if (arrMatch.length === 1) {
            if (arrMatch[0].length >= 1) {
                cleanedTwitter = arrMatch[0][1]
                str = cleanedTwitter
                console.log("Cleaned 2 :", cleanedTwitter)
            }
        }
    }
    return cleanedTwitter
}


export default function ParticipationConditionsElement(props) {

    // console.log("Conditions element :", props)

    // const [selectedCondition, props.setSelectedCondition] = useState([{ id: 'TwitterFollow', values: [props.currentUser.twitter] }])
    const [arrParticipationConditions, setParticipationConditions] = useState(undefined)
    let userDiscordInfos = {}
    console.log("Props :", props)
    if (props.currentUser && props.currentUser.discordServerInfos) {
        userDiscordInfos = JSON.parse(props.currentUser.discordServerInfos)
    }



    const setConditionParamValue = (conditionId, paramIdx, newValue, listShift) => {
        const conditions = [...props.selectedCondition]
        const participationCondition = arrParticipationConditions.find(p => p.id === conditionId)
        console.log("Participation condition ", conditionId, paramIdx, newValue, participationCondition, participationCondition.params)
        const parameter = participationCondition.params[paramIdx]
        for (let i = 0; i < conditions.length; i++) {
            if (conditions[i].id === conditionId) {
                // console.log("Condition :", conditions[i], listShift)
                if (parameter.list) {

                    if (conditionId === "TwitterFollow") {
                        console.log("Twitter follow :", newValue)
                        newValue = cleanTwitterAccount(newValue)
                        console.log("Twitter follow après regexp :", newValue)
                    }
                    

                    let arrVal = conditions[i].values[paramIdx] ? conditions[i].values[paramIdx].split(';').filter(v => v) : ['']
                    arrVal[listShift] = newValue
                    conditions[i].values[paramIdx] = arrVal.filter(v => v).map(v => v.replace(/(https:\/\/)?discord.(gg|com)\/invite\//gi, '').replace(/(https:\/\/)?discord.(gg|com)\//gi, '').replace(/[;]/gi, '')).join(';')
                    // console.log("Set value of list :", conditionId, arrVal, "=>", conditions[i].values[paramIdx])
                } else {
                    if (conditionId === "TweetCondition") {
                        console.log("Tweet condition :", newValue)
                        newValue = extractTwitterTweetURL(newValue)
                        console.log("Tweet condition après regexp :", newValue)
                    }
                    conditions[i].values[paramIdx] = newValue.replace(/(https:\/\/)?discord.(gg|com)\/invite\//gi, '').replace(/(https:\/\/)?discord.(gg|com)\//gi, '').replace(/[;]/gi, '')
                }
            }
        }

        console.log("Set seelcted condition :", conditions)
        props.setSelectedCondition(conditions)
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
            window.setTimeout(() => {
                const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
                const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
            }, 1000)`
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);



    const isDisabledOption = (condition) => {

        let disabled = false

        // if (condition.id === 'DiscordMember') {
        //     console.log("Discord member :", props.project, userDiscordInfos)
        // }
        if (condition.id === 'DiscordMember' && (!props.project || !userDiscordInfos[props.project.discordServerId])) {
            disabled = true
        }

        if (condition.category === 'Wallet' && (!props.project || !props.project.blockchain)) {
            disabled = true
        }

        return disabled
    }

    // console.log("Condition :", props.project)

    const renderConditionParam = (conditionId, param, idx, listShift = 0) => {

        const selected = props.selectedCondition.find(c => c.id === conditionId)

        // console.log("renderConditionParam -> ", conditionId, param, idx, "val:", selected.values[idx])

        let paramValue = selected.values[idx]

        if (param.hidden) {
            return null
        }

        if (param.list) {
            let paramValues = paramValue ? paramValue.split(';').filter(v => v) : ''
            paramValue = paramValues[listShift]
            // console.log("List values :", paramValues, ", current :", paramValue)
        }

        if (param.id === 'discordServerName') {
            console.log("Set discord name :", props.project.discordServerName)
            paramValue = props.project.discordServerName
        }

        let options = []
        if (param.type === 'select') {
            if (param.options === 'discordServerRoles' && props.currentUser.discordServerInfos && props.project.discordServerId) {

                console.log("Roles :", userDiscordInfos[props.project.discordServerId], userDiscordInfos[props.project.discordServerId]?.roles)

                if (userDiscordInfos[props.project.discordServerId] && userDiscordInfos[props.project.discordServerId].roles) {
                    options = userDiscordInfos[props.project.discordServerId].roles.map(r => { return { value: r.name + '/' + r.id, label: r.name } })
                } else {
                    param.disabled = true
                }
            } else if (param.values && Array.isArray(param.values)) {
                options = param.values.map(v => { return { value: v.value, label: v.label } })
            } else {
                param.disabled = true
            }
        }
        let placeholder = param.placeholder ? param.placeholder : ''
        let addElement = null

        if (param.list) {

            // console.log("List param :", idx, selected.values[idx])

            if (selected.values[idx]) {

                // let values = selected.values[idx].split(';').filter(v => v)
                // console.log("Values :", values)

                if (paramValue && listShift < 4) {
                    // console.log("Add element :", paramValue, listShift + 1)
                    addElement = renderConditionParam(conditionId, { ...param, optional: true }, idx, listShift + 1)
                }
            }
        }

        switch (param.type) {
            case 'checkbox':
                return (<>
                    <div class="form-check form-switch">
                        <input disabled={param.disabled} className="form-check-input" checked={paramValue === 'true'} onChange={(e) => { setConditionParamValue(conditionId, idx, e.target.checked ? 'true' : '', listShift) }} key={param.id} type="checkbox" />
                        <label className="form-check-label" for="flexCheckDefault">
                            {param.label}
                        </label>
                        {param.help ? (<p className="fs-sm text-muted">{param.help}</p>) : null}
                    </div>
                    {addElement}
                </>)
            case 'text':
                return (<>
                    <div className="col-sm-12 mb-6">
                        <label for="fn" className="form-label fs-base">{param.label}</label>
                        <div className="input-group">
                            {param.prefix ? (<span className="input-group-text">{param.prefix}</span>) : null}
                            <input disabled={param.disabled} type="text" className="form-control form-control-lg" placeholder={placeholder} value={paramValue} onChange={(e) => { setConditionParamValue(conditionId, idx, e.target.value, listShift) }} />
                            {param.optional || !param.required ? (<span className="input-group-text">optional</span>) : null}
                        </div>
                        {param.help ? (<p className="fs-sm text-muted">{param.help}</p>) : null}
                    </div>
                    {addElement}
                </>)
            case 'number':
                return (<>
                    <div className="col-sm-12 mb-6">
                        <label for="fn" className="form-label fs-base">{param.label}</label>
                        <div className="input-group">
                            <input disabled={param.disabled} min={param.min ? param.min : 0} type="number" className="form-control form-control-lg" value={paramValue} onChange={(e) => { setConditionParamValue(conditionId, idx, e.target.value, listShift) }} />
                            {param.optional || !param.required ? (<span className="input-group-text">optional</span>) : null}
                        </div>
                        {param.help ? (<p className="fs-sm text-muted">{param.help}</p>) : null}
                    </div>
                    {addElement}
                </>)
            case 'date':
                return (<>
                    <div className="col-sm-12 mb-6">
                        <label for="fn" className="form-label fs-base">{param.label}</label>
                        <div className="input-group">
                            <input disabled={param.disabled} min={param.min ? param.min : 0} type="datetime-local" className="form-control form-control-lg" value={paramValue} onChange={(e) => { setConditionParamValue(conditionId, idx, e.target.value, listShift) }} />
                            {param.optional || !param.required ? (<span className="input-group-text">optional</span>) : null}
                        </div>
                    </div>
                    {addElement}
                </>)
            case 'twitterUsername':
                return (<>
                    <div className="col-sm-12 mb-6">
                        <label for="fn" className="form-label fs-base">{param.label}</label>
                        <div className="input-group">
                            <span className="input-group-text">@</span>
                            <input disabled={param.disabled} type="text" placeholder="Twitter username" className="form-control form-control-lg" value={paramValue ? paramValue : ''} onChange={(e) => { setConditionParamValue(conditionId, idx, e.target.value, listShift) }} />
                            {param.optional ? (<span className="input-group-text">optional</span>) : null}
                        </div>
                    </div>
                    {addElement}
                </>)

            case 'select':
                return (<>
                    <div className="col-sm-12 mb-6">
                        <label for="fn" className="form-label fs-base">{param.label}</label>
                        <div className="input-group">
                            <select className="form-select" disabled={param.disabled} onChange={(e) => { setConditionParamValue(conditionId, idx, e.target.value, listShift) }}>
                                <option selected={!paramValue} disabled={true}>{param.disabledValue ? param.disabledValue : 'Select an option'}</option>
                                {options.map(o => (<option value={o.value} selected={o.value === paramValue}>{o.label}</option>))}
                            </select>
                            {param.optional ? (<span className="input-group-text">optional</span>) : null}
                        </div>
                    </div>
                    {addElement}
                </>)


            default: return null
        }
    }

    const handleSelectCondition = async (cond) => {

        // console.log("Condition added :", cond)

        if (props.selectedCondition.find(s => s.id === cond.id)) {
            props.setSelectedCondition(props.selectedCondition.filter(c => c.id !== cond.id))
        } else {
            let arr = [...props.selectedCondition]
            // console.log("Handle select :", cond.params.map(p => { return p.id === 'discordServerName' ? props.project.discordServerName : (p.default ? p.default : '') }))
            arr.push({ id: cond.id, values: cond.params.map(p => { return p.id === 'discordServerName' ? props.project.discordServerName + '/' + props.project.discordServerId : (typeof p.default !== 'undefined' ? p.default : '') }) })
            props.setSelectedCondition(arr)
        }

        // console.log("Participation conditions selected :", props.selectedCondition)
    }

    const fetchParticipationConditions = async () => {
        try {
            const response = await axios.get(`${baseURL}/users/participationconditions`)

            console.log("Response participation conditions :", response)
            setParticipationConditions(response.data)
        } catch (err) {
            //
        }
    }

    useEffect(() => {
        if (typeof arrParticipationConditions === 'undefined')
            fetchParticipationConditions()

    }, [arrParticipationConditions])

    let arrCategories = arrParticipationConditions ? arrParticipationConditions.map(c => { return { category: c.category, icon: c.icon, iconColor: c.iconColor } }) : []

    arrCategories = arrCategories.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.category === value.category
        ))
    )

    return (<div className="mb-3">
        <label className="form-label" >Participation conditions</label><br />
        <small className="text-muted">Selected conditions will be checked to select the winner(s)</small><br />
        {arrCategories.map(category => (<>
            <h6 className="mt-3 px-1">{category.icon ? (<span className={category.icon} style={{ color: (category.iconColor) ? category.iconColor : 'blue' }}></span>) : null} {category.category}</h6>
            <div className="list-group">
                {arrParticipationConditions?.filter(c => (c.diffuserOnly && props.diffuser) || (!c.diffuserOnly)).filter(c => c.category === category.category).map(p => (<>
                    <div className="list-group-item">
                        <div className="form-check">
                            {/* <input className="form-check-input" onClick={() => { handleSelectCondition(p) }} checked={props.selectedCondition.find(c => c.id === p.id)} disabled={isDisabledOption(p)} key={p.id} type="checkbox" /> */}
                            <div class="form-check form-switch">
                                <input type="checkbox" onClick={() => { handleSelectCondition(p) }} className="form-check-input" id="customSwitch1" checked={props.selectedCondition.find(c => c.id === p.id)} disabled={isDisabledOption(p)} key={p.id} />
                                <label
                                    style={{ cursor: 'help', textDecoration: 'underline', textDecorationStyle: 'dotted' }}
                                    className="form-check-label"
                                    data-bs-container="body"
                                    data-bs-toggle="popover"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-bs-title={p.name}
                                    data-bs-content={p.description}
                                    for="flexCheckDefault">
                                    {p.name}
                                </label>
                            </div>
                            {props.selectedCondition.find(c => c.id === p.id) && p.params.length > 0 ? (<form className="pb-3 pb-lg-4" noValidate>
                                {p.params.map((param, idx) => (<div className="row pb-2">
                                    {renderConditionParam(p.id, param, idx)}
                                </div>))}
                            </form>) : null}

                        </div>
                    </div>
                </>))}
            </div>
        </>))}
    </div>
    )
}