
// import './TokensPanel.css';

import { useState, useEffect } from "react";
import ReactEcharts from 'echarts-for-react'

import {
    RouterProvider,
    Link,
    Routes,
    NavLink,
    Route
} from "react-router-dom";

import RaffleSummary from "./sub/RaffleSummary"

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


function DashboardPage(props) {

    const [arrInvites, setArrInvites] = useState(null)
    const [user, setUser] = useState(null)
    const [projectStats, setProjectStats] = useState(null)
    const [lastRaffles, setLastRaffles] = useState(null)

    useEffect(() => {
        async function getUser() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}`
            })
            let user = requestObj.data

            setUser(user)
        }
        console.log("Effect : user = ", user)
        if (!user && props.currentUser.twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, props.currentUser.twitter])

    // const getInvites = async () => {
    //     const resultObj = await axios({
    //         method: 'GET',
    //         url: `${baseURL}/users/${props.currentUser.twitter}/invites`
    //     })
    //     const arrInvites = resultObj.data

    //     setArrInvites(arrInvites)
    // }

    // useEffect(() => {
    //     if (arrInvites === null && props.curentUser) {
    //         getInvites()
    //     }
    // }, [arrInvites, props])

    useEffect(() => {
        async function getProjectStats() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}/projects_stats`
            })
            let projectStats = requestObj.data

            setProjectStats(projectStats)

            // Set last raffles :
            const arrLastRaffles = []
            for (const project of Object.values(projectStats)) {
                for (const raffleStats of project.raffles) {
                    // Adds only running raffles :
                    if (raffleStats.raffleEndDate && Date.now() < (parseInt(raffleStats.raffleEndDate) + (96 * 3600 * 1000))) {
                        arrLastRaffles.push(raffleStats)
                    }
                }
            }
            console.log("Last raffles :", arrLastRaffles)
            setLastRaffles(arrLastRaffles)
        }
        console.log("Effect : projectStats = ", projectStats)
        if (user && !projectStats) {
            console.log("Get project stats")
            getProjectStats()
        }
    }, [projectStats, user])

    if (projectStats) {

        console.log("Data :", [
            { value: projectStats.nbLightRaffles, name: 'Light giveaways' },
            { value: projectStats.nbWLRaffles, name: 'WL giveaways' },
            { value: projectStats.nbTokenRaffles, name: 'Token giveaways' },
        ])
    }

    return (<>
        <ul className="nav nav-tabs-alt" role="tablist">
            <li className="nav-item">
                <a href="#home1" className="nav-link active" data-bs-toggle="tab" role="tab">
                    <i className="bx bx-home-alt opacity-70 me-2"></i>
                    Home
                </a>
            </li>
            {user && user.projects.map(project => (
                <li className="nav-item">
                    <a href={`#project${project.id}`} className="nav-link" data-bs-toggle="tab" role="tab">
                        <i className="bx bx-profile opacity-70 me-2"></i>
                        {project.name}
                    </a>
                </li>
            ))}
        </ul>

        <div className="tab-content">
            <div className="tab-pane fade show active" id="home1" role="tabpanel">
                {arrInvites && arrInvites.length > 0 ? (<>
                    <h2 className="h5 text-primary mb-4">A Gold member invited you !</h2>
                    <p className="mb-0">
                        This allows you to create a token or WL raffle without paying fees or requiring a pass.
                    </p>
                    <br className="mb-4" />
                </>) : null}

                {projectStats ? (<>

                    <div className="row">
                        <div className="col-md-6">
                            <b>Total winners #</b>
                            {Object.values(projectStats).map(p => p.totalWinners).reduce((prev, val) => { return prev + val })}
                            <span className="bx bx-trophy" />
                        </div>
                        <div className="col-md-6">
                            <b>Total participations #</b>
                            {Object.values(projectStats).map(p => p.totalParticipations).reduce((prev, val) => { return prev + val })}
                            <span className="bx bx-user" />
                        </div>
                    </div>
                    <hr className="m-4" />

                    <div className="row">
                        <div className="col-md-6">
                            <fieldset>
                                <legend>Global summary</legend>
                                <ReactEcharts
                                    option={{
                                        tooltip: {
                                            trigger: 'item'
                                        },
                                        // legend: {
                                        //     orient: 'vertical',
                                        //     left: 'left'
                                        // },
                                        series: [
                                            {
                                                name: 'Giveaway types',
                                                type: 'pie',
                                                radius: '50%',
                                                data: [
                                                    { value: Object.values(projectStats).map(p => p.nbLightRaffles).reduce((prev, val) => { return prev + val }), name: 'Light giveaways' },
                                                    { value: Object.values(projectStats).map(p => p.nbWLRaffles).reduce((prev, val) => { return prev + val }), name: 'WL giveaways' },
                                                    { value: Object.values(projectStats).map(p => p.nbTokenRaffles).reduce((prev, val) => { return prev + val }), name: 'Token giveaways' },
                                                ],
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            }
                                        ]
                                    }}
                                />
                            </fieldset>
                        </div>

                        <div className="col-md-6">
                            <fieldset>
                                <legend>Participation ratio</legend>
                                <ReactEcharts
                                    style={{ height: '200px' }}
                                    option={{
                                        tooltip: {
                                            trigger: 'axis',
                                            axisPointer: {
                                                type: 'shadow'
                                            }
                                        },
                                        // grid: {
                                        //     top: 80,
                                        //     bottom: 30
                                        // },
                                        xAxis: {
                                            type: 'value',
                                            position: 'top',
                                            splitLine: {
                                                lineStyle: {
                                                    type: 'dashed'
                                                }
                                            }
                                        },
                                        yAxis: {
                                            type: 'category',
                                            axisLine: { show: false },
                                            axisLabel: { show: false },
                                            axisTick: { show: false },
                                            splitLine: { show: false },
                                            data: Object.values(projectStats).map(s => s.name)
                                        },
                                        height: '50px',
                                        series: [
                                            {
                                                name: 'Participation ratio',
                                                type: 'bar',
                                                showBackground: true,
                                                label: {
                                                    show: true,
                                                    formatter: '{b}'
                                                },
                                                data: Object.values(projectStats).map(project => {
                                                    return { value: project.raffles.length ? ((project.totalParticipations / project.raffles.length / project.followers) * 100).toFixed(2) : 0, name: project.name }
                                                }),
                                                emphasis: {
                                                    itemStyle: {
                                                        shadowBlur: 10,
                                                        shadowOffsetX: 0,
                                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                    }
                                                }
                                            }
                                        ]
                                    }}
                                />
                            </fieldset>
                        </div>
                    </div>
                </>) : null}


                <fieldset>
                    <legend>Last raffles</legend>
                    {lastRaffles && lastRaffles.length > 0 ? (<div>

                        {lastRaffles.map(raffleStats => (
                            <RaffleSummary user={props.curentUser} raffleStats={raffleStats} />
                        ))}

                    </div>) : (<div className="alert d-flex alert-primary" role="alert">
                        <i className="bx bx-bell lead me-3"></i>
                        <div>
                            No giveaway in your recent history 😴. Create a giveaway within 2 minutes, it's <b>free</b> and easy !
                            <hr className="opacity-25 mt-2" style={{ color: 'currentColor' }} />
                            <p className="pt-3 mb-2">
                                <NavLink to="/light-raffles" className={"btn btn-primary"}>
                                    Create a Giveaway 🚀
                                </NavLink>
                            </p>
                        </div>
                    </div>
                    )}
                </fieldset>

            </div>
            {user && user.projects.map(project => (
                <div className="tab-pane fade" id={`project${project.id}`} role="tabpanel">

                    {projectStats && projectStats[project.id] && projectStats[project.id].raffles.length === 0 ? (
                        <div className="alert d-flex alert-primary" role="alert">
                            <i className="bx bx-bell lead me-3"></i>
                            <div>
                                No giveaway in your recent history 😴. Create a giveaway within 2 minutes, it's <b>free</b> and easy !
                                <hr className="opacity-25 mt-2" style={{ color: 'currentColor' }} />
                                <p className="pt-3 mb-2">
                                    <NavLink to="/light-raffles" className={"btn btn-primary"}>
                                        Create a Giveaway 🚀
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    ) : null}

                    {projectStats && projectStats[project.id] && projectStats[project.id].raffles.map(raffleStats => (
                        <RaffleSummary user={props.curentUser} raffleStats={raffleStats} project={project} />
                    ))}
                </div>))}

        </div>

    </>);
}

export default DashboardPage;
