
// import './TokensPanel.css';

import { useState, useEffect } from "react";

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


function SupportPage(props) {


    return (<>
        <h1>Support</h1>

        <div className="alert alert-primary mb-0" role="alert">
            <h4 className="pt-2 alert-heading">Need some help ?</h4>
            <p>Join our discord server : <a href="https://discord.gg/cEg4m8gAXe" target="_blank" rel="noreferer">https://discord.gg/cEg4m8gAXe</a></p>
        </div>
    </>);
}

export default SupportPage;
