
import { useRef, useState, useEffect } from 'react'
import { ethers } from 'ethers';
import axiosObj from 'axios';
import { getBaseURL } from '../utils/constants'
import { Editor } from '@tinymce/tinymce-react'

import ParticipationConditionsElement from './sub/ParticipationConditionsElement'

import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
// import { Shortcuts } from 'tinymce';

// import 'tinymce/tinymce.min.js'

const baseURL = getBaseURL()
const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

function isValidHttpUrl(str) {
    let url;
    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

const toStrDate = (d) => {
    const date = new Date(parseInt(d))

    let day = date.getDate()
    if (day < 10) day = '0' + day

    let month = (date.getMonth() + 1)
    if (month < 10) month = '0' + month

    let hours = (date.getHours())
    if (hours < 10) hours = '0' + hours

    let minutes = (date.getMinutes())
    if (minutes < 10) minutes = '0' + minutes

    // console.log("Return :", date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + hours + ':' + minutes)
    return date.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes
}

export default function AddLightRaffleModal(props) {

    const now = Date.now()

    let setShowPanel = props.setShowPanel

    const editorRef = useRef(null);
    // const log = () => {
    //     if (editorRef.current) {
    //         console.log(editorRef.current.getContent());
    //     }
    // };


    const defaultRaffle = {
        blockchain: null,
        name: '',
        description: '<p>What can we win ? Why this raffle ?</p>',
        image: props.currentUser.image,
        bannerImage: '',
        website: '',
        twitter: props.currentUser.twitter,
        discord: '',
        mandatoryConditions: '',
        optionalConditions: '',
        minParticipants: 1,
        nbSeats: 1,
        projectId: props.projects[0].id,
        started: false,
    }
    const [raffleToAdd, setRaffleToAdd] = useState(props.raffle ? props.raffle : defaultRaffle)
    const [user, setUser] = useState(null)

    const [minEndDate, setMinEndDate] = useState(new Date())
    const [maxEndDate, setMaxEndDate] = useState(raffleToAdd.raffleStartDate ? new Date(raffleToAdd.raffleStartDate + (7 * 24 * 3600 * 1000) - (2 * 3600 * 1000)) : new Date(now + Math.floor((6.9 * 24 * 3600 * 1000))))

    const updateEndDates = () => {
        console.log("Start date :", raffleToAdd.raffleStartDate)
        if (raffleToAdd.raffleStartDate) {
            console.log("Update end dates :", typeof raffleToAdd.raffleStartDate)
            setMinEndDate(parseInt(raffleToAdd.raffleStartDate) + (30 * 60 * 1000))
            setMaxEndDate(parseInt(raffleToAdd.raffleStartDate) + (7 * 24 * 3600 * 1000) - (2 * 3600 * 1000))
        }
    }

    useEffect(() => {
        async function getUser() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}`
            })
            let user = requestObj.data

            setUser(user)
        }
        console.log("Effect : user = ", user)
        if (!user && props.currentUser.twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, props.currentUser.twitter])


    let initConditions = []

    if (props.raffle) {

        let strInitConditions
        if (props.raffle.type === 'collab') {

            strInitConditions = props.raffle.diffuserMandatoryConditions + ',' + props.raffle.diffuserOptionalConditions

        } else {

            strInitConditions = props.raffle.mandatoryConditions + ',' + props.raffle.optionalConditions
        }



        for (let condition of strInitConditions.split(',')) {

            const splitCondition = condition.split('/')
            initConditions.push({
                id: splitCondition[0],
                values: splitCondition.splice(1)
            })
        }
    } else {
        initConditions = [{ id: 'TwitterFollow', values: [props.currentUser.twitter] }]
    }

    const [selectedCondition, setSelectedCondition] = useState(initConditions)

    let {
        blockchain,
        name,
        description,
        image,
        bannerImage,
        website,
        nbSeats,
        projectId,
        raffleStartDate,
        raffleEndDate,
        started,
    } = raffleToAdd

    // console.log("Project id :", projectId)
    // console.log("User: ", user)
    console.log("Raffle to add : ", raffleToAdd)

    const setValue = (k, v) => {
        // console.log("Set value", k, v)
        setRaffleToAdd({
            ...raffleToAdd,
            [k]: v
        })

        setTimeout(updateEndDates, 350)
    }

    const onClick = async () => {

        if (!name) {
            alert(`You must provide a name ;)`)
            return
        }

        if (!raffleToAdd.image) {
            alert(`You must provide an image URL`)
            return
        }

        if (!isValidHttpUrl(raffleToAdd.image) || (raffleToAdd.bannerImage && !isValidHttpUrl(raffleToAdd.bannerImage))) {
            alert(`You must provide valid image URLs`)
            return
        }

        if (!description) {
            alert('You must set a description for your giveaway. Add emojis, a short description of what the winner can win.')
            return
        }

        if (raffleStartDate && !raffleEndDate) {
            alert('You set a start date, but no end date. Please fill the end date or remove the start date to save this raffle.')
            return
        }

        try {

            let response = null

            if (props.raffle) {
                // Edit :

                console.log("Edit :", selectedCondition)

                if (props.raffle?.type === 'collab') {

                    response = await axios({
                        method: 'PATCH',
                        url: `${baseURL}/collabs/${props.raffle.id}`,
                        data: {
                            ...raffleToAdd,
                            selectedCondition: selectedCondition.map(c => !c.values || c.values.length === 0 ? c.id : c.id + "/" + c.values.map(v => encodeURIComponent(v)).join('/'))
                        }
                    })

                } else {

                    response = await axios({
                        method: 'PATCH',
                        url: `${baseURL}/lightraffles/${props.raffle.id}`,
                        data: {
                            ...raffleToAdd,
                            selectedCondition: selectedCondition.map(c => !c.values || c.values.length === 0 ? c.id : c.id + "/" + c.values.map(v => encodeURIComponent(v)).join('/'))
                        }
                    })
                }


            } else {
                // Add :

                response = await axios({
                    method: 'POST',
                    url: `${baseURL}/lightraffles/`,
                    data: {
                        ...raffleToAdd,
                        selectedCondition: selectedCondition.map(c => !c.values || c.values.length === 0 ? c.id : c.id + "/" + c.values.map(v => encodeURIComponent(v)).join('/'))
                    }
                })
            }

            if (response.data.error) {

                alert('An error occured, sorry for that 😢')

            } else {

                props.setShowAddRaffle(false)
                props.updateRaffles()
            }

        } catch (err) {
            console.log("Error add light raffle :", err)
            alert('An error occured, sorry for that 😢')
        }
    }

    return (<div className="modal show" role="dialog" style={{ display: 'block' }} id="profile">
        <div className="modal-dialog" role="document" style={{ maxWidth: '900px' }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add a giveaway</h4>
                    <button type="button" onClick={() => { props.setShowAddRaffle(false); }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="mb-4">
                            <label className="form-label">Project name<span style={{ color: 'red' }}>*</span></label>
                            <select className="form-control" disabled={started || props.raffle?.type === 'collab'} onChange={(e) => { setValue('projectId', parseInt(e.target.value)) }}>
                                {props.projects.map(p => (<option selected={projectId === p.id} value={p.id}>{p.name}</option>))}
                            </select>
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Blockchain</label>
                            <select disabled={started || props.raffle?.type === 'collab'} className="form-control" onChange={(e) => { setValue('blockchain', e.target.value) }}>
                                <option selected={blockchain === null} value={null}>-- Not applicable --</option>
                                <option selected={blockchain === "arbitrum"} value="arbitrum">Arbitrum</option>
                                <option selected={blockchain === "aptos"} value="aptos">Aptos</option>
                                <option selected={blockchain === "avalanche"} value="avalanche">Avalanche</option>
                                <option selected={blockchain === "bitcoin"} value="bitcoin">Bitcoin</option>
                                <option selected={blockchain === "bsc"} value="bsc">BSC</option>
                                <option selected={blockchain === "elrond"} value="elrond">Elrond</option>
                                <option selected={blockchain === "ethereum"} value="ethereum">Ethereum</option>
                                <option selected={blockchain === "flow"} value="flow">Flow</option>
                                <option selected={blockchain === "polygon"} value="polygon">Polygon</option>
                                <option selected={blockchain === "solana"} value="solana">Solana</option>
                                <option selected={blockchain === "sui"} value="sui">SUI</option>
                                <option selected={blockchain === "tezos"} value="tezos">Tezos</option>
                                <option selected={blockchain === "wax"} value="wax">Wax</option>
                            </select>
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Raffle name<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" disabled={started || props.raffle?.type === 'collab'} className="form-control" value={name} onChange={(e) => { setValue('name', e.target.value) }} placeholder={`${props.currentUser.name} x partner name`} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Description<span style={{ color: 'red' }}>*</span></label>
                            <Editor
                                tinymceScriptSrc="/js/tinymce/tinymce.min.js"
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={description}
                                init={{
                                    height: 400,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media paste code'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                                onBlur={() => {
                                    // console.log("Set value desc :", editorRef.current.getContent())
                                    setValue('description', editorRef.current.getContent())
                                }}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Image URL<span style={{ color: 'red' }}>*</span></label>
                            <input type="url" className="form-control" value={image} onChange={(e) => { setValue('image', e.target.value) }} />
                            <p className="text-muted fs-sm">URL of a square banner image</p>
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Banner Image URL</label>
                            <input type="url" className="form-control" value={bannerImage} onChange={(e) => { setValue('bannerImage', e.target.value) }} />
                            <p className="text-muted fs-sm">URL of the banner image</p>
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Organizer's website</label>
                            <input type="url" className="form-control" value={website} onChange={(e) => { setValue('website', e.target.value) }} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Number of spots to win</label>
                            <input type="number" disabled={props.raffle?.type === 'collab'} min={1} max={100} className="form-control" value={nbSeats} onChange={(e) => { setValue('nbSeats', e.target.value) }} />
                            <p className="form-text">Between 1 and 100 possible winners</p>
                        </div>

                        <ParticipationConditionsElement diffuser={true} collab={props.raffle?.type === 'collab'} currentUser={user} project={user && user.projects.find(p => p.id === projectId)} selectedCondition={selectedCondition} setSelectedCondition={setSelectedCondition} />

                        <hr />
                        <h6 className="my-3">Program Raffle</h6>

                        <div className="mb-3">
                            <label className="form-label">Start date :</label>
                            <input disabled={started} className="form-control" type="datetime-local" value={toStrDate(raffleStartDate)} min={toStrDate(Date.now())} onChange={(e) => { setValue('raffleStartDate', Date.parse(e.target.value.replace('T', ' ').replace(/\-/g, '/'))) }} />
                            <p className="form-text">Optional</p>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">End date :</label>
                            <input className="form-control" type="datetime-local" value={toStrDate(raffleEndDate)} min={toStrDate(minEndDate)} max={toStrDate(maxEndDate)} onChange={(e) => { setValue('raffleEndDate', Date.parse(e.target.value.replace('T', ' ').replace(/\-/g, '/'))) }} />
                            <p className="form-text">Optional</p>
                        </div>

                    </form>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-light" type="button" onClick={() => { props.setShowAddRaffle(false) }} data-bs-dismiss="modal">Close</button>
                    <button className="btn btn-primary" type="button" onClick={() => { onClick() }}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>)
}