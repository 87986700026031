
// import MetaMaskOnboarding from '@metamask/onboarding'
import { useRef, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
    useAccount,
    useConnect,
    useDisconnect,
    useSignMessage,
} from 'wagmi'

import { verifyMessage } from 'ethers/lib/utils'

import axiosObj from 'axios';
import { getBaseURL } from '../utils/constants'
const baseURL = getBaseURL()
const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

const toStrDate = (d) => {
    const date = new Date(parseInt(d))

    let day = date.getDate()
    if (day < 10) day = '0' + day

    let month = (date.getMonth() + 1)
    if (month < 10) month = '0' + month

    let hours = (date.getHours())
    if (hours < 10) hours = '0' + hours

    let minutes = (date.getMinutes())
    if (minutes < 10) minutes = '0' + minutes

    // console.log("Return :", date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + hours + ':' + minutes)
    return date.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes
}



export default function Profile(props) {

    const onboarding = useRef()
    const [dummy, setDummy] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState(null)
    const [initUser, setInitUser] = useState(null)


    const { address, connector, isConnected } = useAccount()
    const { connect, connectors, error: errorConnect, isLoading: isLoadingConnect, pendingConnector } = useConnect()
    const { disconnect } = useDisconnect()

    const { data, error, isLoading, signMessage } = useSignMessage({
        onSuccess(data, variables) {
            // Verify signature when sign message succeeds
            //   const address = verifyMessage(variables.message, data)
            //   recoveredAddress.current = address

            console.log("[Wagmi][useSignMessage] Data:", data, ", variables:", variables)
            props.updateUser(passHolderWallet, {
                email,
                role,
                passHolderWallet,
                signature: data
            }, setShowToastSaved, setShowToastErrorSaved);
        },
    })


    let {
        twitter,
        // discordUsername,
        // discordDiscriminator,
        discordId,
        discordServerId,
        // discordServerName,
        // discordServerIcon,
        name,
        email,
        role,
        // website,
        passHolderWallet,
        // ownerWebsite,
        // raffleWebsite
    } = props.currentUser

    if (address) {
        passHolderWallet = address
    }

    const userDiscordServerInfos = props.currentUser.discordServerInfos ? JSON.parse(props.currentUser.discordServerInfos) : {}

    const arrBlockchains = [{
        chain: 'ethereum',
        name: 'Ethereum',
        wallet: 'metamask',
        networkId: 1,
    }, {
        chain: 'arbitrum',
        name: 'Arbitrum',
        wallet: 'metamask',
        networkId: 42161,
    }, {
        chain: 'polygon',
        name: 'Polygon',
        wallet: 'metamask',
        networkId: 137,
    }]

    const [showToastSaved, setShowToastSaved] = useState(false)
    const [showToastErrorSaved, setShowToastErrorSaved] = useState(false)

    const [memberToAdd, setMemberToAdd] = useState('')

    const setCurrentUser = props.setCurrentUser
    const setEmail = (v) => { console.log("Update info ...", v); setCurrentUser({ ...props.currentUser, email: v }) }
    const setRole = (v) => { console.log("Update info ...", v); setCurrentUser({ ...props.currentUser, role: v }) }
    const setPassHolderWallet = (v) => { console.log("Update info ...", v); setCurrentUser({ ...props.currentUser, passHolderWallet: v }) }

    console.log("Search params :", searchParams.get('discordServers'))
    const arrDiscordServers = (searchParams.get('discordServers')) ? JSON.parse(searchParams.get('discordServers')) : []
    console.log("Arr discord servers :", arrDiscordServers)

    const removePassHolder = () => {
        console.log("Remove wallet ")
        setPassHolderWallet(null)
    }

    const getSignatureWagmi = async (wallet, forceSignature = false) => {

        const urlNonce = `${baseURL}/users/${twitter}/nonce`

        const response = await axios({
            url: urlNonce,
            method: 'GET',
        })

        console.log("Response nonce :", response.data)

        signMessage({ message: response.data.nonce })

    }

    const unlinkDiscord = (projectId) => {
        if (!window.confirm('Warning! If any raffle is in progress, you could loose informations about roles and server that could invalidate participations.')) return

        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId).discordId = null
        tmpUser.projects.find(p => p.id === projectId).discordUsername = null
        tmpUser.projects.find(p => p.id === projectId).discordDiscriminator = null
        tmpUser.projects.find(p => p.id === projectId).discordServerId = null
        tmpUser.projects.find(p => p.id === projectId).discordServerName = null
        tmpUser.projects.find(p => p.id === projectId).discordServerIcon = null
        tmpUser.projects.find(p => p.id === projectId).discordServerInfos = null

        console.log("Tmp user blockchain :", tmpUser)
        setUser(tmpUser)
        setDummy(dummy + 1)

    }

    const setDiscordServer = (projectId, id) => {

        const selectedServer = arrDiscordServers.find(s => s.discordServerId === id)
        console.log("Set selected servr :", selectedServer)

        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId).discordId = searchParams.get('discordId')
        tmpUser.projects.find(p => p.id === projectId).discordUsername = searchParams.get('discordUsername')
        tmpUser.projects.find(p => p.id === projectId).discordDiscriminator = searchParams.get('discordDiscriminator')
        tmpUser.projects.find(p => p.id === projectId).discordServerId = selectedServer.discordServerId
        tmpUser.projects.find(p => p.id === projectId).discordServerName = selectedServer.discordServerName
        tmpUser.projects.find(p => p.id === projectId).discordServerIcon = selectedServer.discordServerIcon

        console.log("Tmp user blockchain :", tmpUser)
        setUser(tmpUser)
        setDummy(dummy + 1)

        setCurrentUser({
            ...props.currentUser,
            ...selectedServer,

        })
    }


    const setProjectBlockchain = (projectId, blockchain) => {
        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId).blockchain = blockchain
        console.log("Tmp user blockchain :", tmpUser)
        setUser(tmpUser)
        setDummy(dummy + 1)
    }

    const setProjectImage = (projectId, image) => {
        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId).image = image
        setUser(tmpUser)
        setDummy(dummy + 1)
    }

    const setProjectBanner = (projectId, banner) => {
        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId).banner = banner
        setUser(tmpUser)
        setDummy(dummy + 1)
    }

    const setProjectDiscordChannelBot = (projectId, channelBot) => {
        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId).discordBotChannel = channelBot
        console.log("Tmp user wallet :", tmpUser)
        setUser(tmpUser)
        setDummy(dummy + 1)
    }

    const setProjectDiscordRoleToTag = (projectId, roleId) => {
        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId).discordRoleToTag = roleId
        console.log("Tmp user wallet :", tmpUser)
        setUser(tmpUser)
        setDummy(dummy + 1)
    }

    const setProjectName = (projectId, name) => {
        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId).name = name
        console.log("Tmp user wallet :", tmpUser)
        setUser(tmpUser)
        setDummy(dummy + 1)
    }

    const setProjectValue = (projectId, field, name) => {
        let tmpUser = user
        tmpUser.projects.find(p => p.id === projectId)[field] = name
        console.log("Tmp user wallet :", tmpUser)
        setUser(tmpUser)
        setDummy(dummy + 1)
    }


    const onClickProjectTokensWallet = (projectId) => {
        //
        // Connect and set pass holder address :
        // setProjectTokensWallet(projectId, newAccounts[0])        
    }

    const onClickPassHolder = () => {
        //
        // Connect and set address ...
        // setPassHolderWallet(newAccounts[0])
    }

    if (showToastSaved) {
        setTimeout(() => {
            setShowToastSaved(false)
        }, 5500)
    }
    if (showToastErrorSaved) {
        setTimeout(() => {
            setShowToastErrorSaved(false)
        }, 5500)
    }


    const updateUserDetails = () => {
        // Update only user details :
        if (!email) {
            alert('Email is mandatory')
            return
        }

        if (discordId && !discordServerId) {
            alert('You must select a server associated to your discord account')
            return
        }

        // If needs sign :
        console.log("Pass holder wallet :", passHolderWallet)
        if (passHolderWallet) {

            // Get signature wagmi : this will sign and launch user update :
            getSignatureWagmi('')

        } else {
            // else : userUpdate
            props.updateUser(passHolderWallet, {
                email,
                role,
                passHolderWallet
            }, setShowToastSaved, setShowToastErrorSaved);
        }


    }

    const updateProjectDetails = async (projectId) => {
        // Update only project details :

        try {

            if (!user.projects.find(p => p.id === projectId).name) {
                return window.alert('Must set a name for the project')
            }

            const requestObj = await axios({
                method: 'POST',
                url: `${baseURL}/users/${twitter}/project`,
                data: {
                    ...user.projects.find(p => p.id === projectId),
                    twitter: user.twitter
                }
            })

            // setUser(requestObj.data.saved)
            // setInitUser(JSON.parse(JSON.stringify(requestObj.data.saved)))

            setShowToastSaved(true)
        } catch (err) {
            console.log("Err save project :", err)
            setShowToastErrorSaved(true)
        }
    }

    const handleAddMember = async (projectId) => {
        // memberToAdd 
        // POST /project/:id/member
        console.log("Add member ", memberToAdd, "to", projectId)
        try {
            const requestObj = await axios({
                method: 'POST',
                url: `${baseURL}/users/${twitter}/project/${projectId}/member/${memberToAdd}`,
            })
            getUser()
            setMemberToAdd('')
            alert(requestObj.data.message)

        } catch (err) {
            // console.log("rq obj:", err)
            alert('This username does not exist on UtilityFactory. This user sould sign in on UtilityFactory before being added to your team.')
        }
    }

    const handleRemoveMember = async (projectId, memberTwitter) => {
        // DELETE /project/:id/member
        console.log("Remove member ", memberTwitter, "from", projectId)
        const requestObj = await axios({
            method: 'DELETE',
            url: `${baseURL}/users/${twitter}/project/${projectId}/member/${memberTwitter}`,
        })
        getUser()
        alert(requestObj.data.message)
    }

    const handleCreateProject = async () => {
        // DELETE /project/:id/member
        const requestObj = await axios({
            method: 'PUT',
            url: `${baseURL}/users/${twitter}/project/`,
        })
        getUser()
    }

    const handleDeleteProject = async (id) => {
        // DELETE /project/:id/member
        if (!window.confirm('Do you really want to delete this project ?')) {
            return
        }
        const requestObj = await axios({
            method: 'DELETE',
            url: `${baseURL}/users/${twitter}/project/${id}`,
        })
        getUser()
    }

    async function getUser() {
        const requestObj = await axios({
            method: 'GET',
            url: `${baseURL}/users/${twitter}`
        })
        let user = requestObj.data
        if (user.projects.length === 0) {
            user.projects.push({})
        }

        for (let p of user.projects) {
            if (p.discordServerInfos) {
                p.discordServerInfos = JSON.parse(p.discordServerInfos)
            }
        }
        setUser(user)
        setInitUser(JSON.parse(JSON.stringify(user)))
    }

    useEffect(() => {
        console.log("Effect : user = ", user)
        if (!user && twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, twitter])

    // if (userDiscordServerInfos) {

    //     console.log("Discord :", userDiscordServerInfos);
    //     console.log("Project", user.projects[0])
    //     console.log("Server info:", userDiscordServerInfos[user.projects[0].discordServerId])
    // }

    return (
        <>
            <h2 className="h2 pt-xl-1 pb-3">Account Details</h2>

            {props.currentUser && !props.currentUser.email ? (
                <div className="alert d-flex alert-warning" role="alert">
                    <i className="bx bx-error lead me-3"></i>
                    <div>
                        The Email field is mandatory. This allows use to send you informations about giveaways.
                    </div>
                </div>
            ) : null}

            <h5 className="h5 text-primary mb-4">Basic info</h5>
            <form className="border-bottom pb-3 pb-lg-4" noValidate>
                <div className="row pb-2">
                    <div className="col-sm-6 mb-4">
                        <label for="fn" className="form-label fs-base">Twitter account</label>
                        <input type="text" id="fn" disabled={true} className="form-control form-control-lg" value={twitter} required />
                    </div>
                    <div className="col-sm-6 mb-4">
                        <label for="sn" className="form-label fs-base">Twitter name</label>
                        <input type="text" id="sn" disabled={true} className="form-control form-control-lg" value={name} required />
                    </div>

                    <div className="col-sm-12 mb-4">
                        <label for="email" className="form-label fs-base">Email address<span style={{ color: 'red' }}>*</span></label>
                        <input type="email" id="email" className="form-control form-control-lg" value={email} required onChange={(e) => { setEmail(e.target.value) }} />
                        <div className="invalid-feedback">Please provide a valid email address!</div>
                    </div>

                    <div className="col-sm-12 mb-4">
                        <label for="email" className="form-label fs-base">Role</label>
                        <select className="form-select" id="select-role" onChange={(e) => { setRole(e.target.value) }}>
                            <option disabled={true} selected={!role}>-- select a role --</option>
                            {['Founder', 'Collab Manager', 'Tech', 'Marketing'].map(r => (<option value={r.toLowerCase()} selected={r.toLowerCase() === role}>{r}</option>))}
                        </select>
                    </div>



                    <div className="col-sm-12 mb-4">
                        <label for="email" className="form-label fs-base">Personal Ethereum Wallet</label>
                        <p className="text-muted fs-sm">Ethereum wallet holding access pass - Required only if you want to access premium features</p>
                        {/* <input type="text" id="passHolderWallet" className="form-control form-control-lg" value={props.currentUser.passHolderWallet} required onChange={(e) => { setPassHolderWallet(e.target.value) }} /> */}
                        {isConnected ? (<input type="text" id="passHolderWallet" className="form-control form-control-lg" value={address} disabled={true} />) :
                            (<div className="btn-group">
                                {
                                    connectors.map((connector) => (
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            disabled={!connector.ready}
                                            key={connector.id}
                                            onClick={() => connect({ connector })}
                                        >
                                            {connector.name}
                                            {!connector.ready && ' (unsupported)'}
                                            {isLoadingConnect &&
                                                connector.id === pendingConnector?.id &&
                                                ' (connecting)'}
                                        </button>
                                    ))}
                            </div>)
                        }
                        {errorConnect && <div>{errorConnect.message}</div>}
                    </div>
                </div>
                <div className="d-flex mb-3" style={{ marginTop: '20px' }}>
                    <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); updateUserDetails() }}>Save changes</button>
                </div>
                {showToastSaved ? (
                    <div className="alert d-flex alert-success" role="alert">
                        <i className="bx bx-check-circle lead me-3"></i>
                        <div>
                            Your modifications have been saved.
                        </div>
                    </div>
                ) : null}
                {showToastErrorSaved ?
                    (<div className="alert d-flex alert-danger" role="alert">
                        <i className="bx bx-block lead me-3"></i>
                        {showToastErrorSaved}
                    </div>)
                    : null}
            </form>

            {user?.projects && user?.projects.map((project, idx) => (<>
                <h5 className="h5 text-primary pt-1 pt-lg-3 my-4">{project.name ? project.name : `Project or community #${idx + 1} details`}</h5>
                <form className="needs-validation border-bottom pb-2 pb-lg-4" novalidate>

                    <div className="col-sm-12 mb-4">
                        <label className="form-label fs-base">Name<span style={{ color: 'red' }}>*</span></label>
                        <input type="text" className="form-control form-control-lg" value={project.name} required onChange={(e) => { setProjectName(project.id, e.target.value) }} />
                    </div>

                    {project.id ? (
                        <div className="col-12 mb-4">
                            <label for="zip" className="form-label fs-base">Collaborators</label>
                            <p className="text-muted fs-sm">Team members can configure this project and organize raffles</p>
                            {project.members.map(member => (
                                <span className="badge bg-primary rounded-pill">
                                    @{member.twitter}
                                    {project.ownerTwitter === member.twitter ?
                                        (<span className="badge rounded-pill bg-success ms-2">owner</span>) :
                                        (<span role="button" className="badge rounded-pill bg-danger ms-2" onClick={(e) => { e.preventDefault(); handleRemoveMember(project.id, member.twitter) }}>remove</span>)}
                                </span>
                            ))}
                            <p></p>
                            <div className="input-group">
                                <span className="input-group-text">@</span>
                                <input type="text" className="form-control" placeholder="TwitterUsername" value={memberToAdd} onChange={(e) => { setMemberToAdd(e.target.value) }} />
                                <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); handleAddMember(project.id) }}>Add member</button>
                            </div>
                            <p className="text-muted fs-sm">The user must have an account on UtilityFactory to be added here</p>
                        </div>
                    ) : null}

                    <div className="col-12 mb-4">
                        <label for="email" className="form-label fs-base">Image (300x300)</label>
                        <p className="text-muted fs-sm">URL of the projet's image</p>
                        <input type="url" className="form-control form-control-lg" value={project.image} required onChange={(e) => { setProjectImage(project.id, e.target.value) }} />
                    </div>

                    <div className="col-12 mb-4">
                        <label for="email" className="form-label fs-base">Banner</label>
                        <p className="text-muted fs-sm">URL of a banner image</p>
                        <input type="url" className="form-control form-control-lg" value={project.banner} required onChange={(e) => { setProjectBanner(project.id, e.target.value) }} />
                    </div>


                    <div className="col-12 mb-4">
                        <label for="zip" className="form-label fs-base">Blockchain of your tokens </label>
                        <p className="text-muted">if you want to create giveaways with wallet conditions</p>
                        <select className="form-select" id="select-input" onChange={(e) => { setProjectBlockchain(project.id, e.target.value) }}>
                            <option value={null}>-- select a blockchain --</option>
                            {arrBlockchains?.map(b => (<option value={b.chain} selected={b.chain.toLowerCase() === project.blockchain}>{b.name}</option>))}
                        </select>
                    </div>


                    <div className="col-12 mb-4">
                        <label for="email" className="form-label fs-base">Mint currency</label>
                        <p className="text-muted fs-sm">URL of a banner image</p>
                        <input type="url" className="form-control form-control-lg" value={project.mintCurrency} required onChange={(e) => { setProjectValue(project.id, 'mintCurrency', e.target.value) }} />
                    </div>


                    <div className="col-12 mb-4">
                        <label for="email" className="form-label fs-base">Public price</label>
                        <p className="text-muted fs-sm"></p>
                        <input type="number" min={0} className="form-control form-control-lg" value={project.publicPrice} required onChange={(e) => { setProjectValue(project.id, 'publicPrice', e.target.value) }} />
                    </div>


                    <div className="col-12 mb-4">
                        <label for="email" className="form-label fs-base">Whitelist price</label>
                        <p className="text-muted fs-sm"></p>
                        <input type="number" min={0} className="form-control form-control-lg" value={project.wlPrice} required onChange={(e) => { setProjectValue(project.id, 'wlPrice', e.target.value) }} />
                    </div>


                    <div className="col-12 mb-4">
                        <label for="email" className="form-label fs-base">Mint date</label>
                        <p className="text-muted fs-sm"></p>
                        <input type="datetime-local" min={0} className="form-control form-control-lg" value={project.mintDate?.substring(0, 16)} required onChange={(e) => { setProjectValue(project.id, 'mintDate', e.target.value) }} />
                    </div>


                    <div className="col-sm-12 mb-4">
                        <label for="sn" className="form-label fs-base">Discord account &amp; bot grant (you must be owner of the server)</label>
                        <p className="text-muted fs-sm">Required only if you want to create raffles with Discord conditions</p>
                        {project.discordUsername ? (
                            (<div className="input-group">
                                <input type="text" id="sn" disabled={true} className="form-control form-control-lg" value={`${project.discordUsername}#${project.discordDiscriminator}`} required />
                                <span className="input-group-text"><img alt="Server selected" src={`https://cdn.discordapp.com/icons/${project.discordServerId}/${project.discordServerIcon}.png`} width="30" /></span>
                                <input type="text" id="discordName" disabled={true} className="form-control form-control-lg" value={`${project.discordServerName}`} />
                                <button className="btn btn-primary" onClick={() => { unlinkDiscord(project.id) }} type="button"><i className="bx bx-trash" style={{ color: 'white' }}></i></button>
                            </div>)
                        ) : (searchParams.get('discordUsername')) ? (
                            <div className="input-group">
                                <input type="text" id="sn" disabled={true} className="form-control form-control-lg" value={`${searchParams.get('discordUsername')}#${searchParams.get('discordDiscriminator')}`} required />
                                {searchParams.get('discordServerId') ?
                                    (<>
                                        {searchParams.get('discordServerIcon') ? (<span className="input-group-text">
                                            <img alt="Server selected" src={`https://cdn.discordapp.com/icons/${searchParams.get('discordServerId')}/${searchParams.get('discordServerIcon')}.png`} width="30" />
                                        </span>) : null}
                                        <input type="text" id="discordName" disabled={true} className="form-control form-control-lg" value={`${searchParams.get('discordServerName')}`} />
                                    </>) :
                                    (<select className="form-select" onChange={(e) => setDiscordServer(project.id, e.target.value)}>
                                        {arrDiscordServers && arrDiscordServers.length === 0 ?
                                            (<option disabled={true}>No server you are owner of ...</option>) :
                                            (<>
                                                <option value={null}>Select a server</option>
                                                {arrDiscordServers.map(s => (<option value={s.discordServerId}>{s.discordServerName}</option>))}
                                            </>)}
                                    </select>)}
                            </div>
                        ) : (
                            <a className="form-control form-control-lg btn btn-info" href={`${baseURL}/auth/${twitter}/discord`} role="button" >
                                <span className="bx bxl-discord-alt"></span>
                                Link discord account
                            </a>
                        )}
                    </div>

                    <div className="col-12 mb-4">
                        <label for="zip" className="form-label fs-base">Discord bot channel</label>
                        <p className="text-muted fs-sm">The bot will post on this channel. You will see authorization button after selecting the channel.</p>
                        <div className="input-group">
                            <select className="form-select" id="select-input" onChange={(e) => { setProjectDiscordChannelBot(project.id, e.target.value) }}>
                                <option disabled={true}>Select a channel from your server</option>
                                {userDiscordServerInfos && userDiscordServerInfos[project.discordServerId] && userDiscordServerInfos[project.discordServerId].channels && userDiscordServerInfos[project.discordServerId].channels.map(ch => (<option value={ch.id} selected={ch.id === project.discordBotChannel}>{ch.name}</option>))}
                            </select>
                            {/* {(project.discordBotChannel) ? (<a className="btn btn-primary" href="https://discord.com/api/oauth2/authorize?client_id=1011978413382324345&permissions=377957133312&scope=bot" rel="noreferrer" target="_blank">Authorize Bot</a>) : null} */}
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <label for="zip" className="form-label fs-base">Discord role to tag</label>
                        <p className="text-muted fs-sm">The bot will tag this role when it will post messages.</p>
                        <div className="input-group">
                            <select className="form-select" id="select-input" onChange={(e) => { setProjectDiscordRoleToTag(project.id, e.target.value) }}>
                                <option value="here">here</option>
                                {userDiscordServerInfos && userDiscordServerInfos[project.discordServerId] && userDiscordServerInfos[project.discordServerId].roles && userDiscordServerInfos[project.discordServerId].roles.filter(r => r.name.indexOf('everyone') === -1).map(ch => (<option value={ch.id} selected={ch.id === project.discordRoleToTag}>{ch.name}</option>))}
                            </select>
                            {/* {(project.discordBotChannel) ? (<a className="btn btn-primary" href="https://discord.com/api/oauth2/authorize?client_id=1011978413382324345&permissions=377957133312&scope=bot" rel="noreferrer" target="_blank">Authorize Bot</a>) : null} */}
                        </div>
                    </div>

                    <div className="d-flex mb-3" style={{ marginTop: '20px' }}>
                        <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); updateProjectDetails(project.id) }}>Save changes</button>
                        <button className="btn btn-danger mx-4" onClick={(e) => { e.preventDefault(); handleDeleteProject(project.id) }}>Delete project</button>
                    </div>
                    {showToastSaved ? (
                        <div className="alert d-flex alert-success" role="alert">
                            <i className="bx bx-check-circle lead me-3"></i>
                            <div>
                                Your modifications have been saved.
                            </div>
                        </div>
                    ) : null}
                    {showToastErrorSaved ? (
                        <div className="alert d-flex alert-danger" role="alert">
                            <i className="bx bx-block lead me-3"></i>
                            {showToastErrorSaved}
                        </div>
                    ) : null}

                </form>
            </>))}
            <hr />
            <button className="btn btn-success my-4" onClick={() => { handleCreateProject() }} >Create a project</button>

        </>
    )

}