
// import './TokensPanel.css';

import { useState, useEffect } from "react";

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


function MarketplacePage(props) {

    const [arrAvailableTokens, setArrAvailableTokens] = useState(null)


    const fetchAvailableTokens = async () => {

        try {
            const response = await axios.get(`${baseURL}/tokens/non-attributed`)
            console.log("Response :", response)
            setArrAvailableTokens(response.data)

        } catch (err) {
            //
        }
    }

    useEffect(() => {
        if (arrAvailableTokens === null) {
            fetchAvailableTokens()
        }
    })


    // Check user has pass :
    if (props.currentUser.passType === '') {

        return (<>
            <h1>Marketplace</h1>

            <div className="alert alert-primary mb-0" role="alert">
                <h4 className="pt-2 alert-heading">This is a premium feature</h4>
                <p>
                    Please upgrade your account, mint an access pass <a href='/upgrade'>here</a>.
                </p>
            </div>
        </>)
    }

    return (<>
        <h1>Marketplace</h1>
        <p className="text-muted fs-sm">List of available tokens & WL raffles. You can contact current owners to propose a raffle with your community.</p>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
            {(arrAvailableTokens && arrAvailableTokens.length > 0) ?
                arrAvailableTokens.filter(t => !t.attributedTo).map(token => (
                    <div className="col pb-1 pb-lg-3 mb-4">
                        <article>
                            <div className="d-block position-relative rounded-3 mb-3">
                                <span className="badge bg-dark position-absolute bottom-0 end-0 zindex-2 mb-3 me-3">{token.tokenType} - {token.blockchain}</span>
                                <img src={token.image.replace('ipfs://', 'https://ipfs.io/ipfs/')} className="img-fluid rounded-3" alt={token.name} />
                            </div>
                            <h3 className="h5">
                                {token.name} {token.tokenType === 'ERC1155' ? (<span className="fs-sm text-muted">(x{token.tokenAmount})</span>) : null}
                            </h3>
                            <div className="align-items-center text-muted">
                                { token.collection.twitter ? (<p>
                                    Collection : {token.collection.twitter ? (<a href={`https://twitter.com/${token.collection.twitter}`} target="_blank" rel="noreferrer">{token.collection.name}</a>) : (<i>{token.collection.name}</i>)}
                                </p>) : null }
                                <p>
                                    Current owner : <a href={`https://twitter.com/${token.ownerTwitter}`}>{token.ownerTwitter}</a>
                                </p>
                            </div>
                            {/* <a href="#!" rel="noreferrer" className="btn btn-link px-0 mt-3" onClick={() => { }}>
                                <i className="bx bx-user-pin fs-lg me-2"></i>
                                Propose a raffle
                            </a> */}
                        </article>
                    </div>
                )
                ) : null}

        </div>

    </>);
}

export default MarketplacePage;
