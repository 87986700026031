
// import './TokensPanel.css';

import { useState, useEffect } from "react";
import { saveAs } from "file-saver";

import AddCollabRaffleModal from './AddCollabRaffleModal'
import AttributeCollabRaffleModal from './AttributeCollabRaffleModal'
import {
    RouterProvider,
    Link,
    Routes,
    NavLink,
    Route
} from "react-router-dom";
import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'

const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()

function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    return str;
}

const toStrDate = (d) => {
    const date = new Date(parseInt(d))

    let day = date.getDate()
    if (day < 10) day = '0' + day

    let month = (date.getMonth() + 1)
    if (month < 10) month = '0' + month

    let hours = (date.getHours())
    if (hours < 10) hours = '0' + hours

    let minutes = (date.getMinutes())
    if (minutes < 10) minutes = '0' + minutes

    // console.log("Return :", date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + hours + ':' + minutes)
    return date.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes
}

// const now = new Date()
// const minDate = toStrDate(now.getTime() + (1 * 3600 * 1000))
// const maxDate = toStrDate(now.getTime() + (60 * 24 * 3600 * 1000))

function CollabOwnerRafflePage(props) {

    const [showAddRaffle, setShowAddRaffle] = useState(false)
    const [showAttributeRaffle, setAttributeRaffle] = useState(false)
    const [showEditRaffle, setShowEditRaffle] = useState(false)
    const [arrRaffles, setArrRaffles] = useState(null)
    const [copyPasted, setCopyPasted] = useState(false)
    const [project, setProject] = useState(false)
    const [filter, setFilter] = useState('all')

    const [user, setUser] = useState(null)

    useEffect(() => {
        async function getUser() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}`
            })
            let user = requestObj.data

            setUser(user)
            if (user.projects?.length > 0) {
                setProject(user.projects[0])
            }
        }
        console.log("Effect : user = ", user)
        if (!user && props.currentUser.twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, props.currentUser.twitter])

    const deleteLightRaffle = async (raffle) => {
        // Delete ...
        if (!window.confirm('Do you want to delete this raffle ?')) {
            return
        }

        try {
            const response = await axios.delete(`${baseURL}/collabs/${raffle.id}`)

            console.log("Response light raffle :", response)
            fetchRaffles()

        } catch (err) {
            //
            console.log("Err delete light raffles :", err)
        }

    }

    const fetchRaffles = async () => {

        try {
            const response = await axios.get(`${baseURL}/collabs/`)

            console.log("Response light raffle :", response)
            setArrRaffles(response.data)

        } catch (err) {
            //
            console.log("Err get light raffles :", err)
        }
    }

    useEffect(() => {

        if (arrRaffles === null) {
            fetchRaffles()
        }

        if (copyPasted) {
            setTimeout(() => {
                setCopyPasted(false)
            }, 5000)
        }
    })

    const handleDownloadWinners = async (raffle) => {
        // 
        try {
            const csv = await axios.get(`${baseURL}/collabs/${raffle.id}/winners`)

            console.log("Response collab raffle :", csv)
            const blob = new Blob([csv.data], { type: "text/csv;charset=utf-8" });
            saveAs(blob, 'Raffle_winners.csv');

        } catch (err) {
            //
            console.log("Err get collab raffles' winners :", err)
        }
    }

    const handleDuplicate = async (raffle) => {
        // 
        try {
            const response = await axios.patch(`${baseURL}/collabs/${raffle.id}/duplicate`)

            console.log("Response duplicate collab raffle :", response)
            if (response) {
                alert('Raffle duplicated 🔥')
                fetchRaffles()
            }

        } catch (err) {
            //
            console.log("Err duplicate collab raffle:", err)
        }
    }



    const funcFilter = (r) => {

        if (filter === 'all') return true;
        if (filter === 'available') return !r.diffuserProjectId;
        if (filter === 'sent') return r.diffuserProjectId && !r.started;
        if (filter === 'started') return r.started && r.raffleEndDate && Date.now() < r.raffleEndDate;
        if (filter === 'ended') return r.raffleEndDate && Date.now() > r.raffleEndDate;
    }

    return (<>
        <h1>Collab Management</h1>
        <h6 className="text-muted">Configure collabs &amp; choose diffusers</h6>

        {showAddRaffle ? (<AddCollabRaffleModal owner={true} currentUser={props.currentUser} project={project} projects={user.projects} updateRaffles={fetchRaffles} setShowAddRaffle={setShowAddRaffle} />) : null}
        {showEditRaffle ? (<AddCollabRaffleModal owner={true} currentUser={props.currentUser} project={project} projects={user.projects} raffle={showEditRaffle} updateRaffles={fetchRaffles} setShowAddRaffle={setShowEditRaffle} />) : null}
        {showAttributeRaffle ? (<AttributeCollabRaffleModal currentUser={props.currentUser} raffle={showAttributeRaffle} fetchRaffles={fetchRaffles} setShowPanel={setAttributeRaffle} />) : null}

        <ul className="nav nav-tabs-alt" role="tablist">
            {user && user.projects.map(curProject => (
                <li className="nav-item">
                    <a href={`#project${curProject.id}`} onClick={() => { setProject(curProject) }} className={`nav-link ${(curProject.id === project?.id) ? 'active' : ''}`} data-bs-toggle="tab" role="tab">
                        <i className="bx bx-profile opacity-70 me-2"></i>
                        {curProject.name}
                    </a>
                </li>
            ))}
        </ul>

        <div className="tab-content">

            {user && user.projects.map((project, idx) => (<>
                <div className={`tab-pane fade show ${idx === 0 ? 'active' : ''}`} id={`project${project.id}`} role="tabpanel">

                    <div className="d-flex align-items-end">
                        <div className=" p-2">
                            <button className="btn btn-success btn-sm" onClick={() => { setShowAddRaffle(true) }}>
                                <span className="bx bx-tada bx-plus" />
                                Add
                            </button>
                        </div>
                        <div className="btn-group border-start p-2" role="group" aria-label="Outline button group">
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'all'} onClick={() => { setFilter('all') }}>All</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'available'} onClick={() => { setFilter('available') }}>Available</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'sent'} onClick={() => { setFilter('sent') }}>Sent</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'started'} onClick={() => { setFilter('started') }}>Started</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" disabled={filter === 'ended'} onClick={() => { setFilter('ended') }}>Ended</button>
                        </div>
                    </div>

                    {!arrRaffles ? (<p className="m-5 p-1 text-muted">No collab here. Want to offer tokens or WLs to a partner for a giveaway partnership ? Create it here, send to your partner, we'll take care of everything 🔥</p>) : null}

                    {arrRaffles && arrRaffles.filter(r => r.projectId === project.id).filter(funcFilter).map(raffle => (<>
                        <article className="card border-0 bg-transparent m-2 p-1">
                            <div className="row g-0">
                                <div className="col-sm-4 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3" style={{ backgroundImage: `url(${raffle.image})`, minHeight: '15rem' }}>
                                    <a href="#" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more"></a>
                                </div>
                                <div className="col-sm-8">
                                    <div className="card-body px-0 pt-sm-0 ps-sm-4 pb-0 pb-sm-4">

                                        {!raffle.diffuserProjectId ? (<span className="m-1 badge bg-success shadow-success fs-sm mb-3">available</span>) : null}
                                        {raffle.diffuserProjectId && !raffle.started ? (<span className="m-1 badge bg-warning shadow-success fs-sm mb-3">sent</span>) : null}
                                        {raffle.started && raffle.raffleEndDate && Date.now() < raffle.raffleEndDate ? (<span className="m-1 badge bg-info shadow-info fs-sm mb-3">started</span>) : null}
                                        {raffle.started && raffle.raffleEndDate && Date.now() > raffle.raffleEndDate ? (<span className="m-1 badge bg-danger shadow-danger fs-sm mb-3">ended</span>) : null}

                                        {raffle.diffuserProject ? (<span className="badge bg-primary">Partner: <a className="text-white" href={`https://twitter.com/${raffle.diffuserProject.ownerTwitter}`} target="_blank">{raffle.diffuserProject.name}</a></span>) : null}

                                        <h3 className="h4">
                                            {raffle.name}
                                        </h3>
                                        {/* <p className="mb-4">DESC</p> */}
                                        {raffle.winnersStr ? (<p className="card-text">
                                            Winner(s) (total list downloadable):
                                            <ul>
                                                {raffle.winnersStr === '' ? <li>No winner</li> : raffle.winnersStr.split(',').slice(0, 3).map(w => <li><a href={`https://twitter.com/${w}`} rel="noreferrer"><span className="bx bxl-twitter" /> {w}</a></li>)}
                                            </ul>
                                            {raffle.winnersStr ? (<button className="btn btn-success btn-sm" disabled={!raffle.winnersStr} onClick={() => { handleDownloadWinners(raffle) }}><i className="bx bx-cloud-download"></i> Download winners</button>) : null}
                                        </p>) : null}

                                        <div className="mb-4">
                                            <label for="text-input" className="form-label">
                                                Share this link to collect participations
                                            </label>
                                            <input tupe="url" disabled={true} style={{ cursor: 'text' }} className="form-control" value={`https://ufxyz.com/c/c${raffle.id}-${slugify(raffle.name)}`} />
                                        </div>
                                        {raffle.raffleStartDate ? (<div className="mb-4">
                                            <i className="bx bx-timer fs-lg me-1"></i>
                                            <span className="fs-sm">Start: {(new Date(parseInt(raffle.raffleStartDate))).toLocaleDateString()} {(new Date(parseInt(raffle.raffleStartDate))).toLocaleTimeString()}</span>
                                        </div>) : null}
                                        {raffle.raffleEndDate ? (<div className="mb-4">
                                            <i className="bx bx-time fs-lg me-1"></i>
                                            <span className="fs-sm">End: {(new Date(parseInt(raffle.raffleEndDate))).toLocaleDateString()} {(new Date(parseInt(raffle.raffleEndDate))).toLocaleTimeString()}</span>
                                        </div>) : null}
                                        <div className="d-flex align-items-center text-muted">
                                            <div className="fs-sm border-end pe-3 me-3">
                                                <i className="bx bx-bookmark-plus fs-lg me-1"></i>
                                                {(new Date(raffle.createdAt)).toLocaleDateString()}
                                            </div>
                                            <div className="d-flex align-items-center me-3">
                                                {raffle.started ?
                                                    (<>
                                                        <Link to={`/raffle/l/${raffle.id}`} params={{ raffleId: `l${raffle.id}` }} className="btn btn-outline-primary btn-sm">
                                                            View more
                                                        </Link>
                                                    </>) :
                                                    !raffle.diffuserProjectId ? (<>
                                                        <button className="m-1 btn btn-outline-success" onClick={() => { setAttributeRaffle(raffle) }} alt="Start">
                                                            <i className="bx bx-mail-send"></i>
                                                        </button>
                                                        <button className="m-1 btn btn-outline-primary" onClick={() => { setShowEditRaffle(raffle) }}>
                                                            <i className="bx bx-pencil"></i>
                                                        </button>
                                                        <button className="m-1 btn btn-outline-danger" onClick={() => { deleteLightRaffle(raffle) }}>
                                                            <span className="bx bx-trash" />
                                                        </button>
                                                    </>) : null}
                                                <button className="m-1 btn btn-outline-info" onClick={() => { handleDuplicate(raffle) }}>
                                                    <i className="bx bx-copy"></i>
                                                </button>

                                            </div>
                                            <div className="d-flex align-items-center me-3">
                                                <i className="bx bx-gift fs-lg me-1"></i>
                                                <span className="fs-sm">{raffle.nbSeats}</span>
                                            </div>
                                            {/* <div className="d-flex align-items-center">
                                                <i className="bx bx-share-alt fs-lg me-1"></i>
                                                <span className="fs-sm">9</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </>
                    ))}



                </div>
            </>))}
        </div>

    </>);
}

export default CollabOwnerRafflePage;
