
// import './TokensPanel.css';
import { useState, useEffect } from "react";

import AddPrizeWLModal from "./sub/AddPrizeWLModal"
import AddPrizeTokenModal from "./sub/AddPrizeTokenModal"

import { useAccount, useConnect, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


function CollabPrizesPage(props) {

    const [arrPrizes, setArrPrizes] = useState(null)
    const [user, setUser] = useState(null)
    const [showAddToken, setShowAddToken] = useState(false)
    const [showAddWL, setShowAddWL] = useState(false)
    const [projectId, setProjectId] = useState(false)
    const { address, connector, isConnected } = useAccount()
    const { connect, connectors, error: errorConnect, isLoading: isLoadingConnect, pendingConnector } = useConnect()

    useEffect(() => {
        async function getUser() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}`
            })
            let user = requestObj.data

            setUser(user)
        }
        console.log("Effect : user = ", user)
        if (!user && props.currentUser.twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, props.currentUser.twitter])



    const fetchPrizes = async () => {

        try {
            const response = await axios.get(`${baseURL}/prizes`)
            console.log("Response :", response)
            setArrPrizes(response.data.prizes)

        } catch (err) {
            //
        }
    }

    useEffect(() => {
        if (arrPrizes === null) {
            fetchPrizes()
        }
    })

    return (<>
        <h1>Collab prizes</h1>
        <h6 className="text-muted">Available prizes - Can be added to collabs</h6>

        {showAddToken ? (<AddPrizeTokenModal currentUser={props.currentUser} user={user} project={user && user.projects && projectId && user.projects.find(p => p.id === projectId)} updateList={fetchPrizes} setShowPanel={setShowAddToken} />) : null}

        <ul className="nav nav-tabs-alt" role="tablist">
            {user && user.projects.map((project, idx) => (
                <li className="nav-item">
                    <a href={`#project${project.id}`} className={`nav-link ${idx === 0 ? 'active' : ''}`} data-bs-toggle="tab" role="tab">
                        <i className="bx bx-profile opacity-70 me-2"></i>
                        {project.name}
                    </a>
                </li>
            ))}
        </ul>

        <div className="tab-content">


            {user && user.projects.map((project, idx) => (<>
                <div className={`tab-pane fade show ${idx === 0 ? 'active' : ''}`} id={`project${project.id}`} role="tabpanel">
                    <div className="row">

                        {(address) ? (<p className="text-center">
                            <button className="btn btn-primary mx-2" disabled={!project.id} onClick={() => { setProjectId(project.id); setShowAddToken(true) }}><i className="bx bx-plus" /> Add token</button>
                        </p>) : (<p className="text-center">
                            <p className="alert alert-info">Connect your project's wallet to add tokens</p>
                            <div className="btn-group  p-1 m-1">
                                {
                                    connectors.map((connector) => (
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            disabled={!connector.ready}
                                            key={connector.id}
                                            onClick={() => connect({ connector })}
                                        >
                                            {connector.name}
                                            {!connector.ready && ' (unsupported)'}
                                            {isLoadingConnect &&
                                                connector.id === pendingConnector?.id &&
                                                ' (connecting)'}
                                        </button>
                                    ))}
                            </div>
                        </p>)}


                        {(user && arrPrizes && arrPrizes.length > 0) ?
                            arrPrizes.filter(p => p.tokenId && !p.collabId && p.projectId === project.id).map(prize => (
                                <div class="card overflow-hidden col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-4 bg-repeat-0 bg-size-cover" style={{ backgroundImage: `url(${prize.image ? prize.image.replace('ipfs://', 'https://ipfs.io/ipfs/') : user.projects.find(p => p.id === prize.projectId).image})`, minHeight: '8rem' }}></div>
                                        <div class="col-sm-8">
                                            <div class="card-body">
                                                <h5 class="card-title">{prize.name ? prize.name : user.projects.find(p => p.id === prize.projectId).name}</h5>
                                                <p class="card-text fs-sm">
                                                    Token id : {parseInt(prize.tokenId, 16)}
                                                </p>
                                                {/* <a class="btn btn-sm btn-primary" href="#">Go somewhere</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : null}

                    </div >
                </div>
            </>))
            }
        </div >
    </>);
}

export default CollabPrizesPage;
