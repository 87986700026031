
import ReactEcharts from 'echarts-for-react'

function RaffleSummary(props) {

    const raffleStats = props.raffleStats
    // const project = props.project
    // const user = props.user

    return (<div className="card overflow-hidden mt-4">
        <div className="row g-0">
            <div className="col-sm-4 bg-repeat-0 bg-size-cover" style={{ backgroundImage: `url(${raffleStats.image})`, minHeight: '12rem' }}></div>
            <div className="col-sm-8">
                <div className="card-body">
                    <h5 className="card-title">{raffleStats.name}</h5>

                    <div className="row">
                        <div className="col-4">
                            <div className="list-group-flush">
                                <div className="list-group-item list-group-item-action">
                                    <span className="bx bx-user" alt="Participants nb"></span> {raffleStats.nbParticipations}
                                </div>
                                <div className="list-group-item list-group-item-action">
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="list-group-flush">
                                <div className="list-group-item list-group-item-action">
                                    <span className="bx bx-trophy" alt="Participants nb"></span> {raffleStats.nbWinners}
                                </div>
                                <div className="list-group-item list-group-item-action">
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="list-group-flush">
                                <div className="list-group-item list-group-item-action">
                                    <span className="bx bx-time" alt="End date"></span> {new Date(parseInt(raffleStats.raffleEndDate)).toLocaleDateString()}
                                </div>
                                <div className="list-group-item list-group-item-action">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <p className="fs-xs">Participation repartition over time</p>
                    {raffleStats.participationsRepartition ? (<ReactEcharts
                        style={{ height: '100px' }}
                        option={{
                            xAxis: {
                                type: 'category',
                                data: raffleStats.participationsRepartition.map(v => {
                                    const d = new Date(v.date)
                                    const day = d.getDate()
                                    const month = d.getMonth() + 1
                                    const hour = d.getHours()
                                    const minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
                                    return `${day}/${month} ${hour}:${minutes}`
                                }),
                            },
                            yAxis: {
                                type: 'value',
                                show: false,
                            },
                            tooltip: {
                                trigger: "item"
                            },
                            series: [
                                {
                                    data: raffleStats.participationsRepartition.map(v => parseInt(v.value)),
                                    type: 'bar'
                                }
                            ]
                        }}
                    />) : null}
                </div>
            </div>
        </div>
    </div>)
}

export default RaffleSummary;
