
import { useRef, useState, useEffect } from 'react'
import { ethers } from 'ethers';
import axiosObj from 'axios';
import { getBaseURL } from '../utils/constants'
import { Editor } from '@tinymce/tinymce-react'

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
// import { Shortcuts } from 'tinymce';

// import 'tinymce/tinymce.min.js'

const baseURL = getBaseURL()
const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});


const toStrDate = (d) => {
    const date = new Date(parseInt(d))

    let day = date.getDate()
    if (day < 10) day = '0' + day

    let month = (date.getMonth() + 1)
    if (month < 10) month = '0' + month

    let hours = (date.getHours())
    if (hours < 10) hours = '0' + hours

    let minutes = (date.getMinutes())
    if (minutes < 10) minutes = '0' + minutes

    // console.log("Return :", date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + hours + ':' + minutes)
    return date.getFullYear() + '-' + month + '-' + day + 'T' + hours + ':' + minutes
}

const now = new Date()
const minDate = toStrDate(now.getTime() + (1 * 3600 * 1000))
const maxDate = toStrDate(now.getTime() + (6.9 * 24 * 3600 * 1000))


export default function AttributeCollabRaffleModal(props) {

    const [raffleEndDate, setRaffleEndDate] = useState(minDate)
    const [arrParticipationConditions, setParticipationConditions] = useState(undefined)
    const [raffleConditions, setRaffleConditions] = useState(false)
    const [attributedTo, setAttributedTo] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState([])

    console.log("Raffle :", props.raffle)
    let setShowPanel = props.setShowPanel

    const fetchParticipationConditions = async () => {
        try {
            const response = await axios.get(`${baseURL}/users/participationconditions`)

            console.log("Response participation conditions :", response)
            setParticipationConditions(response.data)
        } catch (err) {
            //
        }
    }

    const initRaffleConditions = () => {
        // console.log("setRaffleConditions :", arrParticipationConditions)
        if (!arrParticipationConditions) {
            // console.log("No arrPartipation conditions ...")
            return
        }
        // console.log("======> Owner conditions :", props.offer.raffleConditions)
        const conditions = (props.raffle.ownerMandatoryConditions + ((props.raffle.ownerOptionalConditions) ? ',' + props.raffle.ownerOptionalConditions : '')).split(',')
        let arr = []
        for (let condition of conditions) {
            let arrCondition = condition.split('/')
            // console.log("Add owner cond : ", arrCondition)
            const found = arrParticipationConditions.find(c => c.id === arrCondition[0])
            if (found) {
                arr.push(found)
            }
        }
        // console.log("owner conditions :", arr)
        setRaffleConditions(arr)
    }

    const handleStartRaffle = async () => {

        if (!raffleEndDate) {
            alert('You have to provide a end date for the raffle')
        }

        try {

            let tmpDate = Date.parse(raffleEndDate.replace('T', ' ').replace(/\-/g, '/'))

            await axios({
                method: 'POST',
                url: `${baseURL}/collabs/${props.raffle.id}/attribute`,
                data: {
                    diffuserProjectId: attributedTo[0].id
                }
            })
            props.setShowPanel(false)
            props.fetchRaffles()

        } catch (err) {
            console.log("Error add light raffle :", err)
            alert('An error occured ... sorry for that :(')
        }
    }

    useEffect(() => {
        if (typeof arrParticipationConditions === 'undefined')
            fetchParticipationConditions()
        else if (raffleConditions === false && arrParticipationConditions)
            initRaffleConditions()

    }, [raffleConditions, arrParticipationConditions])


    const handleSearch = (query) => {
        setIsLoading(true);
        console.log("Handle search,", query)
        axios({
            method: 'GET',
            url: `${baseURL}/projects/?q=${query}`,
        })
            .then((resp) => {
                console.log("Resp :", resp)
                return resp.data
            })
            .then((items) => {
                console.log("Items :", items)
                setOptions(items);
                setIsLoading(false);
            });
    };

    const filterBy = () => true;

    return (<div className="modal show" role="dialog" style={{ display: 'block' }} id="profile">
        <div className="modal-dialog" role="document" style={{ maxWidth: '900px' }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Offer this collab</h4>
                    <button type="button" onClick={() => { props.setShowPanel(false) }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="mb-3">
                            <label className="form-label" >Owner's conditions</label><br />
                            <small className="text-muted">Selected conditions for owner's side</small><br />
                            {raffleConditions && raffleConditions.map(p => (<>
                                <div className="form-check">
                                    <input className="form-check-input" checked={true} disabled={true} type="checkbox" />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        {p.name}
                                    </label>
                                </div>
                            </>))}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Partner project</label>

                            <p className="alert alert-info">
                                Choose among existing projects on Utility Factory. If your partner has no UtilityFactory account, it's free for the partner.
                            </p>

                            <AsyncTypeahead
                            filterBy={filterBy}
                                id="basic-typeahead-single"
                                onSearch={handleSearch}
                                labelKey="name"
                                minLength={3}
                                isLoading={isLoading}
                                options={options}
                                onChange={(attributeTo) => {
                                    console.log("Select attributed to :", attributeTo)
                                    setAttributedTo(attributeTo)
                                }}
                                placeholder="Enter Twitter username of your partner"
                                selected={attributedTo}
                            />
                        </div>

                    </form>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-light" type="button" onClick={() => { props.setShowPanel(false) }} data-bs-dismiss="modal">Close</button>
                    <button className="btn btn-primary" type="button" onClick={() => { handleStartRaffle() }}>
                        Sent collab proposition
                    </button>
                </div>
            </div>
        </div >
    </div >)
}