
import { useRef, useState } from 'react'
import axiosObj from 'axios';
import { getBaseURL } from '../../utils/constants'

import 'react-bootstrap-typeahead/css/Typeahead.css'
// import { Shortcuts } from 'tinymce';

// import 'tinymce/tinymce.min.js'

const baseURL = getBaseURL()
const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

export default function AddPrizeWLModal(props) {

    let { twitter } = props.currentUser
    let setShowPanel = props.setShowPanel

    const [projectId, setProjectId] = useState(props.project.id)
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };


    const [raffleToAdd, setRaffleToAdd] = useState({ projectName: false, description: '', ownerTwitter: twitter, publicPrice: 0, wlPrice: 0, mintCurrency: 'ETH', blockchain: 'ethereum', nbSeats: 1, maxMints: 1 })

    console.log("Projects :", props.project)

    let {
        blockchain,
        name,
        mintCurrency,
        publicPrice,
        wlPrice,
        ownerWebsite,
        ownerTwitter,
        ownerDiscord,
        nbSeats,
        maxMints
    } = raffleToAdd

    const setValue = (k, v) => {
        // console.log("Set value", k, v)
        setRaffleToAdd({
            ...raffleToAdd,
            [k]: v
        })
    }

    const onClick = async () => {

        if (!blockchain || !name) {

            console.log("Blockchain :", blockchain, ", name:", name)
            alert(`You must provide a mandatory informations ;)`)
            return
        }

        try {

            await axios({
                method: 'POST',
                url: `${baseURL}/prizes/wl`,
                data: {
                    ...raffleToAdd,
                    projectId,
                    projectName: props.project.name,
                    name,
                    blockchain,
                    nbSeats,
                    maxMints,
                }
            })
            props.updateList()
            setShowPanel(false)

        } catch (err) {
            console.log("Error add WL", err)
            alert('An error occured ... sorry for that :(')
        }
    }

    return (<div className="modal show" role="dialog" style={{ display: 'block' }} id="profile">
        <div className="modal-dialog" role="document" style={{ maxWidth: '900px' }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add a WhiteList raffle</h4>
                    <button type="button" onClick={() => { props.setShowPanel(false); }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="mb-4">
                            <label className="form-label">Project<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" disabled={true} value={props.project.name} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">WL name<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" value={name} onChange={(e) => { setValue('name', e.target.value) }} placeholder={`${props.currentUser.name} x partner name`} />
                            <p className="form-text">Just an identifier to find different WL offers</p>
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Mint currency</label>
                            <input type="text" className="form-control" value={mintCurrency} onChange={(e) => { setValue('mintCurrency', e.target.value) }} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Public mint price</label>
                            <input type="number" min="0" className="form-control" value={publicPrice} onChange={(e) => { setValue('publicPrice', e.target.value) }} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">WL mint price</label>
                            <input type="number" min="0" className="form-control" value={wlPrice} onChange={(e) => { setValue('wlPrice', e.target.value) }} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Owner's website</label>
                            <input type="url" className="form-control" value={ownerWebsite} onChange={(e) => { setValue('ownerWebsite', e.target.value) }} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Owner's Twitter<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" disabled={true} className="form-control" value={ownerTwitter} onChange={(e) => { setValue('ownerTwitter', e.target.value) }} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Owner's Discord</label>
                            <input type="text" disabled={true} className="form-control" value={ownerDiscord} onChange={(e) => { setValue('ownerDiscord', e.target.value) }} />
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Number of spots to win<span style={{ color: 'red' }}>*</span></label>
                            <input type="number" min="1" max="50" className="form-control" value={nbSeats} onChange={(e) => { setValue('nbSeats', e.target.value) }} />
                            <p className="form-text">Between 1 and 10 possible winners</p>
                        </div>

                        <div className="mb-4">
                            <label className="form-label">Max number of mints<span style={{ color: 'red' }}>*</span></label>
                            <input type="number" min="1" max="20" className="form-control" value={maxMints} onChange={(e) => { setValue('maxMints', e.target.value) }} />
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-light" type="button" onClick={() => { props.setShowPanel(false) }} data-bs-dismiss="modal">Close</button>
                    <button className="btn btn-primary" type="button" onClick={() => { onClick() }}>
                        Add WL Prize
                    </button>
                </div>
            </div>
        </div>
    </div>)
}