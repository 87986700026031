
// import './TokensPanel.css';

import { useState, useEffect } from "react";

import { ethers } from "ethers";
import { useAccount, useConnect, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()

// const CONTRACT_PASS_ADDR = '0xF07148d40D2a33dEAacB2F914E1d14bfb747363F' // MAINNET
// const CONTRACT_PASS_ADDR = '0xa4ECE0065194FBdaD2cB4b0561F29406346a43ed' // GOERLI
const CONTRACT_PASS_ADDR = (window.location.hostname === 'localhost')? '0xa4ECE0065194FBdaD2cB4b0561F29406346a43ed' : '0xF07148d40D2a33dEAacB2F914E1d14bfb747363F'

const CONTRACT_ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "MAX_FREE_MINT",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "SPONRORED_OFFER",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "activate",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "activationDate",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "code",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "a",
                "type": "address"
            }
        ],
        "name": "addSponsor",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "freeMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getTokenInfos",
        "outputs": [
            {
                "internalType": "enum UtilityFactoryPassGenesis.TokenType",
                "name": "",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "isValid",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "mintGold",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "sponsorCode",
                "type": "string"
            }
        ],
        "name": "mintGoldSponsored",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "mintSilver",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "sponsorCode",
                "type": "string"
            }
        ],
        "name": "mintSilverSponsored",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nextOwnerToExplicitlySet",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "numberMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "code",
                "type": "string"
            }
        ],
        "name": "removeSponsor",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "_data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "baseURI",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bool",
                "name": "state",
                "type": "bool"
            }
        ],
        "name": "setSaleState",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenOfOwnerByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawMoney",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

function MyPassPage(props) {

    // TODO : Switch to mainnet if not already !!!!!!!!!!!!!!!!

    const [sponsorCode, setSponsorCode] = useState('')
    const [passList, setPassList] = useState(null)
    const [errorTx, setErrorTx] = useState(null)
    const [waitingTx, setWaitingTx] = useState(false)

    const [tokenIdToActivate, setTokenIdToActivate] = useState(null)

    const { address, connector, isConnected } = useAccount()
    const { connect, connectors, error: errorConnect, isLoading: isLoadingConnect, pendingConnector } = useConnect()

    console.log("Address connected :", address)
    const { config: configSilver, error: prepareErrorSilver, isError: isPrepareErrorSilver } = usePrepareContractWrite({
        address: CONTRACT_PASS_ADDR,
        abi: CONTRACT_ABI,
        chainId: window.location.hostname === 'localhost' ? 5 : 1,
        functionName: sponsorCode ? 'mintSilverSponsored' : 'mintSilver',
        args: sponsorCode ? [1, sponsorCode] : [1],
        enabled: true,
        overrides: {
            from: address,
            value: sponsorCode ? (window.location.hostname === 'localhost' ? ethers.utils.parseEther('0.003') : ethers.utils.parseEther('0.3')) : (window.location.hostname === 'localhost' ? ethers.utils.parseEther('0.0035') : ethers.utils.parseEther('0.35')),
        },
    })
    const { config: configGold, error: prepareErrorGold, isError: isPrepareErrorGold } = usePrepareContractWrite({
        address: CONTRACT_PASS_ADDR,
        abi: CONTRACT_ABI,
        chainId: window.location.hostname === 'localhost' ? 5 : 1,
        functionName: sponsorCode ? 'mintGoldSponsored' : 'mintGold',
        args: sponsorCode ? [1, sponsorCode] : [1],
        enabled: true,
        overrides: {
            from: address,
            value: sponsorCode ? (window.location.hostname === 'localhost' ? ethers.utils.parseEther('0.009') : ethers.utils.parseEther('0.9')) : (window.location.hostname === 'localhost' ? ethers.utils.parseEther('0.0095') : ethers.utils.parseEther('0.95')),
        },
    })
    const { config: configActivate, error: prepareErrorActivate, isError: isPrepareErrorActivate } = usePrepareContractWrite({
        address: CONTRACT_PASS_ADDR,
        abi: CONTRACT_ABI,
        chainId: window.location.hostname === 'localhost' ? 5 : 1,
        functionName: 'activate',
        args: [tokenIdToActivate], // TOKEN ID !!!!
        enabled: Boolean(tokenIdToActivate),
    })

    const { write: writeSilver, error: errorWriteSilver, isError: isErrorSilver, data: dataSilver } = useContractWrite(configSilver)
    const { write: writeGold, error: errorWriteGold, isError: isErrorGold, data: dataGold } = useContractWrite(configGold)
    const { write: writeActivate, error: errorWriteActivate, isError: isErrorActivate, data: dataActivate } = useContractWrite(configActivate)

    const { isLoading: isLoadingSilver, isSuccess: isSuccessSilver } = useWaitForTransaction({
        hash: dataSilver?.hash,
    })
    const { isLoading: isLoadingGold, isSuccess: isSuccessGold } = useWaitForTransaction({
        hash: dataGold?.hash,
    })
    const { isLoading: isLoadingActivate, isSuccess: isSuccessActivate } = useWaitForTransaction({
        hash: dataActivate?.hash,
    })

    // Go & get data :
    useEffect(() => {

        // Get pass list :
        //
        const getPassList = async () => {

            console.log("Current user :", props.currentUser)
            try {
                const response = await axios.get(`${baseURL}/users/${props.currentUser.twitter}/passList`)

                console.log("Response passList :", response)
                setPassList(response.data)

            } catch (err) {
                //
            }

        }

        if (props.currentUser && props.currentUser.twitter) {
            if (passList === null) {
                getPassList()
            }
        }
    }, [passList, props.currentUser])

    useEffect(() => {

        if (tokenIdToActivate && writeActivate) {
            writeActivate()
        }
    }, [tokenIdToActivate])

    // Get nb pass :
    const nbSilver = passList && passList.length ? passList.filter(p => p.passType === 'silver').length : 0
    const nbGold = passList && passList.length ? passList.filter(p => p.passType === 'gold').length : 0

    const nbSilverValid = passList && passList.length ? passList.filter(p => p.passType === 'silver' && p.isValid).length : 0
    const nbGoldValid = passList && passList.length ? passList.filter(p => !p.passType === 'gold' && p.isValid).length : 0

    if (!address) {

        return (<>
            <h1>My Access Pass</h1>
            <div className="btn-group">
                {
                    connectors.map((connector) => (
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            disabled={!connector.ready}
                            key={connector.id}
                            onClick={() => connect({ connector })}
                        >
                            {connector.name}
                            {!connector.ready && ' (unsupported)'}
                            {isLoadingConnect &&
                                connector.id === pendingConnector?.id &&
                                ' (connecting)'}
                        </button>
                    ))}
            </div>
        </>)
    }

    return (<>
        <h2 className="h2 pt-xl-1 pb-3">My access pass</h2>

        {
            errorTx ? (<div className="alert alert-danger mb-4" role="alert">
                <h4 className="pt-2 alert-heading">An error occured</h4>
                <p>{errorTx.message.indexOf('cannot estimate gas') > -1 ? `Seems that the transaction is impossible. Maybe not enough currency on your wallet ? Check sponsor code, check you are connected with the correct account.` : null}</p>
                <p>{errorTx.message.indexOf('user rejected') > -1 ? `You rejected this transaction. Maybe you are not sure to pay for our service ?` : null}</p>
                <p>If you cannot figure it out. Check the Support page and contact us on our Discord server.</p>
            </div>) : null
        }

        <ul className="nav nav-tabs-alt" role="tablist">
            <li className="nav-item">
                <a href="#home1" className={'nav-link ' + ((passList && passList.length > 0) ? 'active' : '')} data-bs-toggle="tab" role="tab">
                    <i className="bx bx-user-check opacity-70 me-2"></i>
                    Pass list
                </a>
            </li>
            <li className="nav-item">
                <a href="#profile1" className={'nav-link ' + ((passList && passList.length > 0) ? '' : 'active')} data-bs-toggle="tab" role="tab">
                    <i className="bx bx-key opacity-70 me-2"></i>
                    Mint an access pass
                </a>
            </li>
        </ul>

        <div className="tab-content">
            <div className={'tab-pane fade ' + ((passList && passList.length > 0) ? 'show active' : '')} id="home1" role="tabpanel">

                {!passList || passList.length === 0 ? (<span className="text-muted">No access pass yet, mint one ;)</span>) : null}

                {isSuccessActivate && (
                    <div className="alert alert-success">
                        Successfully activated your Pass!
                        <div>
                            <a href={`https://etherscan.io/tx/${dataActivate?.hash}`}>Etherscan</a>
                        </div>
                    </div>
                )}
                {(isPrepareErrorActivate || isErrorActivate) && (
                    <div className="alert alert-danger">Error: {(prepareErrorActivate || errorWriteActivate)?.message}</div>
                )}

                {passList && passList.map(pass => (
                    <article className="card border-0 shadow-sm overflow-hidden mb-4">
                        <div className="row g-0">
                            <div className="col-sm-4 position-relative bg-position-center bg-repeat-0 bg-size-cover" style={{ backgroundImage: `url(${pass.passType === 'silver' ? 'https://api.utilityfactory.xyz/static/token_image_silver.jpg' : 'https://api.utilityfactory.xyz/static/token_image_gold.jpg'})`, minHeight: '15rem' }}>
                            </div>
                            <div className="col-sm-8">
                                <div className="card-body">
                                    <h3 className="h4">
                                        {pass.passType === 'gold' ? 'Gold Access Pass' : 'Silver Access Pass'} #{pass.tokenId}
                                    </h3>
                                    {pass.activationDate === "1970-01-01T00:00:00.000Z" ? (<span className="badge bg-danger">not activated</span>) : pass.isValid ? (<span className="badge bg-success">activated</span>) : (<span className="badge bg-warning">expired</span>)}
                                    <hr className="my-4" />
                                    <div className="d-flex align-items-center justify-content-between">
                                        {
                                            waitingTx ? (<div className="spinner-border text-secondary" role="status">
                                                <span className="visually-hidden">Waiting transaction...</span>
                                            </div>) :
                                                pass.activationDate === "1970-01-01T00:00:00.000Z" ? (<p className="pt-3 mb-2">
                                                    <button className="btn btn-primary btn-" disabled={isLoadingActivate} onClick={() => { setTokenIdToActivate(pass.tokenId) }}>
                                                        {isLoadingActivate ? 'Activating...' : 'Activate now'}
                                                    </button>
                                                </p>) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>))}


            </div>
            <div className={'tab-pane fade ' + ((passList && passList.length > 0) ? '' : 'show active')} id="profile1" role="tabpanel">

                <div className="table-responsive-lg">

                    <div className="row flex-nowrap pb-4">
                        <div className="col">
                            <div className="card h-100 border-0 shadow-sm p-xxl-3" style={{ minWidth: '18rem' }}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center pb-2 pb-md-3 mb-4">
                                        <div className="ps-4">
                                            <h3 className="fs-lg fw-normal text-body mb-2">Silver</h3>
                                            <h4 className="h3 lh-1 mb-0">0.35 <span className="text-body fs-sm fw-normal">$ETH - 3 months validity</span></h4>
                                        </div>
                                    </div>
                                    <ul className="list-unstyled fs-sm pb-md-3 mb-3">
                                        <li className="d-flex mb-2">
                                            <i className="bx bx-check fs-xl me-1"></i>
                                            <span className="opacity-70">WhiteList Giveaways</span>
                                        </li>
                                        <li className="d-flex mb-2">
                                            <i className="bx bx-check fs-xl me-1"></i>
                                            <span className="opacity-70">WhiteList Management</span>
                                        </li>
                                        <li className="d-flex mb-2">
                                            <i className="bx bx-check fs-xl me-1"></i>
                                            <span className="opacity-70">Premium pages</span>
                                        </li>
                                        <li className="d-flex mb-2">
                                            <i className="bx bx-check fs-xl text-primary me-1"></i>
                                            Token Giveaways - <b>50$ tech tax applicable</b>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer border-0 pt-0 pb-4">
                                    {waitingTx ? (<div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Waiting transaction...</span>
                                    </div>) : (
                                        <button onClick={(e) => { e.preventDefault(); writeSilver?.() }} disabled={!writeSilver || isLoadingSilver || isLoadingGold} className="btn btn-outline-primary w-100">
                                            {isLoadingGold || isLoadingSilver ? 'Minting ...' : 'Mint Silver Pass'}
                                        </button>
                                    )}
                                    <p className="fs-sm">
                                        You have {nbSilver} of them. {nbSilverValid} activated.
                                    </p>
                                    {isSuccessSilver && (
                                        <div className="alert alert-success">
                                            Successfully minted your Pass!
                                            <div>
                                                <a href={`https://etherscan.io/tx/${dataSilver?.hash}`}>Etherscan</a>
                                            </div>
                                        </div>
                                    )}
                                    {(isPrepareErrorSilver || isErrorSilver) && (
                                        <div className="alert alert-danger">Error: Maybe not enough funds or wallet not initialized</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0 bg-primary shadow-primary shadow-dark-mode-none p-xxl-3" style={{ minWidth: '18rem' }}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center pb-2 pb-md-3 mb-4">
                                        <div className="ps-4">
                                            <h3 className="fs-lg fw-normal text-light opacity-70 mb-2">Gold</h3>
                                            <h4 className="h3 text-light lh-1 mb-0">0.95 <span className="fs-sm fw-normal opacity-70">$ETH - 12 months validity</span></h4>
                                        </div>
                                    </div>
                                    <ul className="list-unstyled fs-sm pb-md-3 mb-3">
                                        <li className="d-flex text-light mb-2">
                                            <i className="bx bx-check fs-xl me-1"></i>
                                            <span className="opacity-70">WhiteList Giveaways</span>
                                        </li>
                                        <li className="d-flex text-light mb-2">
                                            <i className="bx bx-check fs-xl me-1"></i>
                                            <span className="opacity-70">WhiteList Management</span>
                                        </li>
                                        <li className="d-flex text-light mb-2">
                                            <i className="bx bx-check fs-xl me-1"></i>
                                            <span className="opacity-70">Premium pages</span>
                                        </li>
                                        <li className="d-flex text-light mb-2">
                                            <i className="bx bx-check fs-xl me-1"></i>
                                            Token Giveaways - <b>No tech tax</b>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-footer border-0 pt-0 pb-4">
                                    {waitingTx ? (<div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Waiting transaction...</span>
                                    </div>) : (
                                        <button onClick={(e) => { e.preventDefault(); writeGold?.(); }} disabled={!writeGold || isLoadingSilver || isLoadingGold} className="btn btn-light w-100">
                                            {isLoadingGold || isLoadingSilver ? 'Minting ...' : 'Mint Gold Pass'}
                                        </button>
                                    )}
                                    <p className="fs-sm text-light ">
                                        You have {nbGold} of them. {nbGoldValid} activated.
                                    </p>
                                    {isSuccessGold && (
                                        <div className="alert alert-success">
                                            Successfully minted your Pass!
                                            <div>
                                                <a href={`https://etherscan.io/tx/${dataGold?.hash}`}>Etherscan</a>
                                            </div>
                                        </div>
                                    )}
                                    {(isPrepareErrorSilver || isErrorSilver) && (
                                        <div className="alert alert-danger">Error: Maybe not enough funds or wallet not initialized</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3>Sponsored ?</h3>
                    <p className="text-muted fs-sm">If your were contacted by our business developpers, please fill in the sponsor code below and <b>get 0.05 $ETH discount</b></p>

                    <div className="row flex-nowrap pb-4">
                        <div className="col">
                            <div className="card h-100 border-0 shadow-sm p-xxl-3" style={{ minWidth: '18rem' }}>
                                <div className="card-body">
                                    <div className="ps-4 mb-3">
                                        <h3 className="fs-lg fw-normal text-body mb-2">Silver</h3>
                                        <h4 className="h3 lh-1 mb-0">0.3 <span className="text-body fs-sm fw-normal">$ETH - 3 months validity</span></h4>
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" className="form-control" placeholder="sponsor code" onChange={(e) => { setSponsorCode(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="card-footer border-0 pt-0 pb-4">
                                    {waitingTx ? (<div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Waiting transaction...</span>
                                    </div>) : (
                                        <button onClick={(e) => { e.preventDefault(); writeSilver?.(); }} disabled={!writeSilver || isLoadingSilver || isLoadingGold} className="btn btn-outline-primary w-100">
                                            {isLoadingGold || isLoadingSilver ? 'Minting ...' : 'Mint Silver Pass'}
                                        </button>
                                    )}
                                    {isSuccessSilver && (
                                        <div className="alert alert-success">
                                            Successfully minted your Pass!
                                            <div>
                                                <a href={`https://etherscan.io/tx/${dataSilver?.hash}`}>Etherscan</a>
                                            </div>
                                        </div>
                                    )}
                                    {(isPrepareErrorSilver || isErrorSilver) && (
                                        <div className="alert alert-danger">Error: Maybe not enough funds or wallet not initialized</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 border-0 bg-primary shadow-primary shadow-dark-mode-none p-xxl-3" style={{ minWidth: '18rem' }}>
                                <div className="card-body">
                                    <div className="ps-4 mb-3">
                                        <h3 className="fs-lg fw-normal text-light opacity-70 mb-2">Gold</h3>
                                        <h4 className="h3 text-light lh-1 mb-0">0.9 <span className="fs-sm fw-normal opacity-70">$ETH - 12 months validity</span></h4>
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" className="form-control" placeholder="sponsor code" onChange={(e) => { setSponsorCode(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="card-footer border-0 pt-0 pb-4">
                                    {waitingTx ? (<div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Waiting transaction...</span>
                                    </div>) : (
                                        <button onClick={(e) => { e.preventDefault(); writeGold?.(); }} disabled={!writeGold || isLoadingSilver || isLoadingGold} className="btn btn-light w-100">
                                            {isLoadingGold || isLoadingSilver ? 'Minting ...' : 'Mint Gold Pass'}
                                        </button>
                                    )}
                                    {isSuccessGold && (
                                        <div className="alert alert-success">
                                            Successfully minted your Pass!
                                            <div>
                                                <a href={`https://etherscan.io/tx/${dataGold?.hash}`}>Etherscan</a>
                                            </div>
                                        </div>
                                    )}
                                    {(isPrepareErrorSilver || isErrorSilver) && (
                                        <div className="alert alert-danger">Error: Maybe not enough funds or wallet not initialized</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </>);
}

export default MyPassPage;
