
// import './TokensPanel.css';

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"

import { saveAs } from "file-saver";

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
import RaffleSummary from "./sub/RaffleSummary";

const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()

function RaffleDetailsPage(props) {

    const [details, setDetails] = useState(null)
    const [showShare, setShowShare] = useState(false)
    const [user, setUser] = useState(null)
    const [shareWith, setShareWith] = useState('')
    const params = useParams()
    console.log("Props :", props, params)
    const raffleId = params.raffleType + params.raffleId

    useEffect(() => {
        async function getUser() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}`
            })
            let user = requestObj.data

            setUser(user)
        }
        console.log("Effect : user = ", user)
        if (!user && props.currentUser.twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, props.currentUser.twitter])

    const fetchRaffle = async () => {

        try {
            const response = await axios.get(`${baseURL}/c/${params.raffleType}/${params.raffleId}/details`)
            console.log("Response raffle details :", response)
            setDetails(response.data)

        } catch (err) {
            //
            console.log("Err get raffle:", err)
        }
    }

    useEffect(() => {

        if (details === null) {
            fetchRaffle()
        }
    })

    const handleDownloadWinners = async (raffle) => {
        // 
        try {
            let csv;
            if (params.raffleType === 'l') {

                csv = await axios.get(`${baseURL}/lightraffles/${params.raffleId}/winners`)
            } else {
                csv = await axios.get(`${baseURL}/collabs/${params.raffleId}/winners`)
            }

            console.log("Response raffle :", csv)
            const blob = new Blob([csv.data], { type: "text/csv;charset=utf-8" });
            saveAs(blob, 'Raffle_winners.csv');

        } catch (err) {
            //
            console.log("Err get light raffles' winners :", err)
        }
    }


    const handleShareWith = async () => {
        // 
        try {

            await axios({
                url: `${baseURL}/lightraffles/${params.raffleId}`,
                method: 'PATCH',
                data: {
                    sharedWith: shareWith,
                }
            })
            setShowShare(false)
            fetchRaffle()

        } catch (err) {
            //
            console.log("Err get light raffles' winners :", err)
        }
    }

    if (!details) {
        return (<>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </>)
    }

    return (<>
        <h1>Raffle details</h1>

        {details.raffle.type === 'l' ? (
            <div className="row">
                <div className="col-md-2">
                    {details && details.raffle?.sharedWith ? (<button className="btn btn-sm btn-outline-warning">Shared with {details.raffle?.sharedWith}</button>) : ((details.raffle?.sharedWith !== props.currentUser.twitter) ? (<button className="btn btn-sm btn-link" onClick={() => { setShowShare(true) }}><i className="bx bx-share-alt"></i> share</button >) : null)}
                </div>
                <div className="col-md-10">
                    {details && details.raffle?.sharedWith ? (<span className="text-muted fs-xs">Share this page URL, free account needed</span>) : null}
                    {showShare ? (<div class="input-group">
                        <input class="form-control" type="text" placeholder="Twitter username" value={shareWith} onChange={(e) => { setShareWith(e.target.value) }} />
                        <button class="btn btn-primary" type="button" onClick={() => { handleShareWith() }}>Share</button>
                    </div>) : null}
                </div>
            </div>
        ) : null}

        <RaffleSummary raffleStats={details.stats} />

        {
            details.stats.nbWinners > 0 ? <>
                <hr />
                <fieldset className="m-2 p-2">
                    <legend>Winners</legend>

                    <ol className="list-group list-group-numbered">
                        {details.stats.winners.map(w => (<li className="list-group-item">{w}</li>))}
                    </ol>
                    <button className="btn btn-success btn-sm" onClick={() => { handleDownloadWinners(details.stats) }}><i className="bx bx-cloud-download"></i> Download winners</button>
                </fieldset>
            </> : null
        }

        <hr />

        {/* <fieldset className="m-2 p-2">
            <legend>Participants Facts & Figures</legend>

            <div className="row">
                <div className="col-4">
                    <div className="list-group-flush">
                        <div className="list-group-item list-group-item-action">
                            <span className="bx bx-user" alt="Participants nb"></span> 1
                        </div>
                        <div className="list-group-item list-group-item-action">
                            <span className="bx bx-user" alt="Participants nb"></span> 1
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="list-group-flush">
                        <div className="list-group-item list-group-item-action">
                            <span className="bx bx-trophy" alt="Participants nb"></span> 2
                        </div>
                        <div className="list-group-item list-group-item-action">
                            <span className="bx bx-trophy" alt="Participants nb"></span> 2
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="list-group-flush">
                        <div className="list-group-item list-group-item-action">
                            <span className="bx bx-time" alt="End date"></span> 2
                        </div>
                        <div className="list-group-item list-group-item-action">
                            <span className="bx bx-time" alt="End date"></span> 2
                        </div>
                    </div>
                </div>
            </div>


            Moyenne du nombre de follower par participant.
            Moyenne de l'age de membership sur serveur discord (si condition discord)
            Moyenne de la balance sur les wallet (si condition wallet)

        </fieldset> */}

        <fieldset>
            <legend>Raffle Participants</legend>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th><i className="bx bxl-twitter" style={{ color: '#1D9BF0' }}></i> user</th>
                            <th><i className="bx bxl-discord-alt" style={{ color: '#5865F2' }}></i> user</th>
                            <th><i className="bx bxl-discord-alt" style={{ color: '#5865F2' }}></i> Membership duration</th>
                            <th className="text-center"><i className="bx bxl-discord-alt" style={{ color: '#5865F2' }}></i> premium</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.participants.map(p => {

                            const discordDetails = p.additionalInformations ? JSON.parse(p.additionalInformations) : { discord: { roles: [] } }
                            return (
                                <tr>
                                    <td><a href={`https://twitter.com/${p.participantTwitter}`} target="_blank">{p.participantTwitter}</a></td>
                                    <td>{p.participantDiscord}</td>
                                    <td>{discordDetails.discord?.servers?.find(s => s.serverId === details?.project?.discordId) ? discordDetails.discord?.servers?.find(s => s.serverId === details?.project?.discordId).membershipDurationDays?.toFixed(0) : '-'}</td>
                                    <td className="text-center">
                                        {
                                            discordDetails.discord.premium ?
                                                (<i className="bx bx-check" style={{ color: 'green' }}></i>) :
                                                (<i className="bx bx-x" style={{ color: 'red' }}></i>)
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </fieldset>

    </>);
}

export default RaffleDetailsPage;
