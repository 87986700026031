
// import './TokensPanel.css';

import { useState, useEffect } from "react";

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


function SubscribePlanPage(props) {


    return (<>
        <h1>Subscribe Pro Plan</h1>

        <div className="alert alert-primary mb-0" role="alert">
            Free for now
        </div>

        <div>
            <h3>Available seats</h3>
            <p>3/4</p>
            <ol>
                <li>Attributed to project <i>UtilityFactory</i></li>
            </ol>

            <p>
                Attribute a seat to :
                <select className="form-control">
                    <option>Project 1</option>
                    <option>Project 2</option>
                </select>
                <button className="btn btn-success">Attribute</button>
            </p>
            <p className="text-muted">A seat attribution cannot be reverted until 1 month.</p>
        </div>

        {/* <div class="table-responsive-lg">
            <div class="row flex-nowrap pb-4">
                <div class="col">
                    <div class="card h-100 border-0 shadow-sm p-xxl-3" style={{ minWidth: '18rem' }}>
                        <div class="card-body">
                            <div class="d-flex align-items-center pb-2 pb-md-3 mb-4">
                                <div class="flex-shrink-0 bg-secondary rounded-3">
                                    <img src="assets/img/landing/saas-1/pricing/basic.png" width="84" alt="Icon" />
                                </div>
                                <div class="ps-4">
                                    <h3 class="fs-lg fw-normal text-body mb-2">Basic</h3>
                                    <h4 class="h3 lh-1 mb-0">$5.00 <span class="text-body fs-sm fw-normal">/ per month</span></h4>
                                </div>
                            </div>
                            <ul class="list-unstyled fs-sm pb-md-3 mb-3">
                                <li class="d-flex mb-2">
                                    <i class="bx bx-check fs-xl text-primary me-1"></i>
                                    Aenean neque tortor, purus faucibus
                                </li>
                                <li class="d-flex mb-2">
                                    <i class="bx bx-check fs-xl text-primary me-1"></i>
                                    Nullam augue vitae et volutpat sagittis
                                </li>
                                <li class="d-flex text-muted mb-2">
                                    <i class="bx bx-x fs-xl me-1"></i>
                                    Mauris massa penatibus enim elit quam
                                </li>
                                <li class="d-flex text-muted mb-2">
                                    <i class="bx bx-x fs-xl me-1"></i>
                                    Nec ac sagittis nunc bibendum
                                </li>
                                <li class="d-flex text-muted">
                                    <i class="bx bx-x fs-xl me-1"></i>
                                    Odio ut orci volutpat ultricies eleifend
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer border-0 pt-0 pb-4">
                            <a href="#" class="btn btn-outline-primary w-100">Start free trial</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100 border-0 bg-primary shadow-primary shadow-dark-mode-none p-xxl-3" style={{ minWidth: '18rem' }}>
                        <div class="card-body">
                            <div class="d-flex align-items-center pb-2 pb-md-3 mb-4">
                                <div class="flex-shrink-0 rounded-3" style={{ backgroundColor: 'rgba(255,255,255, .1)' }}>
                                    <img src="assets/img/landing/saas-1/pricing/standard.png" width="84" alt="Icon" />
                                </div>
                                <div class="ps-4">
                                    <h3 class="fs-lg fw-normal text-light opacity-70 mb-2">Standard</h3>
                                    <h4 class="h3 text-light lh-1 mb-0">$10.00 <span class="fs-sm fw-normal opacity-70">/ per month</span></h4>
                                </div>
                            </div>
                            <ul class="list-unstyled fs-sm pb-md-3 mb-3">
                                <li class="d-flex text-light mb-2">
                                    <i class="bx bx-check fs-xl me-1"></i>
                                    <span class="opacity-70">Aenean neque tortor, purus faucibus</span>
                                </li>
                                <li class="d-flex text-light mb-2">
                                    <i class="bx bx-check fs-xl me-1"></i>
                                    <span class="opacity-70">Nullam augue vitae et volutpat sagittis</span>
                                </li>
                                <li class="d-flex text-light mb-2">
                                    <i class="bx bx-check fs-xl me-1"></i>
                                    <span class="opacity-70">Mauris massa penatibus enim elit quam</span>
                                </li>
                                <li class="d-flex text-light mb-2">
                                    <i class="bx bx-check fs-xl me-1"></i>
                                    <span class="opacity-70">Nec ac sagittis nunc bibendum</span>
                                </li>
                                <li class="d-flex text-light opacity-50">
                                    <i class="bx bx-x fs-xl me-1"></i>
                                    Odio ut orci volutpat ultricies eleifend
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer border-0 pt-0 pb-4">
                            <a href="#" class="btn btn-light w-100">Start free trial</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100 border-0 shadow-sm p-xxl-3" style={{ minWidth: '18rem' }}>
                        <div class="card-body">
                            <div class="d-flex align-items-center pb-2 pb-md-3 mb-4">
                                <div class="flex-shrink-0 bg-secondary rounded-3">
                                    <img src="assets/img/landing/saas-1/pricing/ultimate.png" width="84" alt="Icon" />
                                </div>
                                <div class="ps-4">
                                    <h3 class="fs-lg fw-normal text-body mb-2">Ultimate</h3>
                                    <h4 class="h3 lh-1 mb-0">$15.00 <span class="text-body fs-sm fw-normal">/ per month</span></h4>
                                </div>
                            </div>
                            <ul class="list-unstyled fs-sm pb-md-3 mb-3">
                                <li class="d-flex mb-2">
                                    <i class="bx bx-check fs-xl text-primary me-1"></i>
                                    Aenean neque tortor, purus faucibus
                                </li>
                                <li class="d-flex mb-2">
                                    <i class="bx bx-check fs-xl text-primary me-1"></i>
                                    Nullam augue vitae et volutpat sagittis
                                </li>
                                <li class="d-flex mb-2">
                                    <i class="bx bx-check fs-xl text-primary me-1"></i>
                                    Mauris massa penatibus enim elit quam
                                </li>
                                <li class="d-flex mb-2">
                                    <i class="bx bx-check fs-xl text-primary me-1"></i>
                                    Nec ac sagittis nunc bibendum
                                </li>
                                <li class="d-flex">
                                    <i class="bx bx-check fs-xl text-primary me-1"></i>
                                    Odio ut orci volutpat ultricies eleifend
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer border-0 pt-0 pb-4">
                            <a href="#" class="btn btn-outline-primary w-100">Start free trial</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

    </>);
}

export default SubscribePlanPage;
