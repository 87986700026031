import React, { useEffect, useState } from 'react'

import { WagmiConfig, createClient, configureChains } from 'wagmi'
import { mainnet, arbitrum, polygon, goerli, arbitrumGoerli, polygonMumbai } from 'wagmi/chains'

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { SafeConnector } from 'wagmi/connectors/safe'



import {
    Routes,
    NavLink,
    Route
} from "react-router-dom";

import axiosObj from 'axios'
import { getSignedNonce } from './utils/web3'

import './App.css';

import DashboardPage from './components/DashboardPage'
import Profile from './components/Profile'
import MyPassPage from './components/MyPassPage'
import LightRafflesPage from './components/LightRafflesPage'
import RaffleDetailsPage from './components/RaffleDetailsPage'
import LeaderboardsPage from './components/LeaderboardsPage'
import MarketplacePage from './components/MarketplacePage'
import RoadmapPage from './components/RoadmapPage'
import SupportPage from './components/SupportPage'
import CollabDiffuserRafflePage from './components/CollabDiffuserRafflesPage'
import CollabOwnerRafflesPage from './components/CollabOwnerRafflesPage'
import CollabPrizesPage from './components/CollabPrizesPage'
import CollabListPage from './components/CollabListPage'
import Home from './Home'
import MyAccountPage from './components/MyAccountPage'

import { getBaseURL } from './utils/constants'
const baseURL = getBaseURL()

const axios = axiosObj.create({
    withCredentials: true
})
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = `https://api.${window.location.host}/auth/twitter`
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

console.log("HOSTNAME = ", window.location.hostname)

const { chains, provider, webSocketProvider } = configureChains(
    (window.location.hostname !== 'localhost') ? [mainnet, arbitrum, polygon] : [goerli, arbitrumGoerli, polygonMumbai],
    [alchemyProvider({ apiKey: 'P3JiHa75nOQ_k7KY7Z8aDXcxXBmjDhH7', priority: 0 }), publicProvider({ priority: 1 })],
)

const client = createClient({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
            chains,
            options: {
                appName: 'UtilityFactory',
            },
        }),
        new WalletConnectConnector({
            chains,
            options: {
                qrcode: true,
            },
        }),
        //   new InjectedConnector({
        //     chains,
        //     options: {
        //       name: 'Injected',
        //       shimDisconnect: true,
        //     },
        //   }),
    ],
    provider,
    webSocketProvider,
})

function App() {

    const [connectedAccount, setConnectedAccount] = useState('')
    const [currentUser, setCurrentUser] = useState({})
    const [nbAttributed, setNbAttributed] = useState(false)

    const {
        email,
        twitter,
        website,
        passHolderWallet,
        ownerTwitter,
        ownerWebsite,
    } = currentUser


    // const getSignatureWagmi = async (wallet, forceSignature = false) => {

    //     const urlNonce = `${baseURL}/users/${twitter}/nonce`

    //     const response = await axios({
    //         url: urlNonce,
    //         method: 'GET',
    //     })

    //     console.log("Response nonce :", response.data)

    //     signMessage({ message: response.data })

    // }

    const getSignature = async (wallet, forceSignature = false) => {
        const s = localStorage.getItem('signature')
        const sDate = localStorage.getItem('signatureMaxDate')

        console.log("Get signa :", s, sDate, localStorage)

        // if (forceSignature || !localStorage.getItem('signature') || !localStorage.getItem('signatureMaxDate') || (parseInt(localStorage.getItem('signatureMaxDate')) > 0 && parseInt(localStorage.getItem('signatureMaxDate')) < (new Date()).getTime())) {
        // Let's update signature :
        const res = await getSignedNonce('', currentUser.twitter, wallet)

        // console.log("Res getSignedNonce :", res)
        // let signatureMaxDate = new Date()
        // signatureMaxDate = signatureMaxDate.setSeconds(signatureMaxDate.getSeconds() + 20)
        // try {
        //   // localStorage.setItem('signature', res)
        //   // localStorage.setItem('signatureMaxDate', '' + signatureMaxDate)
        // } catch (err) {
        //   console.log("Err localStorage :", err)
        // }

        return res
        // }

    }


    useEffect(() => {

        if (!currentUser.twitter) {
            (async () => {
                const response = await axios.get(`${baseURL}/auth/isLogged`)
                if (response.data.logged) {
                    setCurrentUser({
                        ...response.data.logged,
                        passType: response.data.passType
                    })
                } else {
                    // Not logged ...
                }
            })()
        }

        if (nbAttributed === false && twitter) {

            (async () => {
                const response = await axios.get(`${baseURL}/collabs/nbattributed`)
                if (response.data) {
                    setNbAttributed(response.data)
                } else {
                    // Not logged ...
                }
            })()
        }
    })


    const updateUser = async (signingWallet, objToUpdate, setShowToastSaved, setShowToastErrorSaved, forceSignature = false) => {
        //
        try {


            console.log("Signing wallet :", signingWallet)

            // if (signingWallet || forceSignature) {
            //     signature = await getSignature(signingWallet, forceSignature)
            //     // signature = await getSignatureWagmi(signingWallet, forceSignature)
            // }

            await axios.patch(`${baseURL}/users/${currentUser.twitter}`, {
                ...objToUpdate,
                signingWallet,
                twitter,
            })

            setShowToastSaved(true)


        } catch (err) {

            console.log("Err :", err, err.response.data.error)
            setShowToastErrorSaved(err.response.data.error ? err.response.data.error : 'An error occured while saving your modifications. Alos ... check metamask auth & network.')
        }
    }

    let currentRoute = window.location.pathname
    // console.log("Current route :", currentRoute)
    // console.log("Current user :", currentUser)

    // console.log("Current user :", Object.keys(currentUser).length > 0, ", email: ", currentUser?.email, ", route :", currentRoute, Object.keys(currentUser).length > 0 && !currentUser.email && currentRoute !== '/my-account')
    if (Object.keys(currentUser).length > 0 && !currentUser.email && currentRoute !== '/my-account') {
        // currentRoute = '/my-account'
        window.location.pathname = '/my-account'
        // console.log("======================= REDIRECT =======================")
    }



    const renderSwitch = () => {

        // console.log("Render switch :", currentRoute, currentUser)

        switch (true) {
            case /\/dashboard/.test(currentRoute):
                return (<DashboardPage setCurrentUser={setCurrentUser} currentUser={currentUser} updateUser={updateUser} />)
            case /\/my-account/.test(currentRoute):
                return (<Profile setCurrentUser={setCurrentUser} currentUser={currentUser} updateUser={updateUser} />)
            case /\/mypass/.test(currentRoute):
                return (<MyPassPage currentUser={currentUser} />)
            case /\/raffle\/\w+\/\d+/.test(currentRoute):
                return (<RaffleDetailsPage currentUser={currentUser} />)
            case /\/light-raffles/.test(currentRoute):
                return (<LightRafflesPage currentUser={currentUser} />)
            case /\/leaderboards/.test(currentRoute):
                return (<LeaderboardsPage currentUser={currentUser} />)
            case /\/marketplace/.test(currentRoute):
                return (<MarketplacePage currentUser={currentUser} />)
            case /\/roadmap/.test(currentRoute):
                return (<RoadmapPage currentUser={currentUser} />)
            case /\/support/.test(currentRoute):
                return (<SupportPage currentUser={currentUser} />)
            case /\/collab-offered/.test(currentRoute):
                return (<CollabDiffuserRafflePage currentUser={currentUser} />)
            case /\/collab-management/.test(currentRoute):
                return (<CollabOwnerRafflesPage currentUser={currentUser} />)
            case /\/collab-list/.test(currentRoute):
                return (<CollabListPage currentUser={currentUser} />)
            case /\/collab-prizes/.test(currentRoute):
                return (<CollabPrizesPage currentUser={currentUser} />)
            default:
                return false
        }
    }

    console.log("Base URL :", baseURL)

    const elemToRender = renderSwitch()

    if (elemToRender === false) {

        return (<Home />)
    }

    return (<WagmiConfig client={client}>
        <header className="header navbar navbar-expand-lg bg-light border-bottom border-light shadow-sm fixed-top ">
            <div className="container px-3">
                <a href="index.html" className="navbar-brand pe-3">
                    <img src="/assets/img/logo.png" width="47" alt="UtilityFactory" />
                    <span style={{ fontFamily: "'Quattrocento', serif" }}>Utility Factory</span>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse1" aria-expanded="false">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <nav id="navbarCollapse1" className="collapse navbar-collapse">
                    <hr className="d-lg-none mt-3 mb-2" />
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <a href="/dashboard" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="/roadmap" className="nav-link">Roadmap</a>
                        </li>
                    </ul>
                </nav>
                <div id="navbarNav" className="offcanvas offcanvas-end">


                    {/* <div className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4" data-bs-toggle="mode">
            <input type="checkbox" className="form-check-input" id="theme-mode" />
            <label className="form-check-label d-none d-sm-block" for="theme-mode">Light</label>
            <label className="form-check-label d-none d-sm-block" for="theme-mode">Dark</label>
          </div> */}
                    <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </div>
        </header>

        <section className="container pt-5">
            <div className="row">
                <aside className="bd-sidebar col-lg-3 col-md-4 border-end pb-5 mt-n5" style={{ overflowY: 'auto' }}>
                    <div className="position-sticky top-0">
                        <div className="text-center pt-5">

                            {currentUser && currentUser.twitter ? (<>
                                <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                                    <img src={currentUser.image} className="d-block rounded-circle" width="120" alt="Current user" />
                                </div>
                                <h2 className="h5 mb-1"><span className="bx bxl-twitter"></span> {currentUser.name}</h2>
                                {currentUser.role ? (<p className="mb-1 pb-1">
                                    <span className="badge text-bg-dark">{currentUser.role}</span>
                                </p>) : null}
                                <p className="mb-2 pb-2">
                                    {currentUser.discordUsername ? (<><span className="bx bxl-discord"></span>{currentUser.discordUsername} on {currentUser.discordServerName}<br /></>) : null}
                                    {currentUser.email}
                                </p>
                                <button type="button" className="btn btn-secondary w-100 d-md-none mt-n2 mb-3" data-bs-toggle="collapse" data-bs-target="#account-menu">
                                    <i className="bx bxs-user-detail fs-xl me-2"></i>
                                    Account menu
                                    <i className="bx bx-chevron-down fs-lg ms-1"></i>
                                </button>
                                <div id="account-menu" className="list-group list-group-flush collapse d-md-block">
                                    <NavLink to="/dashboard" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-home fs-xl opacity-60 me-2"></i>
                                        Home
                                    </NavLink>
                                    <NavLink to="/my-account" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-user fs-xl opacity-60 me-2"></i>
                                        My account
                                    </NavLink>
                                    <NavLink to="/light-raffles" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-timer fs-xl opacity-60 me-2"></i>
                                        Giveaways
                                    </NavLink>
                                    <NavLink to="/leaderboards" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-trophy fs-xl opacity-60 me-2"></i>
                                        Leaderboards
                                    </NavLink>
                                    <NavLink to="/collab-offered" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bxs-inbox fs-xl opacity-60 me-2"></i>
                                        Collabs received
                                        {(nbAttributed) ? (<>&nbsp;<span className="badge ms-2 bg-danger rounded-pill">{nbAttributed}</span></>) : null}
                                    </NavLink>


                                    <br />
                                    <h6>Pro features</h6>
                                    <hr />

                                    <NavLink to="/collab-list" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-table fs-xl opacity-60 me-2"></i>
                                        Collab List
                                    </NavLink>
                                    <NavLink to="/collab-prizes" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-gift fs-xl opacity-60 me-2"></i>
                                        Add Offers
                                    </NavLink>
                                    <NavLink to="/collab-management" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-rocket fs-xl opacity-60 me-2"></i>
                                        Send Collab
                                    </NavLink>

                                    <hr />
                                    <NavLink to="/marketplace" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-store fs-xl opacity-60 me-2"></i>
                                        Marketplace
                                        {currentUser.passType === '' ? (<i className="bx bx-lock-alt fs-xl opacity-60 me-2"></i>) : null}
                                    </NavLink>
                                    <NavLink to="/roadmap" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-time fs-xl opacity-60 me-2"></i>
                                        Roadmap
                                    </NavLink>
                                    <NavLink to="/support" className={"list-group-item list-group-item-action d-flex align-items-center "}>
                                        <i className="bx bx-help-circle fs-xl opacity-60 me-2"></i>
                                        Support
                                    </NavLink>
                                    <NavLink to={baseURL.indexOf('localhost') > -1 ? "http://localhost:3000/auth/logout" : "https://api.utilityfactory.xyz/auth/logout"} className={"list-group-item list-group-item-action d-flex align-items-center"}>
                                        <i className="bx bx-log-out fs-xl opacity-60 me-2"></i>
                                        Sign Out
                                    </NavLink>
                                </div>
                            </>) : (<>
                                <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                                    <a type="button" className="btn btn-primary" href={`${baseURL}/auth/twitter`}>
                                        Connect with Twitter
                                    </a>
                                </div>
                            </>)}
                        </div>
                    </div>
                </aside>
                <div className="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0" >
                    <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
                        <Routes>
                            <Route path="/dashboard" element={<DashboardPage setCurrentUser={setCurrentUser} currentUser={currentUser} updateUser={updateUser} />} />
                            <Route path="/my-account" element={<MyAccountPage getSignature={getSignature} setCurrentUser={setCurrentUser} currentUser={currentUser} updateUser={updateUser} />} />
                            <Route path="/mypass" element={<MyPassPage currentUser={currentUser} />} />
                            <Route path="/light-raffles" element={<LightRafflesPage currentUser={currentUser} />} />
                            <Route path="/raffle/:raffleType/:raffleId" element={<RaffleDetailsPage currentUser={currentUser} />} />
                            <Route path="/leaderboards" element={<LeaderboardsPage currentUser={currentUser} />} />
                            <Route path="/marketplace" element={<MarketplacePage currentUser={currentUser} />} />
                            <Route path="/roadmap" element={<RoadmapPage currentUser={currentUser} />} />
                            <Route path="/support" element={<SupportPage currentUser={currentUser} />} />
                            <Route path="/collab-list" element={<CollabListPage currentUser={currentUser} />} />
                            <Route path="/collab-prizes" element={<CollabPrizesPage currentUser={currentUser} />} />
                            <Route path="/collab-offered" element={<CollabDiffuserRafflePage currentUser={currentUser} />} />
                            <Route path="/collab-management" element={<CollabOwnerRafflesPage currentUser={currentUser} />} />
                        </Routes>
                    </div>
                </div>

            </div>
        </section>
    </WagmiConfig>);
}

export default App;
