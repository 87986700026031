
// import './TokensPanel.css';

import { useState, useEffect } from "react";

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


function LeaderboardsPage(props) {

    const [arrOwners, setArrOwners] = useState(null)
    const [arrDiffuser, setArrDiffusers] = useState(null)


    const fetchOwners = async () => {

        try {
            const response = await axios.get(`${baseURL}/leaderboards/owners`)
            console.log("Response :", response)
            setArrOwners(response.data)

        } catch (err) {
            //
        }
    }

    const fetchDiffusers = async () => {

        try {
            const response = await axios.get(`${baseURL}/leaderboards/diffusers`)
            console.log("Response :", response)
            setArrDiffusers(response.data)

        } catch (err) {
            //
        }
    }

    useEffect(() => {
        if (arrOwners === null) {
            fetchOwners()
        } else if (arrDiffuser === null) {
            fetchDiffusers()
        }
    })

    return (<>
        <h1>Leaderboards</h1>

        <p className="text-muted fs-sm">
            Following users are the best projects organizing raffles
        </p>
        <div className="row">
            <div className="col-xl-9 col-lg-8">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Twitter</th>
                            <th scope="col">Followers</th>
                            <th scope="col">Nb raffles</th>
                            <th scope="col">Links</th>
                        </tr>
                    </thead>
                    <tbody>
                        {arrOwners && arrOwners.map((u, idx) => (
                            <tr>
                                <th scope="row">{idx + 1}</th>
                                <td>{u.twitter}</td>
                                <td>{u.followers}</td>
                                <td>{u.nbRaffles}</td>
                                <td><a href={`https://twitter.com/${u.twitter}`} target="_blank" rel="noreferrer" className="btn btn-outline-primary"><span className="bx bxl-twitter" /> {u.twitter}</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>


            </div>
        </div>

    </>);
}

export default LeaderboardsPage;
