
// import './TokensPanel.css';

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"

import { getBaseURL } from '../utils/constants'
import axiosObj from 'axios'
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()


function RaffleChecker(props) {

    const [seeMore, setSeeMore] = useState(false)
    const [raffle, setRaffle] = useState(null)
    const [participation, setParticipation] = useState(null)
    const params = useParams()
    console.log("Props :", props, params)

    // Get Raffle infos :
    let diffuserId;
    let diffuserImage;
    let raffleBannerImage;
    let diffuserFollowers;
    let diffuserWebsite;
    let title;
    let nbParticipants;
    let minParticipants;
    let conditionResultTrue;
    let conditionResultFalse;
    let winner;
    let description;
    let project;

    let hasWalletConditions = false
    let hasDiscordConditions = false

    if (raffle) {

        console.log("Raffle :", raffle)

        diffuserId = raffle.userInfos.twitter
        // diffuserName = raffle.userInfos.name
        diffuserImage = raffle.userInfos.image
        diffuserFollowers = raffle.userInfos.followers
        diffuserWebsite = raffle.userInfos.website

        title = raffle.raffles[0].name
        description = raffle.raffles[0].description
        winner = raffle.raffles[0].winnerStr

        project = raffle.raffles[0].project

        // raffleImage = raffle.raffles[0].image
        raffleBannerImage = raffle.raffles[0].bannerImage

        minParticipants = raffle.raffles[0].minParticipants
        nbParticipants = raffle.raffles[0].nbParticipations

        let allConditions = (raffle.raffles[0].ownerMandatoryConditions ? raffle.raffles[0].ownerMandatoryConditions : '') + ',' + (raffle.raffles[0].diffuserMandatoryConditions ? raffle.raffles[0].diffuserMandatoryConditions : '')
        allConditions += ',' + (raffle.raffles[0].ownerOptionalCondition ? raffle.raffles[0].ownerOptionalCondition : '') + ',' + (raffle.raffles[0].diffuserOptionalCondition ? raffle.raffles[0].diffuserOptionalCondition : '')

        allConditions += ',' + (raffle.raffles[0].mandatoryConditions ? raffle.raffles[0].mandatoryConditions : '') + ',' + (raffle.raffles[0].optionalCondition ? raffle.raffles[0].optionalCondition : '')


        // Has conditions ?
        console.log("All conditions :", allConditions)
        hasDiscordConditions = allConditions.indexOf('Discord') > -1
        hasWalletConditions = allConditions.indexOf('MinimalBalance') > -1 || allConditions.indexOf('Token') > -1

        // hasDiscordConditions = true
        // hasWalletConditions = true
    }

    // Get raffle infos from params :
    // 
    const fetchRaffleInfos = async () => {
        try {
            const response = await axios.get(`${baseURL}/c/raffle/${params.type}/${params.raffleId}`)
            if (response.data) {
                setRaffle(response.data)
            } else {
                setRaffle({})
            }
        } catch (err) {
            //
            setRaffle({})
        }
    }

    const fetchParticipation = async () => {
        try {
            const response = await axios.get(`${baseURL}/c/participation/${params.participationId}/${params.type}/${params.raffleId}`)
            if (response.data) {
                setParticipation(response.data)
            } else {
                setParticipation({})
            }
        } catch (err) {
            //
            setParticipation({})
        }
    }

    useEffect(() => {
        if (raffle === null)
            fetchRaffleInfos()
        else if (params.participationId !== 'null' && participation === null)
            fetchParticipation()
    })

    let allConditionsChecked = false
    if (participation) {
        allConditionsChecked = true
        for (let conditionType in participation.conditionsChecked) {
            for (let condition of participation.conditionsChecked[conditionType]) {
                if (!condition.checked) {
                    allConditionsChecked = false
                }
            }
        }
    }

    if (!project) {
        return null
    }

    return (<div>
        <header className="py-1 bg-image-full"
            style={{ backgroundImage: `url("${project && project.banner ? project.banner : 'https://utilityfactory.xyz/assets/img/diffuser_header_picture.jpeg'}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}>
            <div className="text-center my-3">
                <img className="img-fluid rounded-circle mb-3" width="100" alt="Diffuser" src={project.image} />
                <h1 className="text-white fs-3 fw-bolder" style={{ backgroundColor: '#111111A5' }}>
                    {project.name}
                </h1>
                <p className="text-white-50 mb-0">
                    <i className="fab fa-twitter" style={{ color: 'rgb(29, 161, 242)', fontSize: '20px' }}></i>
                    <span style={{ backgroundColor: '#111111A5', verticalAlign: 'middle', fontSize: '16px', margin: '5px', padding: '5px', color: 'white' }}>
                        {project.website ? (<>
                            Website:
                            <a href={project.website} rel="noreferrer" target="_blank">
                                {project.website}
                            </a>
                        </>) : null}
                    </span>
                </p>
            </div>
        </header>
        <section className="py-3">
            <div className="container px-4 px-lg-5 my-5">
                {(!title) ? (<section id="diffuser-panel" className="py-4 py-xl-5">
                    <div className="container">
                        <div className="text-white bg-dark border rounded border-0 p-4 p-md-5">
                            <h2 className="fw-bold text-white mb-3">This raffle does not exist ;)</h2>
                            <p className="mb-4">Sorry but this is not a valid link.</p>
                        </div>
                    </div>
                </section>) :
                    (<div className="row gx-4 gx-lg-5">
                        {/* Left element: */}
                        <div className="col-md-6" style={{ padding: '20px' }}>
                            {params.participationId === 'null' ? (<div className="alert alert-danger">
                                An error occured. Maybe you connected to Twitter with another account.
                            </div>) : (<>

                                <fieldset className="m-3 p-2">
                                    <legend><span className="bx bxl-twitter" style={{ color: '#1DA1F2' }} />  Twitter conditions</legend>

                                    <p className="alert alert-info">
                                        <i className="bx bxs-info-circle"></i> Twitter conditions are checked at the end of the raffle. 
                                    </p>

                                    {participation ? (<blockquote>
                                        <ul class="list-group">
                                            {participation.conditionsChecked && participation.conditionsChecked.twitter && participation.conditionsChecked.twitter.map(c =>
                                            (<li class="list-group-item">
                                                <span dangerouslySetInnerHTML={{ __html: c.label }}></span>
                                            </li>))}
                                        </ul>
                                    </blockquote>) : null}

                                </fieldset>

                                {hasDiscordConditions ? (<fieldset className="m-3 p-2">
                                    <legend><span className="bx bxl-discord" style={{ color: '#7289da' }} /> Discord conditions</legend>

                                    <blockquote>
                                        <a className="btn btn-sm btn-primary shadow-primary btn-lg" disabled={participation && participation.twitter} href={`${baseURL}/check/discord?raffleType=${params.type}&participationId=${params.participationId}&twitter=${participation?.twitter}`}>Connect with Discord</a>
                                        {/* Check through Discord bot 🤖 */}
                                    </blockquote>

                                    {participation ? (<blockquote>
                                        <ul class="list-group list-group-flush">
                                            {participation.conditionsChecked && participation.conditionsChecked.discord && participation.conditionsChecked.discord.map(c => c.checked ?
                                                (<li class="list-group-item">
                                                    <button class="btn btn-outline-success" type="button">
                                                        <span dangerouslySetInnerHTML={{ __html: c.label }}></span>
                                                        <i className='bx bx-check-square' style={{ color: 'green', fontSize: '1.2em' }} />
                                                    </button>
                                                </li>)
                                                :
                                                (<li class="list-group-item">
                                                    <button class="btn btn-outline-danger" type="button">
                                                        <span dangerouslySetInnerHTML={{ __html: c.label }}></span>
                                                        <i className='bx bx-block' style={{ color: 'red', fontSize: '1.2em' }} />
                                                    </button>
                                                </li>))}
                                        </ul>
                                    </blockquote>) : null}


                                </fieldset>) : null}

                                {hasWalletConditions ? (<fieldset className="m-3 p-2">
                                    <legend><span className="bx bxs-wallet" style={{ color: 'gray' }} /> Wallet condition</legend>

                                    {participation ? (<blockquote>
                                        <ul class="list-group list-group-flush">
                                            {participation.conditionsChecked && participation.conditionsChecked.wallet && participation.conditionsChecked.wallet.map(c => c.checked ?
                                                (<li class="list-group-item">
                                                    <button class="btn btn-outline-success" type="button">
                                                        <span dangerouslySetInnerHTML={{ __html: c.label }}></span>
                                                        <i className='bx bx-check-square' style={{ color: 'green', fontSize: '1.2em' }} />
                                                    </button>
                                                </li>)
                                                :
                                                (<li class="list-group-item">
                                                    <button class="btn btn-outline-danger" type="button">
                                                        <span dangerouslySetInnerHTML={{ __html: c.label }}></span>
                                                        <i className='bx bx-block' style={{ color: 'red', fontSize: '1.2em' }} />
                                                    </button>
                                                </li>))}
                                        </ul>
                                    </blockquote>) : null}

                                </fieldset>) : null}
                            </>)}
                        </div>

                        {/* Right element: */}

                        <div className="col-md-6" style={{ verticalAlign: 'top', padding: '20px', borderLeft: '1px gray dotted' }}>

                            <h3 className="display-5 fw-bolder">
                                {title}
                            </h3>
                            <p classNme="text-small text-muted"> ({nbParticipants} participants)</p>

                            <fieldset className="m-3 p-2">
                                <legend>Giveaway status</legend>

                                {(nbParticipants < minParticipants) ? (<>
                                    <span class="badge bg-warning rounded-pill">
                                        Raffle participation minimum not reached yet
                                        <i className='bx bxs-shield-x' style={{ color: 'white', fontSize: '1.2em' }} />
                                    </span>
                                </>) : (<>
                                    <span class="badge bg-success rounded-pill">
                                        Raffle validated 🚀
                                        <i className='bx bxs-check-shield' style={{ color: 'white', fontSize: '1.2em' }} />
                                    </span>
                                </>)}

                                {(!allConditionsChecked) ? (<>
                                    <span class="badge bg-warning rounded-pill">
                                        Participation not verified
                                        <i className='bx bxs-shield-x' style={{ color: 'white', fontSize: '1.2em' }} />
                                    </span>
                                </>) : (<>
                                    <span class="badge bg-success rounded-pill">
                                        Participatiion verified 🙌
                                        <i className='bx bxs-check-shield' style={{ color: 'white', fontSize: '1.2em' }} />
                                    </span>
                                    <p className="text-muted">Conditions are checked again at the end of the raffle if you are selected.</p>
                                </>)}

                            </fieldset>

                            {/* <fieldset className="m-3 p-2">
                                <legend>Participation status</legend>

                                <button class="btn btn-success shadow-success" type="button">
                                    Participation recorded
                                    <i className='bx bxs-check-shield' style={{ color: 'white', fontSize: '1.2em' }} />
                                </button>
                                <button class="btn btn-danger shadow-danger" type="button">
                                    Participation not recorded
                                    <i className='bx bxs-shield-x' style={{ color: 'white', fontSize: '1.2em' }} />
                                </button>

                                <button class="btn btn-success shadow-success" type="button">
                                    Conditions checked
                                    <i className='bx bxs-check-shield' style={{ color: 'white', fontSize: '1.2em' }} />
                                    <i className='bx bxs-shield-x' style={{ color: 'white', fontSize: '1.2em' }} />
                                </button>
                                <button class="btn btn-danger shadow-danger" type="button">
                                    Conditions not checked
                                    <i className='bx bxs-shield-x' style={{ color: 'white', fontSize: '1.2em' }} />
                                </button>
                            </fieldset> */}

                            <fieldset className="m-3 p-2">
                                <legend>Giveaway details</legend>

                                <div className="p-3 m-3" style={{ maxHeight: seeMore ? 'none' : '250px', overflowY: seeMore ? 'hidden' : 'auto', borderRadius: '7px', border: '1px #CCC solid' }} dangerouslySetInnerHTML={{ __html: description }}>
                                </div>
                                <p className="px-3 mx-3" style={{ cursor: 'pointer' }} onClick={() => { setSeeMore(!seeMore) }}>{seeMore ? 'see less' : 'see more'}</p>
                            </fieldset>

                        </div>
                    </div >)
                }
            </div >
        </section >
    </div >);
}

export default RaffleChecker;
