
// import './TokensPanel.css';
import { useEffect, useState } from "react";


import axiosObj from 'axios';
import { getBaseURL } from '../utils/constants';
const axios = axiosObj.create({
    withCredentials: true
})
const baseURL = getBaseURL()

function extractTwitterTweetURL(str) {
    // Example : "https://twitter.com/Tonton_Nft/status/1626914358310699010?s=fkjgh"
    const extract = str.match(/twitter.com\/.+\/status\/([0-9]+)[^0-9]?.*/i)
    if (extract && extract.length > 1) {
        return extract[1]
    }
    return str
}

function CollabListPage(props) {

    const [editElement, setEditElement] = useState(0)
    const [showAdd, setShowAdd] = useState(false)
    const [filter, setFilter] = useState('all')
    const [lists, setLists] = useState(null)
    const [newProject, setNewProject] = useState({ name: '', status: '' })
    const [projectId, setProjectId] = useState(0)
    const [user, setUser] = useState(null)

    const arrStatus = [
        { value: "new", label: "New" },
        { value: "waiting", label: "Waiting for answer" },
        { value: "no_contact", label: "No contact" },
        { value: "deal_accepted", label: "Deal accepted" },
        { value: "date_set", label: "Date set" },
        { value: "raffle_pending", label: "Raffle pending" },
        { value: "raffle_done", label: "Raffle done" },
        { value: "closed", label: "Closed" },
        { value: "deal_refused", label: "Deal refused" },
    ]
    const setNewProjectValue = (field, v) => {
        if (field === 'twitter') {
            v = extractTwitterTweetURL(v)
        }
        setNewProject({
            ...newProject,
            [field]: v,
        })
        console.log("New project : ", newProject)
    }

    useEffect(() => {
        async function getUser() {
            const requestObj = await axios({
                method: 'GET',
                url: `${baseURL}/users/${props.currentUser.twitter}`
            })
            let user = requestObj.data

            setUser(user)
            setProjectId(user.projects[0].id)
            setNewProjectValue('projectId', user.projects[0].id);
        }
        console.log("Effect : user = ", user)
        if (!user && props.currentUser.twitter) {
            console.log("User null ... on get")
            getUser()
        }
    }, [user, props.currentUser.twitter])

    const fetchLists = async () => {

        try {
            const response = await axios.get(`${baseURL}/lists`)
            console.log("Response :", response)
            setLists(response.data)

        } catch (err) {
            //
        }
    }

    useEffect(() => {
        if (lists === null) {
            fetchLists()
        }
    })

    const updateProject = async (id, field, v) => {
        // Update it now
        try {
            const response = await axios({
                url: `${baseURL}/lists/${id}`,
                method: 'PATCH',
                data: { [field]: v },
            })
            console.log("Response :", response)
            fetchLists()

            setNewProject({ name: '', status: '' })
            setShowAdd(false)

        } catch (err) {
            //
        }
    }

    const addProject = async () => {
        // Add it :
        try {
            const response = await axios({
                url: `${baseURL}/lists`,
                method: 'POST',
                data: {
                    ...newProject,
                    projectId: projectId,
                },
            })
            console.log("Response :", response)
            fetchLists()

            setNewProject({ name: '', status: '' })
            setShowAdd(false)

        } catch (err) {
            //
        }

    }

    const deleteProject = async (id) => {

        if (!window.confirm('Are you sure you want to delete this project ?')) return;

        try {
            const response = await axios({
                url: `${baseURL}/lists/${id}`,
                method: 'DELETE',
            })
            console.log("Response :", response)
            fetchLists()

        } catch (err) {
            //
        }

    }
    const createGAFromProject = async (listElement) => {

        // Create a new GA using informations about this project :
        // Name : project.name x listElement.name
        // Description ?
        // Conditions ? Initier en fonction des infos : Twitter ?

    }

    return (<>
        <h1>Collab Lists</h1>
        <h6 className="text-muted">Collaborative projects list - CRM <span className="badge bg-warning shadow-warning">beta</span></h6>

        <ul className="nav nav-tabs-alt" role="tablist">
            {user && user.projects.map(project => (
                <li className={"nav-item " + ((projectId === project.id) ? 'active' : '')}>
                    <a href={`#project${project.id}`} onClick={() => { setNewProjectValue('projectId', project.id); setProjectId(project.id) }} className={"nav-link " + ((projectId === project.id) ? 'active' : '')} data-bs-toggle="tab" role="tab">
                        <i className="bx bx-profile opacity-70 me-2"></i>
                        {project.name}
                    </a>
                </li>
            ))}
        </ul>

        <div className="tab-content">

            {user && user.projects.map((project) => (<>
                <div className={`tab-pane  ${projectId === project.id ? 'show active' : 'fade'}`} id={`project${project.id}`} role="tabpanel">

                    <p className="row text-center">
                        <div className="col-md-3 mx-3">
                            <button className="btn btn-success mx-2 btn-sm" onClick={() => { setShowAdd(true) }}><i className="bx bx-plus" /> Add a project</button>
                        </div>
                        <div className="col-md-3 mx-3">
                            <select style={{ fontSize: '0.7em' }} class="form-select" onChange={(e) => { setFilter(e.target.value) }}>
                                <option selected={filter === 'all'} value="all">All</option>
                                {arrStatus.map(s => (<option value={s.value}>{s.label}</option>))}
                            </select>
                        </div>
                    </p>

                    <div class="table-responsive" style={{ fontSize: '0.7em' }}>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Project name</th>
                                    <th>Twitter Followers</th>
                                    <th>Discord</th>
                                    <th>Status</th>
                                    <th>Attributed</th>
                                    <th>Proposition</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {showAdd ? (
                                    <tr>
                                        <th scope="row">
                                            <input style={{ fontSize: '0.9em' }} class="form-control" type="text" placeholder="Project's name" onChange={(e) => { setNewProjectValue('name', e.target.value) }} />
                                        </th>
                                        <td>
                                            <input style={{ fontSize: '0.9em' }} class="form-control" type="text" placeholder="Twitter URL" onChange={(e) => { setNewProjectValue('twitter', e.target.value) }} />
                                        </td>
                                        <td>
                                            <input style={{ fontSize: '0.9em' }} class="form-control" type="text" placeholder="Discord server" onChange={(e) => { setNewProjectValue('discord', e.target.value) }} />
                                        </td>
                                        <td>
                                            <select style={{ fontSize: '0.9em' }} class="form-select" onChange={(e) => { setNewProjectValue('status', e.target.value) }}>
                                                {arrStatus.map(s => (<option value={s.value}>{s.label}</option>))}
                                            </select>
                                        </td>
                                        <td>
                                            <select style={{ fontSize: '0.8em' }} className="form-select" onChange={(e) => { setNewProjectValue('attributed', e.target.value) }}>
                                                <option>Not attributed</option>
                                                {project.members.map(member => (<option>{member.twitter}</option>))}
                                            </select>
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <button className="btn btn-success btn-sm" onClick={() => { addProject() }}><i className="bx bx-plus"></i></button>
                                            <button className="btn btn-danger btn-sm" onClick={() => { setShowAdd(false) }}><i className="bx bx-x-circle"></i></button>
                                        </td>
                                    </tr>
                                ) : null}
                                {lists && lists.filter(p => p.projectId === project.id).filter(p => filter === 'all' || filter === p.status).map(listElement => editElement === listElement.id ? (<tr>
                                    <th scope="row">{listElement.name}</th>
                                    <td colSpan={5}>
                                        <div className="row">
                                            <div className="col-md-4">Status</div>
                                            <div className="col-md-4">
                                                <select style={{ fontSize: '0.8em' }} class="form-select" onChange={(e) => { updateProject(listElement.id, 'status', e.target.value) }}>
                                                    {arrStatus.map(s => (<option selected={listElement.status === s.value} value={s.value}>{s.label}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">Attributed</div>
                                            <div className="col-md-4">
                                                {/* Map on team members */}
                                                <select style={{ fontSize: '0.8em' }} className="form-select" onChange={(e) => { updateProject(listElement.id, 'attributed', e.target.value) }}>
                                                    <option>Not attributed</option>
                                                    {project.members.map(member => (<option>{member.twitter}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">WL to offer</div>
                                            <div className="col-md-4">
                                                <input style={{ fontSize: '0.8em' }} className="form-control" placeholder="#wl to offer" type="number" value={listElement.wlToOffer} onChange={(e) => { updateProject(listElement.id, 'wlToOffer', e.target.value) }} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">Tokens to offer</div>
                                            <div className="col-md-4">
                                                <input style={{ fontSize: '0.8em' }} className="form-control" placeholder="#tokens to offer" type="number" value={listElement.tokensToOffer} onChange={(e) => { updateProject(listElement.id, 'tokensToOffer', e.target.value) }} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">Informations</div>
                                            <div className="col-md-4">
                                                <textarea style={{ fontSize: '0.8em' }} className="form-control" placeholder="Other details" type="text" onChange={(e) => { updateProject(listElement.id, 'informations', e.target.value) }}>{listElement.informations}</textarea>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4"></div>
                                            <div className="col-md-4">
                                                <button className="btn btn-success btn-sm" onClick={() => { setEditElement(0) }}>Done</button>
                                            </div>
                                        </div>

                                    </td>
                                </tr>) : (
                                    <tr>
                                        <th scope="row">{listElement.name}</th>
                                        <td><a href={`https://twitter.com/${listElement.twitter}`} target="_blank">@{listElement.twitter}</a></td>
                                        <td>{listElement.discord}</td>
                                        <td>{listElement.status}</td>
                                        <td>{listElement.attributed}</td>
                                        <td>
                                            {listElement.wlToOffer} Wl<br />
                                            {listElement.tokensToOffer} tokens<br />
                                            Informations : <i>{listElement.informations}</i>
                                        </td>
                                        <td>
                                            <div className="btn-group">
                                                <button className="btn btn-danger btn-sm" onClick={() => { deleteProject(listElement.id) }}><i className="bx bx-trash"></i></button>
                                                <button className="btn btn-secondary btn-sm" onClick={() => { setEditElement(listElement.id) }}><i className="bx bx-pencil"></i></button>
                                                <button className="btn btn-primary btn-sm" disabled={true} onClick={() => { createGAFromProject(listElement) }}><i className="bx bx-rocket"></i>GA</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>))
            }

        </div >
    </>);
}

export default CollabListPage;
